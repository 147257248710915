import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRoute} from '@angular/router';
import { AllApisService} from '../all-apis.service'
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private allApis:AllApisService,
              private route:Router,
              private actRoute:ActivatedRoute){}


    canActivate():boolean{
       if(this.allApis.isLoggedIn()){
         console.log(this.allApis.isLoggedIn())
         let role;
         if(localStorage.getItem("userType")){
           try {
             role = localStorage.getItem("userType");
           } catch (e) {
             alert(e); // error in the above string (in this case, yes)!
           }
         }
         console.log(role);
         if(role==="Super Admin"){
          this.route.navigate(["/dashboard/welcomeScreen"]);
         } else if(role==="Admin"){
          this.route.navigate(["/dashboard/collect"]);
         } else{
          this.route.navigate(["/dashboard"]);
         }
        
         return true;
       }
       else{  
        return true;
       }
    }
  
}
