import { Component, OnInit, OnChanges, Input } from '@angular/core';

@Component({
  selector: 'app-print-table-pdf',
  templateUrl: './print-table-pdf.component.html',
})
export class PrintTablePdfComponent implements OnInit, OnChanges {

  @Input() public pdfData = {
    thArr: [],
    trtdArr: [],
    amount: '',
    startDate: '',
    endDate: '',
    title: '',
    logoUrl: '',
    schoolName: '',
    address:'',
    contactNo:'',
    reportName:'',
    session:'',
    obj:{},
    sorting:true,
    sortingName:''
  };

  lastSerialNo:boolean = false;
  studentReport2:boolean = false;

  serialNo = []

  thArr = [];
  trtdArr = [];
  imgUrl = '/assets/img/img1.png';
  constructor() { }

  ngOnChanges(): void {
    this.serialNo = [];
    // console.log("ankit");
    if(this.pdfData.sorting !==false && typeof this.pdfData.sorting == 'undefined'){
      this.pdfData.sorting = true;
    }
    var sortData = this.pdfData.trtdArr
    if(this.pdfData.sorting !==false){
      // sortData.sort(this.dynamicsort("name","asc"));
    }
    sortData.sort(this.checkTotal("name","asc"));

    this.imgUrl = this.pdfData.logoUrl=='N/A'?"/assets/img/img1.png":this.pdfData.logoUrl;
    //console.log(this.globals.initiateUserDetails);
    console.log(this.pdfData.thArr);
    try {
      this.thArr = this.pdfData.thArr;//only names;
      this.trtdArr = this.pdfData.trtdArr
    } catch (err) { console.log(err); }

    setTimeout(() => {
      this.setData(this.printNow);
    }, 500)
  }

  setData(printNow) {
    // console.log("ankush", this.pdfData.sorting);
    // var sortData = this.pdfData.trtdArr
    // if(this.pdfData.sorting !==false && typeof this.pdfData.sorting == 'undefined'){
    //   this.pdfData.sorting = true;
    // }
    
    // if(this.pdfData.sorting !==false){
    //   console.log("ankush1", this.pdfData.sorting);
    //   // console.log("sortData",sortData.sort(this.dynamicsort("name","asc")));
    //   sortData.sort(this.dynamicsort("name","asc"));
    // }
    // console.log("sortData",sortData.sort(this.checkTotal("name","asc")));

    console.log("pdfData", this.pdfData)


    let innHtml = document.getElementById("wholeTable").innerHTML;
    return this.printNow(innHtml);
  }

  printNow(contentToPrint) {
    // console.log("ankush1");
    let popupWin;
    popupWin = window.open('pdf', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
        <html>
          <head>
            <title></title>

           
            
            <style>
            .page{
              width: 21cm;
              height: 29.7cm;
              box-shadow: 0 0 0.2cm rgba(0, 0, 0, 0.5);
             }

          @media print {
              body,
              page {
                  margin: 0;
                  box-shadow: 0;
              }

              table {
                border-collapse:collapse;
                margin-bottom: 1px;
              }
               th{
             border: 2px solid black;
             background-color:lightblue;
              }

              tr ,td  {
                border: 1px solid black;
              }
          }

            </style>
          </head>
      <body onload="">`);
      popupWin.document.write(contentToPrint);
      popupWin.document.write(`<div style="text-align:center;margin-bottom:0px;font-size:small">
        <div>
       <script>
       var is_chrome = function () { return Boolean(window.chrome); }
       if(is_chrome) 
        {
            window.print();
            setTimeout(function(){window.close();}, 100); 
         }
         else
         {
           window.print();
           window.close();
           }
       </script>
      </body>
        </html>`
    );
    popupWin.document.close();
  }


  ngOnInit() {
    console.log("abc",this.pdfData ,this.pdfData.sorting );
    if(this.pdfData.sorting !==false && typeof this.pdfData.sorting == 'undefined'){
      this.pdfData.sorting = true;
    }
    var sortData = this.pdfData.trtdArr
    if(this.pdfData.sorting !==false){
      
      if(this.pdfData.sortingName !== "name"){
        sortData.sort(this.dynamicsort(this.pdfData.sortingName,"asc"));
      }else{
        sortData.sort(this.dynamicsort("name","asc"));
      }
      
    }
    this.serialNo = [];
    sortData.sort(this.checkTotal("name","asc"));
    
    // this.sortTable();
    // this.thArr=[
    // {data: "id", title: "Transaction Id", defaultContent: "<i>Not set</i>", mData: "id", sTitle: "Transaction Id"},
    // {data: "studentName", title: "Student Name", defaultContent: "<i>Not set</i>", mData: "studentName", sTitle: "Student Name"},
    // {data: "fatherName", title: "Father Name", defaultContent: "<i>Not set</i>", mData: "fatherName", sTitle: "Father Name"},
    // {data: "montherName", title: "Mother Name", defaultContent: "<i>Not set</i>", mData: "montherName", sTitle: "Mother Name"},
    // {data: "receiptNo", title: "Reciept No", defaultContent: "<i>Not set</i>", mData: "receiptNo", sTitle: "Reciept No"},
    // {data: "payementDate", title: "Payement Date", defaultContent: "<i>Not set</i>", mData: "payementDate", sTitle: "Payement Date"},
    // {data: "status", title: "Status", defaultContent: "<i>Not set</i>", mData: "status", sTitle: "Status"},
    // {data: "mode", title: "Mode", defaultContent: "<i>Not set</i>", mData: "mode", sTitle: "Mode"},
    // {data: "swmming", title: "swmming", defaultContent: "<i>Not set</i>", mData: "swmming", sTitle: "swmming"},
    // {data: "dancing", title: "dancing", defaultContent: "<i>Not set</i>", mData: "dancing", sTitle: "dancing"},
    // {data: "fine", title: "Fine", defaultContent: "<i>Not set</i>", mData: "fine", sTitle: "Fine"},
    // {data: "transport", title: "Transport ", defaultContent: "<i>Not set</i>", mData: "transport", sTitle: "Transport "},
    // {data: "concession", title: "Concession", defaultContent: "<i>Not set</i>", mData: "concession", sTitle: "Concession"},
    // {data: "paid", title: "Paid", defaultContent: "<i>Not set</i>", mData: "paid", sTitle: "Paid"}
    // ];
    // this.trtdArr=[
    //{id: "5e1d53565461362f24049de7", studentName: "Sumit Dube", fatherName: "sushil Chodhary", motherName: "Mamta Chodhary", receiptNo: "1"},
    // {id: "5e1d53565461362f24049de7", studentName: "Sumit Dube", fatherName: "sushil Chodhary", motherName: "Mamta Chodhary", receiptNo: "1"},
    // {id: "5e1d53565461362f24049de7", studentName: "Sumit Dube", fatherName: "sushil Chodhary", motherName: "Mamta Chodhary", receiptNo: "1"},
    // {id: "5e1d53565461362f24049de7", studentName: "Sumit Dube", fatherName: "sushil Chodhary", motherName: "Mamta Chodhary", receiptNo: "1"}
    //]

  }

  // dynamicsort(property, order) {
  //     var sort_order = 1;
  //   if (order === "desc") {
  //     sort_order = -1;
  //   }
    
  //     return function (a, b) {
  //       // a should come before b in the sorted order
  //       if(!a[property].includes("Total")){
  //         console.log("a[property]", !a[property].includes("Total"), a[property], b[property])
  //       if (a[property] < b[property]) {
  //         return -1 * sort_order;
  //         // a should come after b in the sorted order
  //       } else if (a[property] > b[property]) {
  //         return 1 * sort_order;
  //         // a and b are the same
  //       } else {
  //         return 0 * sort_order;
  //       }
  //     }
  //   }

  // }

  checkTotal(key, order = 'asc') {
    let that = this;
    var i=0;
    that.serialNo.push(" ");
    return function innerSort(a, b) {
      
      // console.log("1", i);
      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
        // property doesn't exist on either object
        console.log("bata")
        return 0;
      }

      if(a[key].includes("Class: ")){
        that.studentReport2 = true;
      }
      
      if ( a[key].includes("Class: ") || a[key].includes("Grand Total")) {
        that.serialNo.push(" ");
        that.lastSerialNo = true;
      }
      else{
        i += 1;
        that.serialNo.push(i);
        console.log("serialNo", that.serialNo)
      }
    };
  }


  dynamicsort(key, order = 'asc') {
    console.log("prayas")
    let that = this;
    return function innerSort(a, b) {
      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
        // property doesn't exist on either object
        return 0;
      }
      if (!a[key].includes("Total") && a[key] != "") {
        console.log("Total1")
        const varA = (typeof a[key] === 'string')
          ? a[key].toUpperCase() : a[key];
        const varB = (typeof b[key] === 'string')
          ? b[key].toUpperCase() : b[key];

        let comparison = 0;
        if (varA > varB) {
          comparison = 1;
        } else if (varA < varB) {
          comparison = -1;
        }
        return (
          (order === 'desc') ? (comparison * -1) : comparison
        );
      }
      if (a[key].includes("Total") && a[key] != "") {
        console.log("Total")
        that.lastSerialNo = true;
      }
    };
  }

  sortTable() { 
    var table, i, x, y; 
    table = document.getElementById("table"); 
    var switching = true; 

    // Run loop until no switching is needed 
    while (switching) { 
      switching = false; 
      var rows = table.rows; 

      // Loop to go through all rows 
      for (i = 1; i < (rows.length - 1); i++) { 
        var Switch = false; 

        // Fetch 2 elements that need to be compared 
        x = rows[i].getElementsByTagName("TD")[1]; 
        y = rows[i + 1].getElementsByTagName("TD")[1]; 

        // Check if 2 rows need to be switched 
        if (x.innerHTML.toLowerCase() > y.innerHTML.toLowerCase()) 
          { 

          // If yes, mark Switch as needed and break loop 
          Switch = true; 
          break; 
        } 
      } 
      if (Switch) { 
        // Function to switch rows and mark switch as completed 
        rows[i].parentNode.insertBefore(rows[i + 1], rows[i]); 
        switching = true; 
      } 
    } 
  } 

}
