export class HelperModel {
    name : string;
    dateOfBirth:  Date;
    address: string;
    contact : string;
    fatherOrHusbandName: string;
    vehicleId: string;
    vehicle: string;
    salaryDetail:Array <any> = [];
    session: string;
}

  export class salaryDetail {
    month:string;
    salary:number;
    session:string;
  }   