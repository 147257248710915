import { Component, OnInit } from '@angular/core';
import { NotifierService } from "angular-notifier";
import { transportService } from "../transport.service";
import { Router } from "@angular/router";
import { Income, incomeDetails } from "../Models/Income";

@Component({
  selector: 'app-edit-vehicle-income',
  templateUrl: './edit-vehicle-income.component.html',
  styleUrls: ['./edit-vehicle-income.component.scss']
})
export class EditVehicleIncomeComponent implements OnInit {
  disableScroll(event: Event): void {
    event.preventDefault();
  }
  session:any;
  vehicleId:any;
  tableRowArr = [new incomeDetails()];
  income = new Income();
  constructor(
    public notifier: NotifierService,
    private serv: transportService,
    public _route: Router,
  ) { }

  ngOnInit() {
      try {
        this.income = JSON.parse(localStorage.getItem("vehicleIncomeLocal"));
        if(this.income.incomeDetails.length > 0) {
          this.tableRowArr = this.income.incomeDetails;
          this.session = this.income.session;
          this.vehicleId = this.income.vehicle;
        } else {
          this.tableRowArr = [new incomeDetails()];
        }
        localStorage.removeItem("vehicleIncomeLocal");
      } catch (e) {
        alert(e);
      }
  }

  deleteRow(index) {
    if (index != 0) {
      let newArr = this.tableRowArr;
      newArr.splice(index, 1);
      this.tableRowArr = newArr;
    }
  }

  addRow() {    
    let lastObj = this.tableRowArr[this.tableRowArr.length - 1]
    if (lastObj.month && lastObj.income) {
      this.tableRowArr.push(new incomeDetails());

    } else {
      this.notifier.notify("error", "Empty fields not allowed")
    }
  }

  updateIncome() {
    this.income.incomeDetails = this.tableRowArr;
    this.income.vehicle = this.vehicleId;
    this.income.session = this.session;
    this.serv.updateIncome(this.income).subscribe(
      (res) => {
        this.notifier.notify("success", "Updated successfully");
        this.income = null;
        this.tableRowArr = [new incomeDetails()];
      },
      (err) => {
        this.notifier.notify("error","Please Try Again");
      }
    );
    this._route.navigate(["/dashboard/addIncome"]);
  }

}
