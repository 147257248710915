export class DriverModel {
    name : string;
    dateOfBirth: Date;
    localAddress = {
        line1: '',
        line2: '',
        state: '',
        pincode: '',
      };
      permanentAddress = {
        line1: '',
        line2: '',
        state: '',
        pincode: ''
      };
    contactNo : string;
    otherContactNo : string;
    profilePicture : string;
    govtId : string;
    verificationStatus : string;
    verificationDate : Date;
    licenceNo : string;
    licenceExpiryDate : Date;
    username: string;
    password : string;
    currentStatus: string;
    salaryDetail:Array <any> = [];
    session: string;
}
  export class salaryDetail {
    month:string;
    salary:number;
    session:string;
    }