import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { FileSelectDirective, FileUploader } from "ng2-file-upload";
import { mapRouteService } from "../../map-transport/map-transport.service";
import { routeSchedulingService } from "../../CreateRoute/create-route.service";
import { OneStorage } from "../../GlobalDataStorage/oneStorage.service";
import { groupBy } from "../../shared/group-by.service";
import { NotifierService } from "angular-notifier";
import * as xlsx from "xlsx";
import { AllApisService } from "../../all-apis.service";
import { OrderPipe } from "ngx-order-pipe";
import { transportService } from "../transport.service";

declare var $: any;

@Component({
  selector: "app-map-route",
  templateUrl: "./map-route.component.html",
  styleUrls: ["./map-route.component.scss"],
})
export class MapRouteComponent implements OnInit {
  @ViewChild("updateRoute", { static: false }) updateRoute: ElementRef;

  order: string = "name";
  reverse: boolean = false;

  uri = "";
  uploader: FileUploader; // = new FileUploader({url:this.uri});
  attachmentList: any = [];
  fileData: File = null;
  previewUrl: any = null;
  fileUploadProgress: string = null;
  uploadedFilePath: string = null;
  mimeType: any;
  showLoader:boolean = false;
  loadContent = "";
  stdModel = new StudentData();
  stdDetails: any;
  tableRowArr = [new ConcessionModel()];
  button: string = "Add";
  concession = new ConcessionModel();

  //months =["N/A","April","May","June","Jully","August","September"]
  allMonths = [
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
    "January",
    "February",
    "March",
  ];

  allStandard = JSON.parse(localStorage.getItem("allClasses"));
  allSection = JSON.parse(localStorage.getItem("allSection"));
  standard;
  view: boolean = false;
  section;
  studentDetails = [];
  routeDetails = [];
  routeID;
  studentId;
  dropdownSettings = {};
  currentStdDetail;
  updatedRouteID;
  session: any;
  sectionObj = {};
  routeList = [];
  routeList2 = [];
  stopageList = [];
  routeAndStopages: any;
  selectedStopage: any = null;
  selectedRoute: any;
  stopAmount;
  tableData = [];
  columns = [];
  voucherAvailable: boolean = false;
  pdfDataObj: any;
  month:any;

  sortedCollection: any[];
  // routeAmountNew={};
  constructor(
    private orderPipe: OrderPipe,
    private serv: transportService,
    private mapRouteServices: mapRouteService,
    private routeSchedulingServices: routeSchedulingService,
    public globals: OneStorage,
    public notifier: NotifierService,
    public _allApiService: AllApisService
  ) {
    this.sortedCollection = orderPipe.transform(this.studentDetails, "name");
    console.log(this.sortedCollection);
  }

  updateUsingStand(data) {
    console.log("studentDetailsstudentDetails", data);
    this.mapRouteServices
      .updateCycleStandFee1(this.stdModel)
      .subscribe((res) => {
        this.notifier.notify("info", "Cycle fee updated");
      });
  }

  private callback1(res: {}, that: any) {
    console.log(res);
    that.allStandard = res["standard"];
    that.sectionObj = res["sectionObj"];
    if (that.allStandard[0]) {
      that.standard = that.allStandard[0];
      that.allSection = that.sectionObj[that.standard];
      that.section = that.allSection[0];
      console.log("section", that.section);
      that.findRoute();
    }
  }
  ngOnInit() {
    this.showLoader = true;
    this.dropdownSettings = {
      singleSelection: false,
      idField: "id",
      textField: "name",
      // selectAllText: 'Select All',
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 3,
      allowSearchFilter: true,
    };
    if (this.globals.standard === null || this.globals.standard.length === 0) {
      this.globals.initiateStandard(this.callback1, this);
    } else {
      this.allStandard = this.globals.standard;
      this.sectionObj = this.globals.sectionObj;
      if (this.allStandard[0]) {
        this.standard = this.allStandard[0];
        this.allSection = this.sectionObj[this.standard];
        this.section = this.allSection[0];
        this.findRoute();
      }
    }

    this.routeSchedulingServices.getRouteDetails(this.globals.activeSession).subscribe
      (res => {
        this.routeDetails = res.data;
        console.log("routeDetails", this.routeDetails)
        this.routeList = this.routeDetails.map(function (el) { return el.name; });
        this.routeList = [...new Set(this.routeList)];
        this.selectedRoute = this.routeList[0];
        this.routeAndStopages = groupBy(this.routeDetails, 'name');
        this.stopageList = []
        this.stopageList.push(...this.routeAndStopages[this.routeList[0]]);
      },
    error => {
      console.log('Error', error);

        });

    this.serv.getRoute(this.globals.activeSession).subscribe(
      (res) => {
        this.routeDetails = res.data;

        let morningRoute = [];
        let eveningRoute = [];

        this.routeDetails.forEach((elem) => {
          if (elem.routeShift == "Morning") {
            morningRoute.push(elem.name);
          } else {
            eveningRoute.push(elem.name);
          }
        });

        this.routeList = morningRoute;
        this.routeList2 = eveningRoute;

        console.log(
          "routeList",
          this.routeDetails,
          this.routeList,
          this.routeList2
        );

        // this.routeList = this.routeDetails.map(function (el) { return el.name; });
        this.routeList = [...new Set(this.routeList)];
        this.selectedRoute = this.routeList[0];
        this.routeAndStopages = groupBy(this.routeDetails, "name");
      },
      (error) => {
        console.log("Error", error);
      }
    );

    this.uri =
      this._allApiService.baseurl + "/file/uploadFileToUpdateRouteCost";
    //"headers": [{ 'Authorization': 'Bearer ' + localStorage.getItem('token')}]
    this.uploader = new FileUploader({
      url: this.uri,
      authToken: "Bearer " + localStorage.getItem("token"),
    });
    //this.uploader.options.headers  =
    this.uploader.onCompleteItem = (
      item: any,
      response: any,
      status: any,
      headers: any
    ) => {
      console.log("yuy", response);
      this.attachmentList.push(JSON.parse(response));
      console.log("this.attachmentList", this.attachmentList);
    };
  }
  onClassChange(evt: string) {
    this.allSection = this.sectionObj[evt];
    if (this.standard !== undefined && this.section) {
      // this.findRoute();
    }
  }
  findRoute() {
    this.getRoute1();
    this.showLoader = true;
    this.mapRouteServices
      .getStudentDetailsNew(
        this.standard,
        this.section,
        this.globals.activeSession
      )
      .subscribe(
        /// send current session instead
        (res) => {
          this.studentDetails = res.data;
          console.log("studentDetails....", this.studentDetails)
          let i;
          for (i = 0; i < this.studentDetails.length; i++) {
            let stdDetail: any = this.studentDetails[i];
            stdDetail["stopageList"] = [];
            if ( ((stdDetail.eveningRouteId && stdDetail.eveningRouteId != "n/a") || (stdDetail.morningRouteId && stdDetail.morningRouteId != "n/a") ) && (stdDetail.stop) ) {
              let j;
              for (j = 0; j < this.routeDetails.length; j++) {
                  if (this.routeDetails[j]._id === stdDetail.morningRouteId) {
                    stdDetail.routeName1 = this.routeDetails[j].name;
                    stdDetail.routeCost = stdDetail.stop.amount;
                    stdDetail.stopage = stdDetail.stop.name;
                    stdDetail.routeCost = stdDetail.stop.amount;
                    stdDetail.stopDetails = stdDetail.stop;
                    stdDetail["stopageList"] = [...this.routeDetails[j].stoppage];
                  } else if (
                    this.routeDetails[j]._id === stdDetail.eveningRouteId
                    
                  ) {
                    stdDetail.routeName2 = this.routeDetails[j].name;
                    stdDetail.routeCost = stdDetail.stop.amount;
                    stdDetail.stopage = stdDetail.stop.name;
                }
              }
              // console.log("details after", stdDetail["stopageList"])
            }
          }
          this.populateTable();
          this.showLoader = false;
          // this.notifier.notify('success', 'Data Updated SuccessFully');
        },
        (error) => {
          console.log("Error", error);
          this.notifier.notify("error", "Something went wrong.");
        }
      );
  }

  confirmDetails() {
    this.currentStdDetail = this.stdModel;
    this.currentStdDetail.studentId = this.stdModel.id;
    let self = this;
    this.globals.confirmDialogService.confirmThis(
      "Updating Transport Details :-" + this.stdModel.name,
      "Want To Update Transport Details?",
      function () {
        self.saveChangeRoute();
      },
      function () {}
    );
  }

  generateExcel() {
    const ws: xlsx.WorkSheet = xlsx.utils.table_to_sheet(
      this.updateRoute.nativeElement
    );
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, "Sheet1");
    xlsx.writeFile(wb, "updateRoute.xlsx");
  }

  changeStoppaageList_new(stdDetail, eventTarget) {
    var row = eventTarget.closest("tr");
    var selectTarget = $(row).find("select.stopage");

    // stdDetail.morningRouteName = eventTarget.value;
    // stdDetail.routeName1 = eventTarget.value;
    // stdDetail.morningRouteId = this.routeAndStopages[eventTarget.value][0]._id;
    this.stdModel.morningRouteName =  eventTarget.value;
    this.stdModel.routeName1 =  eventTarget.value;
    this.stdModel.morningRouteId =  this.routeAndStopages[eventTarget.value][0]._id;

    let stopMap = this.routeAndStopages[eventTarget.value][0].stoppage;
    // this.stdModel
    stdDetail["stopageList"] = stopMap;
  }

  setEveningRoute(stdDetail, eventTarget) {
    // stdDetail.eveningRouteName = eventTarget.value;
    // stdDetail.routeName2 = eventTarget.value;
    // stdDetail.eveningRouteId = this.routeAndStopages[eventTarget.value][0]._id;

    this.stdModel.eveningRouteName =  eventTarget.value;
    this.stdModel.routeName2 =  eventTarget.value;
    this.stdModel.eveningRouteId =  this.routeAndStopages[eventTarget.value][0]._id;
    let stopMap = this.routeAndStopages[eventTarget.value][0].stoppage;

    let list = [];

    if(this.stopageList.length !==0 ){
      for(let i=0; i<stopMap.length; i++){
        for(let j=0; j<this.stopageList.length; j++){
          if(this.stopageList[j].name == stopMap[i].name){
            list.push(stopMap[i])
          }
        }
      }
    }else{
      list = stopMap;
    }

    // stdDetail["stopageList"] = list;
    
  }

  updateStopage(stdDetail, eventTarget) {
    console.log("here", eventTarget);
    this.serv.getStopAmount(eventTarget, this.globals.activeSession).subscribe((res) => {
      // this.stopAmount = res.data.amount;
      stdDetail.routeCost = res.data.amount;
      // this.stdModel.routeCost = res.data.amount;
      stdDetail.stopDetails = res.data;
    });
  }

  importExcelForUpdate() {
    $("#routeModel").modal("show");
  }

  saveChangeRoute() {
    this.currentStdDetail.session = this.globals.activeSession
    this.currentStdDetail.concessionDetails = this.tableRowArr;
    if(!this.currentStdDetail.stopDetails && this.currentStdDetail.inUse){
      return this.notifier.notify("info", "Select Student Stop!");
    }
    this.serv.addTransport(this.currentStdDetail).subscribe(
      (res) => {
        this.notifier.notify("success", "Data Updated SuccessFully");
        $("#viewMapDetails").modal("hide");
        this.tableRowArr = [new ConcessionModel()];
        this.stdModel = new StudentData();
        // if(!this.currentStdDetail.inUse) {
        //   this.findRoute();
        // }
        this.findRoute();

      },
      (error) => {
        console.log("Error", error);
        this.notifier.notify("error", "Something went wrong.");
      }
    );
  }

  setOrder(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }

    this.order = value;
  }

  getRoute1() {
    this.serv.getRoute(this.globals.activeSession).subscribe(
      (res) => {
        this.routeDetails = res.data;

        let morningRoute = [];
        let eveningRoute = [];

        this.routeDetails.forEach((elem) => {
          if (elem.routeShift == "Morning") {
            morningRoute.push(elem.name);
          } else {
            eveningRoute.push(elem.name);
          }
        });

        this.routeList = morningRoute;
        this.routeList2 = eveningRoute;
        this.routeList = [...new Set(this.routeList)];
        this.selectedRoute = this.routeList[0];
        this.routeAndStopages = groupBy(this.routeDetails, "name");
      },
      (error) => {
        console.log("Error", error);
      }
    );
  }
  populateTable() {
    let action_btn_edit = '<button class="editBtn btn btn-success">Assign</button>';

    this.showLoader = false;
    let that = this;
    this.columns = [
      { data: "name", title: "Name", defaultContent: "<i>Not set</i>" },
      { data: "father", title: "Father Name", defaultContent: "<i>Not set</i>" },

      {
        data:"class",
        title: "Class",
        defaultContent: "<i>Not set</i>",
      },
      {
        data: "rollNo",
        title: "Roll No",
        defaultContent: "<i>Not set</i>",
      },
      {
        data: "admissionNo",
        title: "Adm. No",
        defaultContent: "<i>Not set</i>",
      },

      // {
      //   data: "usingStand",
      //   title: "Using Cycle Stand",
      //   defaultContent: "<i>Not set</i>",
      //   render: function(data, type, row) {
      //     if (type === 'display') {
      //       return `<input type="checkbox" ${data ? 'checked' : ''} disabled>`;
      //     }
      //     return data;
      //   }
      // },

      // {
      //   data:"cycleStandConcession",
      //   title: "Cycle Stand Concession",
      //   defaultContent: "<i>--Not set--</i>",
      // },
      {
        data: "routeName1",
        title: "Morning Route",
        defaultContent: "<i>--Not set--</i>",
      },
      {
        data: "routeName2",
        title: "Evening Route",
        defaultContent: "<i>--Not set--</i>",
      },
      {
        data: "stopage",
        title: "Stoppage",
        defaultContent: "<i>--Not set--</i>",
      },
      {
        data: "routeCost",
        title: "Stoppage Cost",
        defaultContent: "<i>--Not set--</i>",
      },
      // {
      //   data: "concession",
      //   title: "Concession",
      //   defaultContent: "0",
      // },
      
      // {
      //   data: "inUse",
      //   title: "Enable Transport",
      //   defaultContent: "<i>Not set</i>",
      //   render: function(data, type, row) {
      //     if (type === 'display') {
      //       return `<input type="checkbox" ${data ? 'checked' : ''} disabled>`;
      //     }
      //     return data;
      //   }
      // },
      {
        data: null,
        title: "Action",
        className: "",
        render: function (data) {
          return action_btn_edit;
        },
        defaultContent: action_btn_edit,
      },
      
    ];
    if ($.fn.DataTable.isDataTable(".example")) {
      $(".example").DataTable().clear().destroy();
    }

    $(".table-Map tbody").on("click", "tr .editBtn", function () {
      var p = this.closest("tr");
      var data = $(".table-Map").DataTable().row(p).data();
      that.editMapDataModal(data);
    });

    
    $(".showhideTable").css("display", "block");
    const example = $(".example").DataTable({
      data: this.studentDetails,
      columns: this.columns,
      columnDefs: [
        {
          visible: false,
          searchable: false,
        },
      ],

      rowCallback: function(row, data) {
        if (data.inUse) {
          $(row).addClass('bg-red');
        }
      },

      dom: "Bfrtip",
      buttons: [
        "pageLength"
      ],
      order: [],
      lengthMenu: [
        [50, 100, 200, 300, -1],
        [50, 100, 200, 300, "All"],
      ],
      language: {
        searchPlaceholder: "Search Here",
      },
    });
  }


  editMapDataModal(data) {
    console.log("data", data);
    $("#viewMapDetails").modal("show");
    this.stdModel = data;
  }

  deleteRow(index) {
    let newArr = this.tableRowArr;
    newArr.splice(index, 1);
    this.tableRowArr = newArr;
  }

  addRow() {
    let lastObj = this.tableRowArr[this.tableRowArr.length - 1]
    if (lastObj.month && lastObj.concession) {
      this.tableRowArr.push(new ConcessionModel());

    } else {
      this.notifier.notify("error", "Empty fields not allowed")
    }
  }

  addConcession(stdDetails : any) {
    this.tableRowArr = stdDetails.concessionDetails
    if(this.tableRowArr.length === 0) {
      this.tableRowArr = [new ConcessionModel()];
    }
  }

  onAddDocument() {
    let concessionData = this.tableRowArr;
    for (let i = 0; i < this.tableRowArr.length; i++) {
      let trow = this.tableRowArr[i];

      if(trow.month)
      {
        if (!trow.month) {
          return this.notifier.notify("error", `Please Select a Month`);
        }

        if (trow.concession === undefined || trow.concession === null) {
          return this.notifier.notify("error", `Please Enter Concession For Blank Field`);
        }
      }
      
      const duplicateIndex = this.tableRowArr.findIndex((row, index) => index !== i && row.month === trow.month);
      if (duplicateIndex !== -1) {
        return this.notifier.notify("error", `'${trow.month}' is already selected.`);
      }

    }

    this.tableRowArr = [];
    for (const doc of concessionData) {
      const { ...newDocument } = doc;
      newDocument.session = this.globals.activeSession;
      this.tableRowArr.push(newDocument)
    }
  }
}
export class StudentData {
  admissionNo : string;
  name: string;
  class: string;
  concession : number
  contactNo : string
  cycleStandConcession : number
  emailId : string
  isDeleted : boolean
  id: string;
  session: string;
  usingStand : boolean
  morningRouteName:string
  routeName1:string
  morningRouteId: string
  eveningRouteName:string
  routeName2:string
  eveningRouteId : string
  skipMonthForTransport:any;
  skipMonthForCylcleStand:any;
  stopageList:any;
  routeCost:number;
  inUse:boolean;
  stopage:any;
}
class ConcessionModel {
  month:string;
  concession:number;
  session: string;
  }