import { Component, OnInit } from '@angular/core';
import { NotifierService } from "angular-notifier";
import { transportService } from "../transport.service";
import { Router } from "@angular/router";
import { ExpenseModel } from "../Models/Expense";
import { OneStorage } from "src/app/GlobalDataStorage/oneStorage.service";

@Component({
  selector: 'app-add-expense',
  templateUrl: './add-expense.component.html',
  styleUrls: ['./add-expense.component.scss']
})
export class AddExpenseComponent implements OnInit {
  imageFlag = false;
  showErrorMessage: string = "";
  billImage: any;
  fileToUpload: any;
  allVehicle: any;
  session: any;
  vehicleId: any;
  expense = new ExpenseModel();
  date = new Date().toISOString().substr(0, 10);
  today: string;


  constructor(
    public notifier: NotifierService,
    private serv: transportService,
    public _route: Router,
    private globals: OneStorage,

  ) { }

  ngOnInit() {
    this.today = new Date().toISOString().split('T')[0];
    this.serv.fetchVehicleAllData().subscribe(
      (res) => {
        this.allVehicle = res.msg;
      });

      this.expense.session = this.globals.activeSession;
      if(typeof this.expense.session === 'undefined' )
      this.expense.session ="2023-24"
  }

  fuelTypeChange() {
    this.expense.billType == "Fuel";
  }

  removeFilePic() {
    this.imageFlag = false;
    this.billImage = null;
    this.expense["billImage"] = null
  }

  onAddDocument(fileName) {
    this.serv.saveImage(this.fileToUpload).subscribe((res) => {
      this.expense[`${fileName}`] = res.data.url;
      if (fileName == "billImage") {
        this.imageFlag = true;
        this.expense.billImage = res.data.url
      }
      console.log("res", this.expense)
    });
  }

  onFileChange(event) {
    this.fileToUpload = event.target.files[0];
    if (
      this.fileToUpload &&
      (this.fileToUpload.name.toLowerCase().includes("jpg") ||
        this.fileToUpload.name.includes("jpeg") ||
        this.fileToUpload.name.includes("jfif") ||
        this.fileToUpload.name.includes("pjpeg") ||
        this.fileToUpload.name.includes("pjp") ||
        this.fileToUpload.name.includes("png") ||
        this.fileToUpload.name.includes("svg"))
    ) {
      this.serv.saveId(this.fileToUpload);
      this.onAddDocument(event.target.name)
    } else {
      this.fileToUpload = null;
      this.notifier.notify("error", "Please select only image file");
    }
  }

  addExpense() {
    this.expense.driverSourceId = "incharge"
    if(!this.expense.vehicle) {
      return this.notifier.notify("error", "Please Select Vehicle");

    } else if (!this.expense.createdAt) {
      return this.notifier.notify("error", "Please Select Date");

    } else if (!this.expense.billType) {
      return this.notifier.notify("error", "Please Select Expense Type");

    } else if (!this.expense.amount) {
      return this.notifier.notify("error", "Please Enter Amount");

    } else if (!this.expense.title) {
      return this.notifier.notify("error", "Please Enter Expense Title");

    } else if (this.expense.billType == "Fuel") {
        if(!this.expense.fuelType)
          return this.notifier.notify("error", "Please Select Fuel Type");

        else if(!this.expense.fuelQuantity) {
          return this.notifier.notify("error", "Please Enter Fuel Quantity");
        }
    }
    this.serv.addExenseByIncharge(this.expense).subscribe(
      (res) => {
        this.notifier.notify("success", "Expense Added successfully");
        this.expense = new ExpenseModel();
        this._route.navigate(["/dashboard/expenseList"]);
      },
      (err) => {
        this.notifier.notify("error", "Please Try Again");
      }
    );
  }

}
