export class Income {
    session:string;
    vehicle:string;
    incomeDetails:Array <any> = [];
}

  export class incomeDetails {
    month:string;
    income:number;
    vehicle:number;
    }