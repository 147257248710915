import { Component, OnInit } from "@angular/core";
import { StudentService } from "../../StudentO/Services/student.service";
import { NotifierService } from "angular-notifier";
import { routeSchedulingService } from "src/app/CreateRoute/create-route.service";
import { OneStorage } from "src/app/GlobalDataStorage/oneStorage.service";
import { Router, ActivatedRoute } from "@angular/router";
import { SharedService } from "src/app/dashboardO/dashboard/shared.service";


declare var $: any;

@Component({
  selector: "app-seat-allocation-report",
  templateUrl: "./seat-allocation-report.component.html",
  styleUrls: ["./seat-allocation-report.component.scss"],
})
export class SeatAllocationReportComponent implements OnInit {
  tableData = [];
  columns = [];
  clickEventsubscription: any;
  pdfDataObj: any;
  showLoader:boolean = false;
  loadContent = "";

  voucherAvailable: boolean = false;
  constructor(
    private _studentService: StudentService,
    private globals: OneStorage,
    public _route: Router,
    public sharedService : SharedService,

  ) {

    this.clickEventsubscription = this.sharedService
    .getClickEvent()
    .subscribe(() => {
      this.globals.activeSession = this.sharedService.getSession();
      this.createTable();
    });
  }

  ngOnInit() {
    this.createTable();
  }

  createTable() {
    this.showLoader = true;
    this._studentService.getSeatAllocation(this.globals.activeSession).subscribe(
      (res) => {
        console.log("----------------getting student list", res);
        if (res.status) {
          this.tableData = res.result;

          this.populateTable();
        }
      },
      (error) => {
        console.log("Error", error);
      }
    );
  }

  populateTable() {
    this.showLoader = false;
    let that = this;
    let action_btn = '<i class="text-primary view" title="Allocation Details" style="cursor: pointer;">view</i>';

    this.columns = [
      { data: "name", title: "Route Name", defaultContent: "<i>Not set</i>" },
      {
        data: "routeShift",
        title: "Route Shift",
        defaultContent: "<i>Not set</i>",
      },
      {
        data: "regNumber",
        title: "Vehicle Number",
        defaultContent: "<i>Not set</i>",
      },
      {
        data: "vehicleCapacity",
        title: "Vehicle Capacity",
        defaultContent: "<i>Not set</i>",
      },
      {
        data: "allocatedSeat",
        title: "Allocated Seat",
        defaultContent: "<i>Not set</i>",
      },
      {
        data: "percentage",
        title: "Allocated Seat Percentage(in %)",
        defaultContent: "<i>Not set</i>",
      },
      {
        title: "Allocation Bar",
        data: "allocatedSeat",
        render: function (data, type, row, meta) {
          const progressValue = (data / row.vehicleCapacity) * 100;
          const progressBarHTML = `
    <progress value="${data}" max="${row.vehicleCapacity}" class="${
            data !== row.vehicleCapacity ? "not-equal" : ""
          }"></progress>
    ${row.allocatedSeat} / ${row.vehicleCapacity}
  `;

          return type === "display" ? progressBarHTML : progressValue;
        },
      },
      {
        data: null,
        className: "center",
        title: 'Allocation Details',
        defaultContent: action_btn,
      },
    ];
    if ($.fn.DataTable.isDataTable(".exampleSeat")) {
      $(".exampleSeat").DataTable().clear().destroy();
    }
    $(".showhideTable").css("display", "block");
    const example = $(".exampleSeat").DataTable({
      data: this.tableData,
      columns: this.columns,
      columnDefs: [
        {
          // "targets": [0],
          visible: false,
          searchable: false,
        },
      ],

      dom: "Bfrtip",
      buttons: [
        "pageLength",
        // "copy",
        // "csv",
        {
          extend: 'excelHtml5',
          exportOptions: {
            columns: ':not(:nth-child(8))'
          }
        },
        {
          text: "Pdf",
          action: function () {
            generatePdf();
          },
        },
      ],
      lengthMenu: [
        [5, 10, 25, 50, -1],
        [5, 10, 25, 50, "All"],
      ],
      language: {
        searchPlaceholder: "Search Here",
      },
    });

    $(".exampleSeat tbody").on("click", "tr i.view", function () {
      var p = this.closest("tr");
      var data = $(".exampleSeat").DataTable().row(p).index();
      localStorage.setItem('vehicleNo', JSON.stringify(that.tableData[data].regNumber));
      that._route.navigate(["/dashboard/studentAllocationReport"]);
    });

    async function generatePdf() {
      that.voucherAvailable = true;
      try {
        if(that.columns.length > 6){
          that.columns.pop()
        }
        that.tableData.forEach((ele) => {
          that.columns.forEach((zz) => {
            if (!ele.hasOwnProperty(zz.data)) {
              ele[zz.data] = "Not set";
            }
          });
          // console.log(ele)
        });
        const inputDateString = new Date();
        const inputDate = new Date(inputDateString);

        const day = inputDate.getDate().toString().padStart(2, '0');
        const month = (inputDate.getMonth() + 1).toString().padStart(2, '0');
        const year = inputDate.getFullYear();

        const hours = inputDate.getHours().toString().padStart(2, '0');
        const minutes = inputDate.getMinutes().toString().padStart(2, '0');

        const formattedDate = `${day}/${month}/${year} ${hours}:${minutes}`;
        var title = "Report generated on  :" + "-  " + formattedDate;
        var concessionList = " ";

        var reportName = "Seat Allocation Management Report";

        await that.globals
          .getPdfDataObj(that.columns, that.tableData, title, reportName)
          .then((res) => {
            that.pdfDataObj = res;
          });
      } catch (e) {
        console.log(e);
      }
    }
  }
}
