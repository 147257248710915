import { Component, OnInit } from "@angular/core";
import { mapRouteService } from "../../map-transport/map-transport.service";
import { OneStorage } from "../../GlobalDataStorage/oneStorage.service";
import { NotifierService } from "angular-notifier";
declare var $: any;

@Component({
  selector: 'app-stoppage-history-report',
  templateUrl: './stoppage-history-report.component.html',
  styleUrls: ['./stoppage-history-report.component.scss']
})
export class StoppageHistoryReportComponent implements OnInit {
  allStandard = JSON.parse(localStorage.getItem("allClasses"));
  allSection = JSON.parse(localStorage.getItem("allSection"));
  standard;
  view: boolean = false;
  section;
  studentDetails = [];
  routeDetails = [];
  dropdownSettings = {};
  session: any;
  sectionObj = {};
  stopageList = [];
  columns = [];
  showLoader: boolean = false;
  stoppageHistory : any;
  loadContent = "";

  constructor(
    private _service: mapRouteService,
    public globals: OneStorage,
    public notifier: NotifierService,
  ) { 
    
  }

  ngOnInit() {
    if (this.globals.standard === null || this.globals.standard.length === 0) {
      this.globals.initiateStandard(this.callback, this);
    } else {
      this.allStandard = this.globals.standard;
      this.sectionObj = this.globals.sectionObj;
      if (this.allStandard[0]) {
        this.standard = this.allStandard[0];
        this.allSection = this.sectionObj[this.standard];
        this.section = this.allSection[0];
      }
    }
    this.findDetails();
  }

  private callback(res: {}, that: any) {
    console.log(res);
    that.allStandard = res["standard"];
    that.sectionObj = res["sectionObj"];
    if (that.allStandard[0]) {
      that.standard = that.allStandard[0];
      that.allSection = that.sectionObj[that.standard];
      that.section = that.allSection[0];
    }
  }

  onClassChange(evt: string) {
    this.allSection = this.sectionObj[evt];
    if (this.standard !== undefined && this.section) {
    }
  }

  findDetails() {
    this.showLoader = true;
    this._service
      .getStudentDetailStoppageHistory(
        this.standard,
        this.section,
        this.globals.activeSession
      )
      .subscribe(
        (res) => {
          this.studentDetails = res.data;
          console.log("studentDetails....", this.studentDetails)
          this.populateTable();
          this.showLoader = false;
        },
        (error) => {
          console.log("Error", error);
          this.notifier.notify("error", "Something went wrong.");
        }
      );
  }
  populateTable() {
    let action_btn_edit = '<button class="historyBtn btn btn-info">History</button>';

    this.showLoader = false;
    let that = this;
    this.columns = [
      { 
        data: "fullName", 
        title: "Name", 
        defaultContent: "<i>Not set</i>" },
      {
        data:"class",
        title: "Class",
        defaultContent: "<i>Not set</i>",
      },
      {
        data: "rollNo",
        title: "Roll No",
        defaultContent: "<i>Not set</i>",
      },
      {
        data: "admissionNo",
        title: "Adm. No",
        defaultContent: "<i>Not set</i>",
      },

      // {
      //   data: "stoppage",
      //   title: "Previous Stoppage",
      //   defaultContent: "<i>--Not set--</i>",
      // },
      // {
      //   data: "stoppage",
      //   title: "Current Stoppage",
      //   defaultContent: "<i>--Not set--</i>",
      // },
      {
        data: null,
        title: "Action",
        className: "",
        render: function (data) {
          return action_btn_edit;
        },
        defaultContent: action_btn_edit,
      },
      
    ];
    if ($.fn.DataTable.isDataTable(".example")) {
      $(".example").DataTable().clear().destroy();
    }

    $(".table-Map tbody").on("click", "tr .historyBtn", function () {
      var p = this.closest("tr");
      var data = $(".table-Map").DataTable().row(p).data();
      that.editMapDataModal(data);
    });


    
    $(".showhideTable").css("display", "block");
    const example = $(".example").DataTable({
      data: this.studentDetails,
      columns: this.columns,
      columnDefs: [
        {
          visible: false,
          searchable: false,
        },
      ],  
      dom: "Bfrtip",
      buttons: [
        "pageLength"
      ],
      order: [],
      lengthMenu: [
        [50, 100, 200, 300, -1],
        [50, 100, 200, 300, "All"],
      ],
      language: {
        searchPlaceholder: "Search Here",
      },
    });
  }


  editMapDataModal(data) {
    this.stoppageHistory = data;
    $("#viewStoppageHistory").modal("show");
    console.log("data", data);
  }

}
