import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { SignUpComponent } from "./SigninSignoutO/sign-up/sign-up.component";

import { SignInComponent } from "./SigninSignoutO/sign-in/sign-in.component";

import { SmSignInComponent } from "./SigninSignoutO/sm-sign-in/sm-sign-in.component";

import { DashboardComponent } from "./dashboardO/dashboard/dashboard.component";

import { AddFineComponent } from "./AddFine/add-fine.component";
import { CreateRouteComponent } from "./CreateRoute/create-route.component";

import { MapTransportComponent } from "./map-transport/map-transport.component";

import { AuthGuard } from "./auth_guard/auth.guard";

import { SiteV2Component } from "./site-v2/site-v2.component";

import { DashGuard } from "./auth_guard/dash-guard.guard";

import { DashContentComponent } from "./dash-content/dash-content.component";

import { ForgetPasswordComponent } from "./SigninSignoutO/forget-password/forget-password.component";

import { SmsUpdatesComponent } from "./ppadmin/sms-updates/sms-updates.component";
import { SmsUpdatesSchoolListComponent } from "./ppadmin/sms-updates-school-list/sms-updates-school-list.component";
import { PadhaiPayQueryListComponent } from "./ppadmin/padhai-pay-query-list/padhai-pay-query-list.component";
import { PPAdminSyncAllComponent } from "./ppadmin/ppadmin-sync-all/ppadmin-sync-all.component";
// import { SmsUpdatesComponent } from './sms-updates/sms-updates.component';
import { PrivacyComponent } from "./privacy/privacy.component";

import { StatusMonthWiseComponent } from "./status-month-wise/status-month-wise.component";
import { OnlineTransactionComponent } from "./ppadmin/online-transaction/online-transaction.component";

import { SchoolPaymentListComponent } from "./school-payment-list/school-payment-list.component";

import { ImportStudentSchedulingExcelComponent } from "./import-student-scheduling-excel/import-student-scheduling-excel.component";
import { SmAgentComponent } from "./ppadmin/sm-agent/sm-agent.component";
import { SideBarSearchComponent } from "./dashboardO/side-bar-search/side-bar-search.component";
import { AboutusComponent } from "./aboutus/aboutus.component";
import { ContactusComponent } from "./contactus/contactus.component";
import { TermconditionComponent } from "./termcondition/termcondition.component";
import { ViewScholarshipsComponent } from "./scholarship-module/view-scholarships/view-scholarships.component";
import { DetailsComponent } from "./scholarship-module/details/details.component";
import { RefundPolicyComponent } from "./refund-policy/refund-policy.component";
import { AppLinkComponent } from "./app-link/app-link.component";
import { AddStopComponent } from "./NewTransportO/add-stop/add-stop.component";
import { AddRouteComponent } from "./NewTransportO/add-route/add-route.component";
import { AddDriverComponent } from "./NewTransportO/add-driver/add-driver.component";
import { AddConductorComponent } from "./NewTransportO/add-conductor/add-conductor.component";
import { AddVehicleComponent } from "./NewTransportO/add-vehicle/add-vehicle.component";
import { MapRouteComponent } from "./NewTransportO/map-route/map-route.component";
import { MapRouteVehicleComponent } from "./NewTransportO/map-route-vehicle/map-route-vehicle.component";
import { DriverConductorVehicleAllocationComponent } from "./NewTransportO/driver-conductor-vehicle-allocation/driver-conductor-vehicle-allocation.component";
import { ListRouteComponent } from "./NewTransportO/list-route/list-route.component";
import { ListVehicleComponent } from "./NewTransportO/list-vehicle/list-vehicle.component";
import { ListConductorAndDriverComponent } from "./NewTransportO/list-conductor-and-driver/list-conductor-and-driver.component";
import { BlankContentComponent } from "./blank-content/blank-content.component";
import { DashboardTransportComponent } from "./dashboardO/dashboard-transport/dashboard-transport.component";
import { StudentReportingComponent } from "./StudentO/studentReporting/studentReporting.component";
import { StudentReportingForTransportComponent } from "./NewTransportO/student-reporting-for-transport/student-reporting-for-transport.component";
import { SeatAllocationReportComponent } from "./NewTransportO/seat-allocation-report/seat-allocation-report.component";
import { TrackSchoolBusComponent } from "./NewTransportO/track-school-bus/track-school-bus.component";
import { OverSpeedDetailsComponent } from "./NewTransportO/over-speed-details/over-speed-details.component";
import { DistanceReportComponent } from "./NewTransportO/distance-report/distance-report.component";
import { transactionReportComponent } from "./Inventory/transactionReporting/transactionReport.component";
import { TransportExpensePrivateComponent } from "./NewTransportO/transport-expense-private/transport-expense-private.component";
import { TransportExpenseCommercialComponent } from "./NewTransportO/transport-expense-commercial/transport-expense-commercial.component";
import { MailConfigComponent } from "./NewTransportO/mail-config/mail-config.component";
import { AddHelperComponent } from "./NewTransportO/add-helper/add-helper.component";
import { ExpenseListComponent } from "./NewTransportO/expense-list/expense-list.component";
import { StudentAllocationReportComponent } from "./NewTransportO/student-allocation-report/student-allocation-report.component";
import { AddVehicleIncomeComponent } from "./NewTransportO/add-vehicle-income/add-vehicle-income.component";
import { EditVehicleIncomeComponent } from "./NewTransportO/edit-vehicle-income/edit-vehicle-income.component";
import { EditExpenseComponent } from "./NewTransportO/edit-expense/edit-expense.component";
import { AddExpenseComponent } from "./NewTransportO/add-expense/add-expense.component";
import { StudentAttendanceReportComponent } from "./NewTransportO/student-attendance-report/student-attendance-report.component";
import { AddShiftComponent } from "./NewTransportO/add-shift/add-shift.component";
import { CapacityShiftWiseComponent } from "./NewTransportO/capacity-shift-wise/capacity-shift-wise.component";
import { StoppageHistoryReportComponent } from "./NewTransportO/stoppage-history-report/stoppage-history-report.component";
import { TransportExpenseCommercialInstallmentwiseComponent } from "./NewTransportO/transport-expense-commercial-installmentwise/transport-expense-commercial-installmentwise.component";

//added later

export const routes: Routes = [
  { path: "", redirectTo: "welcome", pathMatch: "full" },
  // {path: "welcome",component:SiteV1Component,canActivate:[AuthGuard]},
  { path: "welcome", component: SiteV2Component, canActivate: [AuthGuard] },
  { path: "privacy", component: PrivacyComponent },
  { path: "fees", component: AppLinkComponent },
  { path: "refund-policy", component: RefundPolicyComponent },

  { path: "aboutus", component: AboutusComponent },
  { path: "contactus", component: ContactusComponent },
  { path: "termNcondition", component: TermconditionComponent },
  { path: "signin", component: SignInComponent },
  { path: "sm-sign-in", component: SmSignInComponent },
  { path: "signup", component: SignUpComponent },

  { path: "resetPassword", component: ForgetPasswordComponent },
  { path: "sideBarSearch", component: SideBarSearchComponent },
  {
    path: "dashboard",
    component: DashboardComponent,
    canActivate: [DashGuard],
    children: [
      //super admin
      { path: "welcomeScreen", component: DashContentComponent },
      { path: "blankContent", component: BlankContentComponent },
      // Admission

      //school
      {
        path: "school",
        loadChildren: () =>
          import("./SchoolO/SchoolO.module").then((m) => m.SchoolOModule),
      },

      //shop

      {
        path: "student",
        loadChildren: () =>
          import("./StudentO/student.module").then((m) => m.StudentModule),
      },
      {
        path: "inventory",
        loadChildren: () =>
          import("./Inventory/inventory.module").then((m) => m.InventoryModule),
      },
      {
        path: "expense",
        loadChildren: () =>
          import("./expense-management/expense.module").then(
            (m) => m.ExpenseModule
          ),
      },
      {
        path: "collect",
        loadChildren: () =>
          import("./CollectFeeO/collect-fee.module").then(
            (m) => m.CollectFeeOModule
          ),
      },
      {
        path: "admission",
        loadChildren: () =>
          import("./admission/admission.module").then((m) => m.AdmissionModule),
      },
      {
        path: "masterSettings",
        loadChildren: () =>
          import("./MasterSettingO/master-setting-o.module").then(
            (m) => m.MasterSettingOModule
          ),
      },

      {
        path: "user",
        loadChildren: () =>
          import("./UserDetailsO/user-details-o.module").then(
            (m) => m.UserDetailsOModule
          ),
      },

      {
        path: "merchant",
        loadChildren: () =>
          import("./merchant-module/merchant-module.module").then(
            (m) => m.MerchantModuleModule
          ),
      },

      //User Details

      {
        path: "transportDashboard",
        component: DashboardTransportComponent,
      },

      { path: "addfine", component: AddFineComponent },

      { path: "addStop", component: AddStopComponent },
      {path : "addRoute", component : AddRouteComponent},
      { path: "addShift", component: AddShiftComponent },
      { path: "capacityShiftWise", component: CapacityShiftWiseComponent },
      {path : "addDriver", component : AddDriverComponent},
      {path : "addHelper", component : AddHelperComponent},
      {path: "addConductor", component: AddConductorComponent},
      {path: "addVehicle", component: AddVehicleComponent},
      {path : "mapRoute", component: MapRouteComponent},
      {path : "mapVehicleRoute", component:MapRouteVehicleComponent },

      {path : "mapDriverVehicle", component:DriverConductorVehicleAllocationComponent },

      {path : "studentReportForTransport" , component : StudentReportingForTransportComponent},

      {path : "SeatAllocationReport" , component : SeatAllocationReportComponent},
      {path: "trackSchoolBus", component: TrackSchoolBusComponent},
      {path: "overSpeedDetails", component: OverSpeedDetailsComponent},
      {path: "distanceReport", component: DistanceReportComponent},
      {path: "transportExpenseReportPrivate", component: TransportExpensePrivateComponent},
      // {path: "transportExpenseReportCommercial", component: TransportExpenseCommercialComponent},
      {path: "transportExpenseReportCommercialInstallmentWise", component: TransportExpenseCommercialInstallmentwiseComponent},
      {path: "mailConfiguration", component: MailConfigComponent},
      {path: "expenseList", component: ExpenseListComponent},
      {path: "studentAllocationReport", component: StudentAllocationReportComponent},
      // {path: "addIncome", component: AddVehicleIncomeComponent},   
      // {path: "editIncome", component: EditVehicleIncomeComponent},   
      {path: "editExpense", component: EditExpenseComponent},      
      {path: "addExpense", component: AddExpenseComponent},      
      {path: "studentAttendance", component: StudentAttendanceReportComponent},      

      { path: "createroute", component: CreateRouteComponent },
      { path: "createroute", component: CreateRouteComponent },

      { path: "maproute", component: MapTransportComponent },
      {path : "listRoute", component : ListRouteComponent},
      { path: "stoppageHistoryReport", component : StoppageHistoryReportComponent},

      { path: "listVehicle", component: ListVehicleComponent },
      {path : "listDriverConductor", component : ListConductorAndDriverComponent},

      { path: "smsUpdates", component: SmsUpdatesComponent },
      { path: "smsUpdatesList", component: SmsUpdatesSchoolListComponent },
      { path: "onlineTransaction", component: OnlineTransactionComponent },
      { path: "smAgent", component: SmAgentComponent },

      //padhaiPayQueryList
      { path: "padhaiPayQueryList", component: PadhaiPayQueryListComponent },
      { path: "padhaiPaySyncAll", component: PPAdminSyncAllComponent },

      //MonthWiseStatus
      { path: "monthWiseStatus", component: StatusMonthWiseComponent },

      //merchant

      { path: "schoolTransactionList", component: SchoolPaymentListComponent },

      {
        path: "concession",
        loadChildren: () =>
          import("./ConcessionO/concession.module").then(
            (m) => m.ConcessionModule
          ),
      },
      //{path: 'feeReconcelationList', component: FeeReconcelationListComponent},

      //  {path: 'feepayment', component:FeePaymentComponent},
      //{path: 'importExcel', component:ImportExcelComponent},
      {
        path: "importSchedulingExcel",
        component: ImportStudentSchedulingExcelComponent,
      },

      { path: "**", component: PageNotFoundComponent },
    ],
  },
  {
    path: "scholarship",
    loadChildren: () =>
      import("./scholarship-module/scholarship.module").then(
        (m) => m.ScholarshipModule
      ),
  },
  {
    path: "naso",
    loadChildren: () => import("./naso/naso.module").then((m) => m.NasoModule),
  },
  {
    path: "preAdmission",
    component: SideBarSearchComponent,
    children: [
      {
        path: "admission",
        loadChildren: () =>
          import("./admission/admission.module").then((m) => m.AdmissionModule),
      },
    ],
  },

  {
    path: "payment",
    loadChildren: () =>
      import("./payment/payment.module").then((m) => m.PaymentModule),
  },

  {
    path: "admission",
    loadChildren: () =>
      import("./admission/admission.module").then((m) => m.AdmissionModule),
  },
  { path: "**", component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
