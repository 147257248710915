import { Component, OnInit } from '@angular/core';
import { transportService } from '../transport.service';
import { NotifierService } from 'angular-notifier';
import { Router } from '@angular/router';
import { SharedService } from "src/app/dashboardO/dashboard/shared.service";
import { OneStorage } from "../../GlobalDataStorage/oneStorage.service";

declare var $: any;

@Component({
  selector: 'app-list-conductor-and-driver',
  templateUrl: './list-conductor-and-driver.component.html',
  styleUrls: ['./list-conductor-and-driver.component.scss']
})
export class ListConductorAndDriverComponent implements OnInit {

  coloumns1;
  coloumns2;
  coloumns3;
  driverDetails;
  ConductorDetails;
  helperDetails;
  showLoader:boolean = false;
  loadContent = "";
  clickEventsubscription: any;

  constructor(
    private router: Router,private serv: transportService,public notifier: NotifierService, public sharedService : SharedService, private globals: OneStorage,
  ) { 
    this.clickEventsubscription = this.sharedService
    .getClickEvent()
    .subscribe(() => {
      this.globals.activeSession = this.sharedService.getSession();
      this.fetchDriver();
      this.fetchConductor();
      this.fetchHelper();
    });
  }

  ngOnInit() {
    this.showLoader = true;
    let action_btn_edit =
      '<span class="editBtn"><i style="padding-left:10px;position:pointer; cursor:pointer" title = "Edit"><img src="../../../assets/dash/pencil.svg"> </i></span>'
      //  <span class="deleteBtn"><i style="padding-left:10px;position:pointer;" title="delete"><img src="../../../assets/dash/Group45993.svg"></i></span> ';

    this.coloumns1 = [
      {
        defaultContent: "<i>Not Set</i>",
        title: "S. No.",
        // render: function (data, type, full, meta) {
        //   return i++;
        // },
      },
      { data: "name", defaultContent: "<i>Not Set</i>", title: "Driver Name" },
      {
        data: "dateOfBirth",
        defaultContent: "<i>Not Set</i>",
        title: "Date of Birth",
      },
      {
        data: "contactNo",
        defaultContent: "<i>Not Set</i>",
        title: "Contact No.",
      },
      {
        data: "verificationStatus",
        defaultContent: "<i>Not Set</i>",
        title: "Police Verification Status",
      },
      {
        data: "licenceNo",
        defaultContent: "<i>Not Set</i>",
        title: "License No.",
      },
      {
        data: "licenceExpiryDate",
        defaultContent: "<i>Not Set</i>",
        title: "License Expiry Date",
      },
      {
        data: null,
        className: "",
        render: function (data) {
          return action_btn_edit;
        },
        defaultContent: action_btn_edit,
      },
    ];

    this.coloumns2 = [
      {
        defaultContent: "<i>Not Set</i>",
        title: "S. No.",
        // render: function (data, type, full, meta) {
        //   return i++;
        // },
      },
      { data: "name", defaultContent: "<i>Not Set</i>", title: "Conductor Name" },
      {
        data: "dateOfBirth",
        defaultContent: "<i>Not Set</i>",
        title: "Date of Birth",
      },
      {
        data: "contactNo",
        defaultContent: "<i>Not Set</i>",
        title: "Contact No.",
      },
      {
        data: "verificationStatus",
        defaultContent: "<i>Not Set</i>",
        title: "Police Verification Status",
      },
      // {
      //   data: "licenceNo",
      //   defaultContent: "<i>Not Set</i>",
      //   title: "Licence no",
      // },
      // {
      //   data: "licenceExpiryDate",
      //   defaultContent: "<i>Not Set</i>",
      //   title: "Licence expiry date",
      // },
      {
        data: null,
        className: "",
        render: function (data) {
          return action_btn_edit;
        },
        defaultContent: action_btn_edit,
      },
    ];

    this.coloumns3 = [
      {
        defaultContent: "<i>Not Set</i>",
        title: "S. No.",
      },
      { data: "name", defaultContent: "<i>Not Set</i>", title: "Helper Name" },
      {
        data: "dateOfBirth",
        defaultContent: "<i>Not Set</i>",
        title: "Date of Birth",
      },
      {
        data: "contact",
        defaultContent: "<i>Not Set</i>",
        title: "Contact No.",
      },
      {
        data: "fatherOrHusbandName",
        defaultContent: "<i>Not Set</i>",
        title: "Father/Husband Name",
      },
      {
        data: "vehicle",
        defaultContent: "<i>Not Set</i>",
        title: "Allocated Vehicle",
      },
      {
        data: null,
        className: "",
        render: function (data) {
          return action_btn_edit;
        },
        defaultContent: action_btn_edit,
      },
    ];

    this.fetchDriver();
    this.fetchConductor();
    this.fetchHelper();

  }

  editDriver(data){
    localStorage.setItem("driverDetail",JSON.stringify(data));
    this.router.navigate(['/dashboard/addDriver']);
  }

  editConductor(data){
    localStorage.setItem("ConductorDetail",JSON.stringify(data));
    this.router.navigate(['/dashboard/addConductor']);
  }

  editHelper(data){
    localStorage.setItem("helperDetails",JSON.stringify(data));
    this.router.navigate(['/dashboard/addHelper']);
  }
  
  populateTable1(active: boolean) {
    this.showLoader = false;
    let self = this;
    if ($.fn.DataTable.isDataTable(".table-display1")) {
      $(".table-display1").DataTable().clear().destroy();
    }
    $(".showhideTable").css("display", "block");
    const example = $(".table-display1").DataTable({
      data: this.driverDetails,
      columns: this.coloumns1,
      // dom: 'Bfrtip',
      //  buttons: []

      ordering: true,
      "fnRowCallback": function(nRow, aData, iDisplayIndex, iDisplayIndexFull) {
        //debugger;
        var index = iDisplayIndexFull + 1;
        $("td:first", nRow).html(index);
        return nRow;
      },

      language: {
        searchPlaceholder: "Search Here",
      },
      lengthMenu: [
        [5, 10, 25, 50, -1],
        [5, 10, 25, 50, "All"],
      ],
    });
    $(".table-display1 tbody").on("click", "tr .editBtn", function () {
      var p = this.closest("tr");
      var data = $(".table-display1").DataTable().row(p).data();
      console.log("clicked............", data);
      // self.button = "Update";
      self.editDriver(data);
    });
    $(".table-display1 tbody").on("click", "tr .deleteBtn", function () {
      var p = this.closest("tr");
      var data = $(".table-display1").DataTable().row(p).data();
      console.log("clicked............", data.name);
    //   self.globals.confirmDialogService.confirmThis(
    //     `Delete Stop : ${data.name}`,
    //     "Do You Want To Delete Stop ?",
    //     function () {
    //       self.deleteStop(data._id);
    //     },
    //     function () {}
    //   );
    });
  }

  populateTable2(active: boolean) {
    this.showLoader = false;
    let self = this;
    if ($.fn.DataTable.isDataTable(".table-display2")) {
      $(".table-display2").DataTable().clear().destroy();
    }
    $(".showhideTable").css("display", "block");
    const example = $(".table-display2").DataTable({
      data: this.ConductorDetails,
      columns: this.coloumns2,
      // dom: 'Bfrtip',
      //  buttons: []

      ordering: true,

      "fnRowCallback": function(nRow, aData, iDisplayIndex, iDisplayIndexFull) {
        //debugger;
        var index = iDisplayIndexFull + 1;
        $("td:first", nRow).html(index);
        return nRow;
      },

      language: {
        searchPlaceholder: "Search Here",
      },
      lengthMenu: [
        [5, 10, 25, 50, -1],
        [5, 10, 25, 50, "All"],
      ],
    });
    $(".table-display2 tbody").on("click", "tr .editBtn", function () {
      var p = this.closest("tr");
      var data = $(".table-display2").DataTable().row(p).data();
      console.log("clicked............", data);
      // self.button = "Update";
      self.editConductor(data);
    });
    $(".table-display2 tbody").on("click", "tr .deleteBtn", function () {
      var p = this.closest("tr");
      var data = $(".table-display2").DataTable().row(p).data();
      console.log("clicked............", data.name);
    //   self.globals.confirmDialogService.confirmThis(
    //     `Delete Stop : ${data.name}`,
    //     "Do You Want To Delete Stop ?",
    //     function () {
    //       self.deleteStop(data._id);
    //     },
    //     function () {}
    //   );
    });
  }

  populateTable3(active: boolean) {
    this.showLoader = false;
    let self = this;
    if ($.fn.DataTable.isDataTable(".table-display3")) {
      $(".table-display3").DataTable().clear().destroy();
    }
    $(".showhideTable").css("display", "block");
    const example = $(".table-display3").DataTable({
      data: this.helperDetails,
      columns: this.coloumns3,
      ordering: true,

      "fnRowCallback": function(nRow, aData, iDisplayIndex, iDisplayIndexFull) {
        var index = iDisplayIndexFull + 1;
        $("td:first", nRow).html(index);
        return nRow;
      },

      language: {
        searchPlaceholder: "Search Here",
      },
      lengthMenu: [
        [5, 10, 25, 50, -1],
        [5, 10, 25, 50, "All"],
      ],
    });
    $(".table-display3 tbody").on("click", "tr .editBtn", function () {
      var p = this.closest("tr");
      var data = $(".table-display3").DataTable().row(p).data();
      self.editHelper(data);
    });
    $(".table-display3 tbody").on("click", "tr .deleteBtn", function () {
      var p = this.closest("tr");
      var data = $(".table-display3").DataTable().row(p).data();
    });
  }

  fetchDriver(){
    this.serv.getDriverWithSession(this.globals.activeSession).subscribe((res) => {
      this.driverDetails = res.data;
      this.driverDetails.forEach(element => {
        element["licenceExpiryDate"] = element.licenceExpiryDate.substring(0, 10);
        if(element["dateOfBirth"]){
          element["dateOfBirth"] = element.dateOfBirth.substring(0, 10);
        }
      });
      this.populateTable1(true)
    });
  }

  fetchConductor(){
    this.serv.getConductorWithSession(this.globals.activeSession).subscribe((res) => {
      this.ConductorDetails = res.data;
      this.ConductorDetails.forEach(element => {
        if(element["dateOfBirth"]){
          element["dateOfBirth"] = element.dateOfBirth.substring(0, 10);
        }
      });
      this.populateTable2(true)
    });
  }


  fetchHelper(){
    this.serv.getHelperWithSession(this.globals.activeSession).subscribe((res) => {
      this.helperDetails = res.data;
      this.helperDetails.forEach(element => {
        if(element["dateOfBirth"]){
          element["dateOfBirth"] = element.dateOfBirth.substring(0, 10);
        }
      });
      this.populateTable3(true)
    });

  }

}
