import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { transportService } from '../transport.service';
import { NotifierService } from 'angular-notifier';

declare var $: any;

@Component({
  selector: 'app-list-vehicle',
  templateUrl: './list-vehicle.component.html',
  styleUrls: ['./list-vehicle.component.scss']
})
export class ListVehicleComponent implements OnInit {

  coloumns;
  vehicleDetails;
  showLoader:boolean = false;
  loadContent = "";
  viewVehicleData: any;

  constructor(private router: Router,private serv: transportService,public notifier: NotifierService) { }

  ngOnInit() {
    this.showLoader = true;

    let action_btn_edit =
      '<span class="editBtn"><i style="padding-left:10px;position:pointer; cursor: pointer;" title="Edit" ><img src="../../../assets/dash/pencil.svg"> </i></span> <span class="viewBtn"><i style="padding-left:10px;position:pointer; cursor: pointer;" title="View Shift" ><img src="../../../assets/dash/dashboard_sidebar_blue.svg"> </i></span>'
      //  <span class="deleteBtn"><i style="padding-left:10px;position:pointer;" title="delete"><img src="../../../assets/dash/Group45993.svg"></i></span> ';

    this.coloumns = [
      {
        defaultContent: "<i>Not Set</i>",
        title: "S. No.",
        // render: function (data, type, full, meta) {
        //   return i++;
        // },
      },
      { data: "registrationNumber", defaultContent: "<i>Not Set</i>", title: "Registration No." },
      {
        data: "modelNumber",
        defaultContent: "<i>Not Set</i>",
        title: "Model Year",
      },
      {
        data: "insuranceValidity",
        defaultContent: "<i>Not Set</i>",
        title: "Insurance Validity",
      },
      {
        data: "vehicleCategory",
        defaultContent: "<i>Not Set</i>",
        title: "Category",
      },
      {
        data: "VehicleType",
        defaultContent: "<i>Not Set</i>",
        title: "Type",
      },
      {
        data: "seatingCapacity",
        defaultContent: "<i>Not Set</i>",
        title: "Total Seats",
      },
      {
        data: "mileage",
        defaultContent: "<i>Not Set</i>",
        title: "Mileage",
      },
      {
        data: "gpsDeviceId",
        defaultContent: "<i>Not Set</i>",
        title: "GPS ID",
      },
      {
        data: "status",
        defaultContent: "<i>Not Set</i>",
        title: "Status",
      },
      {
        data: null,
        className: "",
        render: function (data) {
          return action_btn_edit;
        },
        defaultContent: action_btn_edit,
      },
    ];



    this.serv.getVehicles({allVehicleFlag : true , inUseVehicleFlag : false}).subscribe((res) => {

      
      this.vehicleDetails = res.data;

      this.vehicleDetails.forEach(element => {
        element["insuranceValidity"] = element.insuranceValidity.substring(0, 10);
      });
      this.populateTable(true)

      console.log("data", this.vehicleDetails)
    });

  }

  editVehicle(data){
    localStorage.setItem("vehicleDetail",JSON.stringify(data));
    this.router.navigate(['/dashboard/addVehicle']);
  }

  viewVehicle(data){
    this.viewVehicleData = data
    $("#viewVehicle").modal("show");
  }

  populateTable(active: boolean) {
    this.showLoader = false;
    let self = this;
    if ($.fn.DataTable.isDataTable(".table-display1")) {
      $(".table-display1").DataTable().clear().destroy();
    }
    $(".showhideTable").css("display", "block");
    const example = $(".table-display1").DataTable({
      data: this.vehicleDetails,
      columns: this.coloumns,
      // dom: 'Bfrtip',
      //  buttons: []

      ordering: true,

      "fnRowCallback": function(nRow, aData, iDisplayIndex, iDisplayIndexFull) {
        //debugger;
        var index = iDisplayIndexFull + 1;
        $("td:first", nRow).html(index);
        return nRow;
      },

      language: {
        searchPlaceholder: "Search Here",
      },
      lengthMenu: [
        [10, 25, 50, -1],
        [10, 25, 50, "All"],
      ],
    });
    $(".table-display1 tbody").on("click", "tr .editBtn", function () {
      var p = this.closest("tr");
      var data = $(".table-display1").DataTable().row(p).data();
      console.log("clicked............", data);
      // self.button = "Update";
      self.editVehicle(data);
    });

    $(".table-display1 tbody").on("click", "tr .viewBtn", function () {
      var p = this.closest("tr");
      var data = $(".table-display1").DataTable().row(p).data();
      self.viewVehicle(data);
    });

    $(".table-display1 tbody").on("click", "tr .deleteBtn", function () {
      var p = this.closest("tr");
      var data = $(".table-display1").DataTable().row(p).data();
      console.log("clicked............", data.name);
    //   self.globals.confirmDialogService.confirmThis(
    //     `Delete Stop : ${data.name}`,
    //     "Do You Want To Delete Stop ?",
    //     function () {
    //       self.deleteStop(data._id);
    //     },
    //     function () {}
    //   );
    });
  }

  trackVehicle() {
    this.showLoader = true;
    this.serv.getMapMyIndiaTrackingLogin().subscribe(res=>{
      if(res.status == true){
        this.notifier.notify("success","Redirecting.....");
        const url = res.RedirectedUrl;
        this.showLoader = false;
        window.open(url, '_blank');
      }},
      (err)=>{
        console.log("error page");
        this.showLoader = false;
        this.notifier.notify("error", "Service is not avaiable");
      }
    )
  }

}
