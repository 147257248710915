import { Component, OnInit } from "@angular/core";
import { ViewEncapsulation } from "@angular/core";
import { UserService } from "../../SigninSignoutO/Services/user.service";
import { AllApisService } from "../../all-apis.service";
import { OneStorage } from "../../GlobalDataStorage/oneStorage.service";
import { ActivatedRoute, Router } from "@angular/router";
import { AccessControlService } from "../../MasterSettingO/rollAccess/access-control.service";
import { AddStudentService } from "src/app/admission/services/add-student.service";
import { MasterServiceService } from "src/app/MasterSettingO/Services/master-service.service";
import { SharedService } from "./shared.service";
declare var $: any;

@Component({
  selector: "<app-dashboard1>",
  templateUrl: "dashboard.component.html",
  styleUrls: ["dashboard.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class DashboardComponent implements OnInit {
  hideBrand = false;
  preadmisionShow = true;
  schoolName: string;
  schoolUrl: string;
  schoolSession: string;
  whiteLabel = false
  allSession = [
    "2019-20",
    "2020-21",
    "2021-22",
    "2022-23",
    "2023-24",
    "2024-25",
  ];
  addSchool: boolean = false;
  listSchool: boolean = false;
  viewSchool: boolean = true;
  addUser: boolean = false;
  listUser: boolean = false;
  viewUser: boolean = true;
  userDetail: any;
  emailID = "";
  firstName = "";
  schoolObj: any;
  sideBarVar = true;
  schoolAddress: string;
  roleGrants = [];
  shopingForstudentEnable: boolean = false;
  config: any;
  var1: boolean = false;
  isAgentCode = true;
  isParentAdmission = true;
  agentCode: any;
  lat: any;
  collectFeeTransport = false;
  long: any;
  hideLeftNav: boolean = false;

  createScheduling = true;
  createFine = true;
  createConcession = true;
  createCustomConcession = true;
  bulkAllotCustomConcession = true;
  onlinePayAccess = false;
  isTransport: boolean = false;
  constructor(
    private userService: UserService,
    private allapis: AllApisService,
    private router: Router,
    private roleServ: AccessControlService,
    public addStudentService: AddStudentService,
    public globals: OneStorage, //take this to the 'signinComponent'
    private maservice: MasterServiceService,
    public route: ActivatedRoute,
    public sharedService : SharedService
  ) {
    //console.log("userDetail(from deshboard)",this.userDetail);
  }

  private callback1(res: {}, that: any) {
    that.schoolObj = res["schoolDetails"];
    // that.sectionObj=res["sectionObj"];
    console.log(that.schoolObj);

    console.log("schoolObj", that.schoolObj);
    if (typeof that.schoolObj.useTransportCollection !== "undefined")
      that.collectFeeTransport = that.schoolObj.useTransportCollection;
    console.log(" this.collectFeeTransport", that.collectFeeTransport);
    if (
      that.schoolObj &&
      that.schoolObj["sourceId"] &&
      ["2112", "2113"].includes(that.schoolObj["sourceId"])
    ) {
      that.hideBrand = true;
    }
    that.userDetail = res["userDetails"];
    if (that.userDetail["roles"][0] !== "Parent")
      that.getRoleGrants(
        that.userDetail["roles"][0],
        that.userDetail["schoolId"][0]
      );
    if (that.userDetail["imageUrl"] !== "N/A") {
      that.userDetail["imageUrl"] =
        that.allapis.baseurl + "/" + that.userDetail["imageUrl"];
      $("#imageNew1").attr("src", that.userDetail["imageUrl"]);
      $("#imageNew2").attr("src", that.userDetail["imageUrl"]);
    } else {
      $("#imageNew1").attr("src", "assets/img/img1.png");
      $("#imageNew2").attr("src", "assets/img/img1.png");
    }
    if (that.schoolObj === undefined) {
      that.schoolName = " ";
      that.schoolSession = "2019-20";
    } else {
      that.schoolName = that.schoolObj["name"];
      if (that.schoolObj["session"]) {
        that.schoolSession = that.schoolObj["session"];
      } else {
        that.schoolSession = "2019-20";
      }
    }
    if (that.schoolObj["adresss"] === undefined) {
      // that.schoolName = " ";
      console.log("not working");
      that.schoolAddress = "";
    } else {
      that.schoolAddress = that.schoolObj["adresss"]["line1"];
    }
    // console.log(that.schoolObj);
    if (
      !that.schoolObj["logoUrl"] ||
      that.schoolObj["logoUrl"] == "" ||
      that.schoolObj["logoUrl"] == "N/A"
    ) {
      that.schoolUrl = " ";
    } else if (that.schoolObj["logoUrl"]) {
      that.schoolUrl = that.schoolObj["logoUrl"];
    } else {
      that.schoolUrl =
        "https://d3nwkpv8dwssuq.cloudfront.net/school-logo-old" +
        that.schoolObj["logoUrl"].replace(/\\/g, "/");
    }
    console.log(that.schoolDetails);
    that.addStudentService.schoolId = that.userDetail.schoolId; // Isko koi haath nhi lagayega :)
    console.log(that.userDetail);
  }
  // updateSchoolname(schoolName){
  //   this.schoolName=schoolName;
  // }
  ngOnInit() {
    if (localStorage.getItem("userType") && localStorage.getItem("userType") == "Parent") {
      this.moveToMobileOrSm();
    }
    if(localStorage.getItem("transport") && localStorage.getItem("transport") == "Y"){
      this.isTransport = true
    }
    let localHideLeftNav = localStorage.getItem("hideLeftNav");
    if (localHideLeftNav && localHideLeftNav == "Y") {
      this.hideLeftNav = true;
    }
    if (this.route.queryParams) {
      this.route.queryParams.subscribe((params) => {
        console.log("side", params.agentCode);
        if (params.agentCode) {
          this.isAgentCode = false;
          this.agentCode = params.agentCode;
          for (var key in this.map) {
            this.map[key] = false;
          }
          this.preadmisionShow = false;
          this.onlinePayAccess = false;
          this.map["agentSchool"] = true;
          return;
        }
        if (params.token) {
          localStorage.setItem("token", params.token);
        }
        if (params.lat && params.long) {
          for (var key in this.map) {
            this.map[key] = false;
          }
          this.isAgentCode = false;
          this.lat = params.lat;
          this.long = params.long;
          this.preadmisionShow = false;
          this.isParentAdmission = false;
          this.map["parentAdmission"] = true;
        }
      });
    }
    if (
      this.globals.schoolDetails === null ||
      this.globals.schoolDetails === undefined ||
      Object.entries(this.globals.schoolDetails).length === 0 ||
      this.globals.userDetails === null ||
      this.globals.userDetails === undefined ||
      Object.entries(this.globals.userDetails).length === 0
    ) {
      this.globals.initiateUserDetails(this.callback1, this);
      this.globals.updateHeaderList(undefined, this);
      // this.schoolName=this.schoolObj["name"];
    } else {
      console.log("already there", this.schoolObj);
      this.schoolObj = this.globals.schoolDetails;
      this.schoolName = this.schoolObj["name"];
      if (
        this.schoolObj &&
        this.schoolObj["sourceId"] &&
        ["2112", "2113"].includes(this.schoolObj["sourceId"])
      ) {
        this.hideBrand = true;
      }
      this.getRoleGrants(
        this.userDetail["roles"][0],
        this.userDetail["schoolId"][0]
      );
      if (this.userDetail["imageUrl"] !== "N/A") {
        this.userDetail["imageUrl"] =
          this.allapis.baseurl + "/" + this.userDetail["imageUrl"];
        $("#imageNew1").attr("src", this.userDetail["imageUrl"]);
        $("#imageNew2").attr("src", this.userDetail["imageUrl"]);
      } else {
        $("#imageNew1").attr("src", "assets/img/img1.png");
        $("#imageNew2").attr("src", "assets/img/img1.png");
      }
    }

    this.addStudentService.getConfiguration().subscribe((res) => {
      this.config = res;
      console.log(this.config);
      if (
        this.config &&
        this.config.payment &&
        this.config.payment.showHide == "Show"
      ) {
        this.var1 = true;
      }
    });

    this.getShopingForstudentEnable();

    let white = localStorage.getItem("whitelabel");
    console.log("whitelabel", white);
    if (white && white == "Y") {
      this.whiteLabel = true;
    }

    // console.log('ngonit in dashboard', localStorage.getItem('schoolDetail'))
    // if(localStorage.getItem('schoolDetail') && localStorage.getItem('schoolDetail') != undefined && localStorage.getItem('schoolDetail') != 'undefined'){
    //         try {
    //             this.schoolObj= JSON.parse(localStorage.getItem('schoolDetail'));
    //             this.schoolName=schoolObj["name"];
    //         } catch(e) {
    //             console.log(e); // error in the above string (in this case, yes)!

    //     }
    // }

    // else{
    //     this.schoolName=" ";
    // }

    //  if(localStorage.getItem('userDetail') && localStorage.getItem('userDetail') != undefined && localStorage.getItem('userDetail') != 'undefined'){
    //         try {
    //             this.userDetail = JSON.parse(localStorage.getItem('userDetail'));
    //         } catch(e) {
    //             console.log(e); // error in the above string (in this case, yes)!

    //     }
    // }

    $(".br-sideleft").addClass(localStorage.getItem("sideleftColor"));
    $(".br-menu-sub").addClass(localStorage.getItem("menusubColor"));
    $(".br-menu-link").addClass(localStorage.getItem("hoverColor"));
    $(".br-menu-link.active").addClass(
      localStorage.getItem("menusubActiveColor")
    );
    $(".ec-handle").addClass(localStorage.getItem("themeBtnColor"));
    $(".user-profile-nav .icon ").addClass(
      localStorage.getItem("headerCtrColor")
    );
    $(".br-header-center .defaultHdrColr").addClass(
      localStorage.getItem("headerCtrColor")
    );
    $(".br-header-left .navicon-left .menu li a i").addClass(
      localStorage.getItem("headerCtrColor")
    );
    let today = new Date().toLocaleDateString()
    $(".currentDate").text(today)
    $(".show-sub + .br-menu-sub").slideDown();
    $("#btnLeftMenu").on("click", function () {
      var menuText = $(".menu-item-label,.menu-item-arrow");
      if ($("body").hasClass("collapsed-menu")) {
        $("body").removeClass("collapsed-menu");
        $(".sidebar-footer").css("visibility", "visible");
        $(".show-sub + .br-menu-sub").slideDown();
        $(".br-sideleft").one("transitionend", function (e) {
          menuText.removeClass("op-lg-0-force");
          menuText.removeClass("d-lg-none");
        });
      } else {
        $("body").addClass("collapsed-menu");
        $(".show-sub + .br-menu-sub").slideUp();
        menuText.addClass("op-lg-0-force");
        $(".br-sideleft").one("transitionend", function (e) {
          menuText.addClass("d-lg-none");
        });
      }
      return false;
    });
    $(document).on("mouseover", function (e) {
      e.stopPropagation();
      if (
        $("body").hasClass("collapsed-menu") &&
        $("#btnLeftMenu").is(":visible")
      ) {
        var targ = $(e.target).closest(".br-sideleft").length;
        if (targ) {
          $("body").addClass("expand-menu");
          // show current shown sub menu that was hidden from collapsed
          $(".show-sub + .br-menu-sub").slideDown();
          var menuText = $(".menu-item-label,.menu-item-arrow");
          menuText.removeClass("d-lg-none");
          menuText.removeClass("op-lg-0-force");
          $(".sidebar-footer").css("visibility", "visible");
        } else {
          $("body").removeClass("expand-menu");
          // hide current shown menu
          $(".show-sub + .br-menu-sub").slideUp();
          var menuText = $(".menu-item-label,.menu-item-arrow");
          menuText.addClass("op-lg-0-force");
          menuText.addClass("d-lg-none");
          $(".sidebar-footer").css("visibility", "hidden");
        }
      }
    });

    $("#btnLeftMenuMobile").on("click", function () {
      $("body").addClass("show-left");
      return false;
    });
    $(document).on("click", function (e) {
      e.stopPropagation();
      // closing left sidebar
      if ($("body").hasClass("show-left")) {
        var targ = $(e.target).closest(".br-sideleft").length;
        if (!targ) {
          $("body").removeClass("show-left");
        }
      }
      // closing right sidebar
      if ($("body").hasClass("show-right")) {
        var targ = $(e.target).closest(".br-sideright").length;
        if (!targ) {
          $("body").removeClass("show-right");
        }
      }
    });

    $(".js-search").click(function () {
      $(".search-bar").addClass("open");
    });
    $(".close-search").click(function () {
      $(".search-bar").removeClass("open");
    });

    (function ($) {
      "use strict";
      $(".ec-handle").on("click", function () {
        $(".ec-colorswitcher").trigger("click");
        $(this).toggleClass("btnclose");
        $(".ec-colorswitcher").toggleClass("sidebarmain");
        return false;
      });
    })($);

    $(".styleswitch").click(function () {
      switchStylestyle(this.getAttribute("data-rel"));
      return false;
    });

    function switchStylestyle(styleName) {
      var sideleftColrArr = [
        "color-one",
        "color-two",
        "color-three",
        "color-four",
      ];
      const menusubColrArr = [
        "cs-menu-one",
        "cs-menu-two",
        "cs-menu-three",
        "cs-menu-four",
      ];
      const menusubActiveColorArr = [
        "active-menu-one",
        "active-menu-two",
        "active-menu-three",
        "active-menu-four",
      ];
      const hoverColArr = [
        "hover-menu-one",
        "hover-menu-two",
        "hover-menu-three",
        "hover-menu-four",
      ];
      const themeBtnColArr = [
        "color-theme-one",
        "color-theme-two",
        "color-theme-three",
        "color-theme-four",
      ];
      const hederCtrColorArr = [
        "active-hdrCtr-one",
        "active-hdrCtr-two",
        "active-hdrCtr-three",
        "active-hdrCtr-four",
      ];
      for (var i = 0; i < sideleftColrArr.length; i++) {
        if (styleName === sideleftColrArr[i]) {
          if (typeof Storage !== "undefined") {
            localStorage.setItem("sideleftColor", sideleftColrArr[i]);
            localStorage.setItem("menusubColor", menusubColrArr[i]);
            localStorage.setItem("hoverColor", hoverColArr[i]);
            localStorage.setItem(
              "menusubActiveColor",
              menusubActiveColorArr[i]
            );
            localStorage.setItem("themeBtnColor", themeBtnColArr[i]);
            localStorage.setItem("headerCtrColor", hederCtrColorArr[i]);
          }
          $(".br-sideleft").addClass(sideleftColrArr[i]);
          $(".br-menu-sub").addClass(menusubColrArr[i]);
          $(".br-menu-link").addClass(hoverColArr[i]);
          $(".br-menu-link.active").addClass(menusubActiveColorArr[i]);
          $(".ec-handle").addClass(themeBtnColArr[i]);
          $(".user-profile-nav .icon ").addClass(hederCtrColorArr[i]);
          $(".br-header-center .defaultHdrColr").addClass(hederCtrColorArr[i]);
          $(".br-header-left .navicon-left .menu li a i").addClass(
            hederCtrColorArr[i]
          );
          $(".currentDate").addClass(hederCtrColorArr[i]);
        } else {
          $(".br-sideleft").removeClass(sideleftColrArr[i]);
          $(".br-menu-sub").removeClass(menusubColrArr[i]);
          $(".br-menu-link").removeClass(hoverColArr[i]);
          $(".br-menu-link.active").removeClass(menusubActiveColorArr[i]);
          $(".ec-handle").removeClass(themeBtnColArr[i]);
          $(".user-profile-nav .icon ").removeClass(hederCtrColorArr[i]);
          $(".br-header-center .defaultHdrColr").removeClass(
            hederCtrColorArr[i]
          );
          $(".br-header-left .navicon-left .menu li a i").removeClass(
            hederCtrColorArr[i]
          );
          $(".currentDate").removeClass(hederCtrColorArr[i]);
        }
      }
    }
    $("#btnAppsMenu").on("click", function () {
      console.log("Showing AppMenu...");
    });
    var date = new Date();
    var months = [
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUN",
      "JUL",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ];
    var val =
      months[date.getMonth()] + " " + date.getDate() + " " + date.getFullYear();

    var time = date.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
    $(".currentTime").text(time);
  }
  schoolListParent() {
    this.router.navigate(["/dashboard/admission/searchSchool/"], {
      queryParams: { lat: this.lat, long: this.long },
    });
  }
  statusParent() {
    this.router.navigate(["/dashboard/admission/parentStudentStatus/"], {
      queryParams: { lat: this.lat, long: this.long },
    });
  }
  sideAddSchool() {
    this.router.navigate(["/dashboard/admission/addSchool/"], {
      queryParams: { agentCode: this.agentCode },
    });
  }

  searchSchool() {
    this.router.navigate(["/dashboard/admission/schoolListForAgent/"], {
      queryParams: { agentCode: this.agentCode },
    });
  }

  cd() {
    console.log(this.sideBarVar);
  }

  onSessionChange(){
      console.log("session", this.globals.activeSession)
      this.sharedService.sendClickEvent(this.globals.activeSession);
  }

  onSignOut() {
    // console.log("method fired");
    this.globals.destroyVariables();
    this.allapis.logoutUser();

    // localStorage.removeItem('token');
    // localStorage.removeItem('userDetail');
    // localStorage.clear();
    // // location.reload();
    //this.router.navigate([""]);
    // console.log("Token now :"+ this.allapis.getToken())
  }

  justNavigate() {
    console.log("this.map here", this.map)
    if (localStorage.getItem("userType") == "Super Admin") {
      this.router.navigate(["/dashboard/welcomeScreen"]);
    } else if (localStorage.getItem("userType") == "Admission In-charge" && this.map["Pre Admission"]) {
      this.router.navigate(["/dashboard/admission"]);
    }else if (this.isTransport) {
      this.router.navigate(["/dashboard/transportDashboard"]);
    } else if (this.map["Collect Fee"]) {
      // this.router.navigate(["/dashboard/collect/generatereceipt"]);
      this.router.navigate(["/dashboard/collect/CollectFeeMiscComponent"]);
    } else if (this.map["School Shop"]) {
      this.router.navigate(["/dashboard/inventory/addCategory"]);
    } else if (localStorage.getItem("userType") == "Parent") {
      this.moveToMobileOrSm();
    } else {
      this.router.navigate(["/dashboard/blankContent"]);
    }
  }

  moveToMobileOrSm() {
      var resObj = {}
      resObj['status'] = true
      resObj['message'] = "Failed"
      resObj['result'] = {}
      console.log('json objec', JSON.stringify(resObj));
      if (window.webkit && window.webkit.messageHandlers) {
        window.webkit.messageHandlers.errorViewCallBack.postMessage(JSON.stringify(resObj));
      } else if (window.Android) {
        window.Android.maketoast(JSON.stringify(resObj));
      } else {
        localStorage.removeItem("token");
        localStorage.removeItem("userDetail");
        localStorage.clear();
        window.location.href='https://schoolmitra.com/';
      }
  }
  
  changeIcon() {
    if (this.sideBarVar === true) {
      this.sideBarVar = false;
    } else {
      this.sideBarVar = true;
    }
  }

  getRoleGrants(role: any, schoolId: any) {
    console.log("roles", role, schoolId);
    this.map["Dashboard"] = true;
    if (role === "Parent") {
      for (var key in this.map) {
        this.map[key] = false;
      }
      this.preadmisionShow = false;
      this.onlinePayAccess = false;
      this.map["parentAdmission"] = true;
      // this.router.navigate(['/dashboard/welcomeScreen']);
    } else if (
      ["Super Admin", "Director", "Chairman", "Directorpa"].includes(role)
    ) {
      this.map["Master Setting"] = true;
      this.onlinePayAccess = true;
    } else if (role === "PPAdmin") {
      for (var key in this.map) {
        this.map[key] = false;
      }
      this.preadmisionShow = false;
      this.map["ppSchoolList"] = true;
      this.map["ppSmsUpdates"] = true;
      this.map["ppQueryList"] = true;
      this.map["ppSyncAll"] = true;
      this.map["ppMerchantList"] = true;
      this.map["ppAgentList"] = true;
      return;
    } else if (role === "Agent") {
      for (var key in this.map) {
        this.map[key] = false;
      }
      this.onlinePayAccess = false;
      this.preadmisionShow = false;
      this.map["agentSchool"] = true;
      return;
    } else if (role === "Admission In-charge") {
      for (var key in this.map) {
        this.map[key] = false;
      }
      this.map["Pre Admission"] = true;
      this.onlinePayAccess = false;
      console.log(this.map);
      this.preadmisionShow = false;
      return;
    } else if (role === "Admin") {
      this.onlinePayAccess = true;
    }
    this.roleServ.fetchRoles(schoolId).subscribe((res) => {
      console.log(res);
      loadSidebar();
      this.roleGrants = res.roleAccesslist;
      this.roleServ.roleGrants = res.roleAccesslist;
      this.roleGrants.forEach((roleobj) => {
        if (roleobj["role"] === role) {
          roleobj.defination.forEach((elem) => {
            this.map[elem.module] = elem.inUse;
            console.log(
              "this.map[elem.module]",
              elem.module,
              this.map[elem.module]
            );
            if (elem.module == "Create Scheduling" && !elem.inUse) {
              this.createScheduling = false;
            }
            if (elem.module == "Create Fine" && !elem.inUse) {
              this.createFine = false;
            }
            if (elem.module == "Create Concession" && !elem.inUse) {
              this.createConcession = false;
            }
            if (elem.module == "Create Custom Concession" && !elem.inUse) {
              this.createCustomConcession = false;
            }
            if (elem.module == "Bulk allot Custom Concession" && !elem.inUse) {
              this.bulkAllotCustomConcession = false;
            }
          });
        }
      });
    });
    function loadSidebar() {
      $(".br-menu-link").on("click", function () {
        var nextElem = $(this).next();
        var thisLink = $(this);
        if (nextElem.hasClass("br-menu-sub")) {
          if (nextElem.is(":visible")) {
            thisLink.removeClass("show-sub");
            nextElem.slideUp();
          } else {
            $(".br-menu-link").each(function () {
              $(this).removeClass("show-sub");
            });
            $(".br-menu-sub").each(function () {
              $(this).slideUp();
            });
            thisLink.addClass("show-sub");
            nextElem.slideDown();
          }
          return false;
        } else {
          $(".br-menu-link").each(function () {
            $(this).removeClass("show-sub");
          });

          $(".br-menu-sub").each(function () {
            $(this).slideUp();
          });

          thisLink.addClass("show-sub");
        }
      });
    }
  }
  //   master_access(moduleName:any){
  //     if(this.userDetail["schoolId"][0]===undefined || this.userDetail["schoolId"][0]===null){return this.has_access(moduleName);}
  //     // if (this.userDetail !== null && this.userDetail !== undefined &&   this.userDetail["roles"] !==undefined && this.userDetail["roles"] !==null && this.userDetail["roles"].length>=0) {

  //     // var role=this.userDetail["roles"][0];
  //     // // console.log(this.roleGrants);
  //     // this.roleGrants[0].defination.forEach(elem1=>{
  //     //     if(elem1.module===moduleName){
  //     //         // console.log(moduleName);
  //     //      console.log(elem1.inUse);
  //     //       return elem1.inUse;

  //     //     }
  //     //   })
  //     // }

  //   }

  getShopingForstudentEnable() {
    this.maservice.getOthersInfo().subscribe((res) => {
      console.log("--getAdmitCardOncollectFee -", res);
      if (res.length > 0) {
        this.shopingForstudentEnable = res[0].shopingForstudent;
      }
    });
  }
  updateSchoolName(test, adress, logourl) {
    this.schoolName = test;
    this.schoolAddress = adress;
    this.schoolUrl = this.allapis.baseurl + "/" + logourl;
    //console.log("hellllo updating schoool name ",this.schoolName)
  }
  has_access(module) {
    switch (module) {
      case "School SetUp":
        return true;
      case "User Details":
        return true;
      case "Master Setting":
        return true;
      case "General Setting":
        return true;
      default:
        return false;
    }
  }
  map = {
    "School Setup": false,
    "User Details": false,
    "Student Details": false,
    Transport: false,
    Scheduling: false,
    Inventory: false,
    "Daily Book": false,
    "School Shop": false,
    Reporting: false,
    "Master Setting": false,
    "General Setting": false,
    "Pre Admission": false,
    ppSchoolList: false,
    ppQueryList: false,
    ppSmsUpdates: false,
    ppSyncAll: false,
    ppMerchantList: false,
    parentAdmission: false,
  };
}
