import { Component, OnInit } from '@angular/core';
import { ShiftModel } from "../Models/Shift";
import { NotifierService } from "angular-notifier";
import { transportService } from '../transport.service';
import { OneStorage } from "src/app/GlobalDataStorage/oneStorage.service";
declare var $: any;


@Component({
  selector: 'app-add-shift',
  templateUrl: './add-shift.component.html',
  styleUrls: ['./add-shift.component.scss']
})
export class AddShiftComponent implements OnInit {
  shiftRemark :  string = "";
  shift = new ShiftModel();
  tableData = [];
  columns = [];
  voucherAvailable: boolean = false;
  pdfDataObj: any;
  allVehicle: any;
  vehicleId: any;
  session:any;
  studentList: any;
  AllShiftList: any;

  showLoader:boolean = false;
  loadContent = "";

  constructor(
    public notifier: NotifierService,
    private globals: OneStorage,
    private serv: transportService,

  ) {}

  ngOnInit() {
    this.createTable ();
  }
  
  onAddingShift () {
    if(!this.shift.name) {
      return this.notifier.notify("error", "Please Enter Shift Name");
    }

    this.serv.addShift(this.shift).subscribe(
      (res) => {
        this.notifier.notify("success", "Shift Name Added Successfully");
        this.shift = new ShiftModel();
        this.createTable();
      },
      (err) => {
        this.notifier.notify("error", "Shift Name Already Exist");
        // console.error("Backend Error:", err);
      }
    );    
  }

  createTable () {
    this.showLoader = true;
    this.serv.fetchAllShift().subscribe(
      (res) => {
        this.AllShiftList = res.msg;

        this.populateTable();
      },
      (err) => {
        this.notifier.notify("error", err.error);
      }
    );
  }

  populateTable() {
    this.showLoader = false;
    let that = this;
    this.columns = [
      { data: "name", 
        title: "Shift Name", 
        defaultContent: "<i>_</i>" 
      },
      {
        data: "remark",
        title: "Remark",
        defaultContent: "<i>not set</i>",
      },
    ];

    if ($.fn.DataTable.isDataTable(".example")) {
      $(".example").DataTable().clear().destroy();
    }
    $(".showhideTable").css("display", "block");
    const example = $(".example").DataTable({
      data: this.AllShiftList,
      columns: this.columns,
      columnDefs: [
        {
          visible: false,
          searchable: false,
        },
      ],

      dom: "Bfrtip",
      order: [],
      buttons: [
        "pageLength",
        {
          extend: 'excelHtml5',
          exportOptions: {
              columns: ':not(:nth-child(3))'
        }
        },
        {
          text: "Pdf",
          action: function () {
            generatePdf();
          },
        },
      ],
      lengthMenu: [
        [5, 10, 25, 50, -1],
        [5, 10, 25, 50, "All"],
      ],
      language: {
        searchPlaceholder: "Search Here",
      },
    });
    async function generatePdf() {
      that.voucherAvailable = true;
      try {
        that.columns.forEach((ele) => {
          that.columns.forEach((zz) => {
            if (!ele.hasOwnProperty(zz.data)) {
              ele[zz.data] = "Not set";
            }
          });
        });
        const inputDateString = new Date();
        const inputDate = new Date(inputDateString);

        const day = inputDate.getDate().toString().padStart(2, '0');
        const month = (inputDate.getMonth() + 1).toString().padStart(2, '0');
        const year = inputDate.getFullYear();

        const hours = inputDate.getHours().toString().padStart(2, '0');
        const minutes = inputDate.getMinutes().toString().padStart(2, '0');

        const formattedDate = `${day}/${month}/${year} ${hours}:${minutes}`;
        var title = "Report generated on  :" + "-  " + formattedDate;
        var reportName = "Shift List";

        await that.globals
          .getPdfDataObj(that.columns, that.AllShiftList, title, reportName)
          .then((res) => {
            that.pdfDataObj = res;
          });
      } catch (e) {
        console.log(e);
      }
    }
  }


}
