import { Component, OnInit } from '@angular/core';
import { HelperModel, salaryDetail } from "../Models/Helper";
import { NotifierService } from "angular-notifier";
import { transportService } from "../transport.service";
import { Router } from "@angular/router";
import { OneStorage } from "../../GlobalDataStorage/oneStorage.service";
import { SharedService } from "src/app/dashboardO/dashboard/shared.service";


@Component({
  selector: 'app-add-helper',
  templateUrl: './add-helper.component.html',
  styleUrls: ['./add-helper.component.scss']
})
export class AddHelperComponent implements OnInit {
  disableScroll(event: Event): void {
    event.preventDefault();
  }
  conductor = new HelperModel()
  helper = new HelperModel()
  date: any;
  profilePicture: any;
  govtId : any;
  button: string = "Add Helper";
  canConductorBeDeactivated = true;
  vehicleAndId:any;
  vehicleDetails:any;

  conductorId : string;
  tableRowArr = [new salaryDetail()];
  salaryButton = "Add Salary";
  clickEventsubscription: any;

  constructor(
    public notifier: NotifierService,
    private serv: transportService,
    private router: Router,
    private globals: OneStorage,
    public sharedService : SharedService,

  ) { 
    this.clickEventsubscription = this.sharedService
    .getClickEvent()
    .subscribe(() => {
      this.globals.activeSession = this.sharedService.getSession();
    });
  }

  ngOnInit() {
    this.button = "Add Helper";
    if (localStorage.getItem("helperDetails")) {
      try {
        this.helper = JSON.parse(localStorage.getItem("helperDetails"));

        if(this.helper.salaryDetail.length > 0) {
          this.tableRowArr =   this.helper.salaryDetail;
          this.salaryButton = "Update/View"
        } else {
          this.tableRowArr = [new salaryDetail()];
        }
        
        this.button = "Update Helper";

        localStorage.removeItem("helperDetails");
      } catch (e) {
        alert(e);
      }
    }
    this.getAllVehicle();
  }


  validateForm() {
    if (
      Object.keys(this.helper).length === 0 ||
      !this.helper.name ||
      this.helper.name.trim() == ""
    ) {
      this.notifier.notify("error", "Please Enter a Name.");
      return false;
    }

    if (
      Object.keys(this.helper).length === 0 ||
      !this.helper.contact ||
      this.helper.contact == null
    ) {
      this.notifier.notify("error", "Please Enter contact number.");
      return false;
    }

    // for (let i = 0; i < this.tableRowArr.length; i++) {

    //   let trow = this.tableRowArr[i];

    //   if(trow.month)
    //   {
    //     if (!trow.month) {
    //       return this.notifier.notify("error", `Please Select Month`);
    //     }
    //     if (trow.salary === undefined || trow.salary === null) {
    //       return this.notifier.notify("error", `Please Enter Salary For Blank Field`);
    //     }
    //     if (!trow.session) {
    //       trow.session = this.globals.globalSession;
    //     }
    //   }

    // }
    return true;
  }

  onChange(newValue) {
    this.serv.checkAllocation({user : "Conductor", id : this.conductorId}).subscribe( (res) => {
      console.log("res from allocation check", res)

      if(res.status == false){
        this.notifier.notify("error", res.msg);
        this.canConductorBeDeactivated = false
      }else{
        this.canConductorBeDeactivated = true
      }
    })
}


  resetPageDetails() {
    this.helper = new HelperModel();
    this.vehicleAndId = null;
    this.tableRowArr = [new salaryDetail()];
  }

  addHelper() {    
    if (!this.validateForm()) {
      return;
    }
    if(this.vehicleAndId) {
      this.helper.vehicle = this.vehicleAndId.vehicle;
      this.helper.vehicleId = this.vehicleAndId.vehicleId;
    }

    this.helper.salaryDetail = this.tableRowArr

    if(this.button == "Update Helper"){
      this.helper.session = this.globals.activeSession
      this.serv.updateHelper(this.helper).subscribe(
        (res) => {
          this.notifier.notify("success", "Helper Updated successfully");
          console.log("response", res);
          this.router.navigate(["/dashboard/listDriverConductor"]);
          this.resetPageDetails();
        },
        (err) => {
          this.notifier.notify("error", "Please Try Again");
        }
      );
      localStorage.setItem("refreshTable", JSON.stringify(true));
      
    }else{
      this.helper.session = this.globals.activeSession
      this.helper.salaryDetail = this.tableRowArr;
      this.serv.addHelper(this.helper).subscribe(
        (res) => {
          console.log(res);
          if(res.status){
            this.notifier.notify("success", "Helper Added Successfully");
            this.resetPageDetails()
          }else{
            this.notifier.notify("error",res.msg);
          }
        },
        (err) => {
          console.log(err);
          this.notifier.notify("error", "Error:" + err);
        }
      );
    }
  }

  parseDate(dateString: string): Date {
    let dateVar: any = null;
    if (dateString) {
      dateVar = new Date(dateString);
    }
    console.log(dateVar);
    return dateVar;
  }

  deleteRow(index) {
    if (index != 0) {
      let newArr = this.tableRowArr;
      newArr.splice(index, 1);
      this.tableRowArr = newArr;
    }
  }

  addRow() {    
    let lastObj = this.tableRowArr[this.tableRowArr.length - 1]
    if (lastObj.month && lastObj.salary) {
      this.tableRowArr.push(new salaryDetail());

    } else {
      this.notifier.notify("error", "Empty fields not allowed")
    }
  }

  getAllVehicle() {
    this.serv.getVehicles({allVehicleFlag : true , inUseVehicleFlag : false}).subscribe((res) => {
      this.vehicleDetails = res.data;
    });
    
  }

  getFunc() {
    if(this.tableRowArr.length === 0) {
      this.tableRowArr = [new salaryDetail()];
    }
  }

  onAddDocument() {
    let concessionData = this.tableRowArr;
    for (let i = 0; i < this.tableRowArr.length; i++) {
      let trow = this.tableRowArr[i];

      if(trow.month)
      {
        if (!trow.month) {
          return this.notifier.notify("error", `Please Select a Month`);
        }
        if (trow.salary === undefined || trow.salary === null) {
          return this.notifier.notify("error", `Please Enter Salary For Blank Field`);
        }
      }
      
      const duplicateIndex = this.tableRowArr.findIndex((row, index) => index !== i && row.month === trow.month);
      if (duplicateIndex !== -1) {
        return this.notifier.notify("error", `'${trow.month}' is already selected.`);
      }

    }

    this.tableRowArr = [];
    for (const doc of concessionData) {
      const { ...newDocument } = doc;
      newDocument.session = this.globals.activeSession;
      this.tableRowArr.push(newDocument)
    }
  }

}
