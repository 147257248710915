import { Component, OnInit, Inject } from "@angular/core";
import { NotifierService } from "angular-notifier";
import { OneStorage } from "src/app/GlobalDataStorage/oneStorage.service";
import { ConfirmDialogService } from "src/app/shared/confirm-dialog/confirm-dialog.service";
import { transportService } from "../transport.service";
import { DOCUMENT } from '@angular/common';

declare var $: any;

@Component({
  selector: "app-driver-conductor-vehicle-allocation",
  templateUrl: "./driver-conductor-vehicle-allocation.component.html",
  styleUrls: ["./driver-conductor-vehicle-allocation.component.scss"],
})
export class DriverConductorVehicleAllocationComponent implements OnInit {
  button = "Save Allocation";
  vehicleList = [];
  driverList = [];
  conductorList = [];
  tableData = [];
  previousDriverConductorDetails = {};
  selectedConductor;
  selectedVehicle;
  selectedDriver;
  coloumns = [];
  showLoader:boolean = false;
  loadContent = "";
  updateFlag = false;

  constructor(
    private serv: transportService,
    public notifier: NotifierService,
    public confirmDialogService: ConfirmDialogService,
    public globals: OneStorage,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit() {
    this.selectedVehicle = "0";
    this.selectedDriver = "0";
    this.selectedConductor = "0";

    this.updateFlag = false;

    let action_btn_edit =
      '<span class="editBtn"><i  title = "Edit" style="padding-left:10px;position:pointer; cursor:pointer;"><img src="../../../assets/dash/pencil.svg"> </i></span> <span class="deleteBtn"><i style="padding-left:10px;position:pointer; cursor:pointer;" title="Delete"><img src="../../../assets/dash/Group45993.svg"></i></span> ';

    var i = 1;

    this.coloumns = [
      {
        defaultContent: "<i>Not Set</i>",
        title: "S. No.",
        // render: function (data, type, full, meta) {
        //   return i++;
        // },
      },
      {
        data: "registrationNumber",
        defaultContent: "<i>Not Set</i>",
        title: "Vehicle No.",
      },
      {
        data: "driverName",
        defaultContent: "<i>Not Set</i>",
        title: "Driver Name",
      },
      {
        data: "conductorName",
        defaultContent: "<i>Not Set</i>",
        title: "Conductor Name",
      },
      {
        data: null,
        className: "",
        render: function (data) {
          return action_btn_edit;
        },
        defaultContent: action_btn_edit,
      },
    ];

    this.getAllocatedVehicles()

    this.serv.getDriver().subscribe((res) => {
      this.driverList = res.data;
      // console.log("driver", res);
    });

    this.serv.getConductor().subscribe((res) => {
      this.conductorList = res.data;
      // console.log("conductor", res);
    });
  }

  scrollToTop() {
    this.document.body.scrollTop = 0; 
    this.document.documentElement.scrollTop = 0; 
  }

  getAllocatedVehicles(){
    this.showLoader = true;
    this.serv.getVehicles({allVehicleFlag : false , inUseVehicleFlag : true}).subscribe((res) => {
      this.vehicleList = res.data;
      console.log("vehicles", res);
      let data = [];
      this.vehicleList.forEach((elem) => {
        if (elem.driverName) {
          data.push(elem);
        }
      });
      this.tableData = data;
      this.populateTable(true);
    });
  }

  validateForm() {
    if (this.selectedVehicle == "0" || this.selectedVehicle.trim() == "") {
      this.notifier.notify("error", "Please Select a Vehicle.");
      return false;
    }
    if (this.selectedDriver == "0") {
      this.notifier.notify("error", "Please select a Driver.");
      return false;
    }

    if (this.selectedConductor == "0") {
      this.selectedConductor = "";
    }

    return true;
  }

  allocateDriverCondToVehicle() {
    let allocateData = {
      vehicle: this.selectedVehicle,
      driver: this.selectedDriver,
      conductor: this.selectedConductor,
    };

    if(this.updateFlag){
      allocateData["previousDriverConductorDetails"] = this.previousDriverConductorDetails
    }

    if (!this.validateForm()) {
      return;
    }

    this.serv
      .allocateVehicleToDriverConductor(allocateData)
      .subscribe((res) => {
        console.log(res);
        this.updateFlag = false;
        this.getAllocatedVehicles()
        this.notifier.notify("success", res.msg);
      });

      this.resetPageDetails();
  }

  resetPageDetails() {
    this.selectedVehicle = "0";
    this.selectedDriver = "0";
    this.selectedConductor = "0";
  }


  

  editAllocation(data) {
    this.scrollToTop();
    this.updateFlag = true;
    this.selectedVehicle = data._id;
    this.previousDriverConductorDetails["conductor"] = data.conductor;
    this.previousDriverConductorDetails["driver"] = data.driver;
    this.previousDriverConductorDetails["status"] = "updated";
  }

  deleteAllocation(data){

    let allocateData = {
      vehicle: data._id,
      driver: null,
      conductor: null,
    };
    this.previousDriverConductorDetails["conductor"] = data.conductor;
    this.previousDriverConductorDetails["driver"] = data.driver;
    this.previousDriverConductorDetails["status"] = "Deleted";

    allocateData["previousDriverConductorDetails"] = this.previousDriverConductorDetails
    
    console.log("allocateData", allocateData)

    this.serv
      .allocateVehicleToDriverConductor(allocateData)
      .subscribe((res) => {
        console.log(res);
        this.notifier.notify("success", "Allocation Deleted Successfully");
      });
  }

  populateTable(active: boolean) {
    this.showLoader = false;
    let self = this;
    if ($.fn.DataTable.isDataTable(".table-displayDriverConductorVehicle")) {
      $(".table-displayDriverConductorVehicle").DataTable().clear().destroy();
    }
    $(".showhideTable").css("display", "block");
    const example = $(".table-displayDriverConductorVehicle").DataTable({
      data: this.tableData,
      columns: this.coloumns,

      bFilter: true,

      bInfo: true,
      bLengthChange: true,
      ordering: true,
      "fnRowCallback": function(nRow, aData, iDisplayIndex, iDisplayIndexFull) {
        //debugger;
        var index = iDisplayIndexFull + 1;
        $("td:first", nRow).html(index);
        return nRow;
      },


      language: {
        searchPlaceholder: "Search Here",
        oPaginate: {
          sNext: '<i class="fa fa-forward"></i>',
          sPrevious: '<i class="fa fa-backward"></i>',
          sFirst: '<i class="fa fa-step-backward"></i>',
          sLast: '<i class="fa fa-step-forward"></i>',
        },
      },
      lengthMenu: [
        [5, 10, 25, 50, -1],
        [5, 10, 25, 50, "All"],
      ],
    });
    $(".table-displayDriverConductorVehicle tbody").on("click", "tr .editBtn", function () {
      var p = this.closest("tr");
      var data = $(".table-displayDriverConductorVehicle").DataTable().row(p).data();
      self.button = "Update Allocation";
      self.editAllocation(data);
    });
    $(".table-displayDriverConductorVehicle tbody").on("click", "tr .deleteBtn", function () {
      var p = this.closest("tr");
      var data = $(".table-displayDriverConductorVehicle").DataTable().row(p).data();
      self.globals.confirmDialogService.confirmThis(
        'Delete Allocation',
        "Do You Want To Remove Allocation ?",
        function () {
          self.deleteAllocation(data);
        },
        function () {}
      );
    });
  }
}
