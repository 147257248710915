import { Component, OnInit } from '@angular/core';
import {TransportMailModel} from "../Models/TransportMail"
import  {transportService} from "../transport.service"
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-mail-config',
  templateUrl: './mail-config.component.html',
  styleUrls: ['./mail-config.component.scss']
})
export class MailConfigComponent implements OnInit {

  transportMail = new TransportMailModel()
  fromSessionStoppage: string;
  toSessionStoppage: string;
  fromSessionRoute: string;
  toSessionRoute: string;

  constructor(
    // public mailModel : TransportMailModel,
    public service : transportService,
    public notify: NotifierService
  ) { }

  ngOnInit() {
    this.transportMail = new TransportMailModel()
    this.getMailSesstings()
  }

  getMailSesstings(){
    this.service.gettransportMailSetting().subscribe((res) => {
      console.log("res.data" , res.data)
      if(res.data && res.data !== null){
        this.transportMail = res.data;
      }else{
        this.transportMail.usingSms = false;
      }
    });
  }

  updateMailSettings(){
    this.service.updatetransportMailSetting(this.transportMail).subscribe((res) => {
      if(res.status){
        this.notify.notify('success', res.message);
      }
       this.getMailSesstings();
    });
  }


  copyStoppageSession() {

    if (!this.fromSessionStoppage) {
      return this.notify.notify('error', "Please Select a Copy From");

    } else  if (!this.toSessionStoppage) {
      return this.notify.notify('error', "Please Select a Copy to session");

    } else if (this.fromSessionStoppage == this.toSessionStoppage) {
      return this.notify.notify('error', "Both Session Can't be Same");
    }

    this.service.copyStoppage(this.fromSessionStoppage, this.toSessionStoppage).subscribe((res) => {
      if(res.status){
        this.notify.notify('success', res.message);
      }
    });
  }

  copyRouteSession() {

    if (!this.fromSessionRoute) {
      return this.notify.notify('error', "Please Select a Copy From");

    } else  if (!this.toSessionRoute) {
      return this.notify.notify('error', "Please Select a Copy to session");

    } else if (this.fromSessionRoute == this.toSessionRoute) {
      return this.notify.notify('error', "Both Session Can't be Same");
    }
    
    this.service.copyRoute(this.fromSessionRoute, this.toSessionRoute).subscribe((res) => {
      if(res.status){
        this.notify.notify('success', res.message);
      }
    });
  }
  
}
