import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  NgZone,
} from "@angular/core";

import { MapsAPILoader } from "@agm/core";

// import { Polyline } from '@agm/core/services/google-maps-types';

import { transportService } from '../transport.service';
import { NotifierService } from "angular-notifier";
import { from } from "rxjs";
import { filter, toArray } from "rxjs/operators";
declare var $: any;


@Component({
  selector: 'app-track-school-bus',
  templateUrl: './track-school-bus.component.html',
  styleUrls: ['./track-school-bus.component.scss']
})
export class TrackSchoolBusComponent implements OnInit {
  selectedOption: string = '';
  latitude = 28.539339;
  longitude = 77.295372;
  initialZoom = 5;
  vehicleDetails:any;
  vehicleStatus: Boolean = true
  address: string;
  trackingData:any;
  stopId: string;
  speed: any;
  fullAddress: string;
  movigData:any;
  stopData:any;
  showLocation;
  lat:any;
  long:any;

  stop = new StoppageModel();
  stopList: [];
  coloumns = [];
  private geoCoder;
  vehicleId :any;
  // path: google.maps.LatLngLiteral[] = [];

  @ViewChild("search", { static: true }) mapElement: ElementRef;


  constructor(
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private serv: transportService,
    private notifier: NotifierService
  ) {}

  ngOnInit() {
    this.createTable(); 
    // this.initializePath();

    setInterval(() => {
      console.log("vehcile id.........", this.vehicleId)

      console.log("before check......")
      if (this.vehicleId !== null && this.vehicleId !== undefined && this.vehicleStatus == true) { 
          this.serv.trackSchookBusSingle(this.vehicleId).subscribe((res) => {
              console.log("data........", res.msg)
              this.latitude = res.msg.lat;
              this.longitude = res.msg.long;
              console.log("this lat:", this.latitude) 
              console.log("this long:", this.longitude)
          });
      }
  }, 3000);


// setInterval(() => {
//   if (this.vehicleId !== null && this.vehicleId !== undefined) {
//     this.serv.trackSchookBusSingle(this.vehicleId).subscribe((res) => {
//       this.latitude = res.msg.lat;
//       this.longitude = res.msg.long;

//       this.path.push({ lat: this.latitude, lng: this.longitude });

//       this.latitude = this.path[this.path.length - 1].lat;
//       this.longitude = this.path[this.path.length - 1].lng;

//       console.log("this lat:", this.latitude);
//       console.log("this long:", this.longitude);
//     });
//   }
// }, 7000);


  

    let action_btn_edit =
      '<span class="editBtn"><i style="padding-left:10px;position:pointer;"><img src="../../../assets/dash/pencil.svg"> </i></span>'
    var i = 1;
    this.coloumns = [
      { data: "driverName", defaultContent: "<i>Not Set</i>", title: "Driver" },
      {
        data: "gpsStatus",
        defaultContent: "<i>Not Set</i>",
        title: "Status",
      },
      {
        data: "registrationNumber.",
        defaultContent: "<i>Not Set</i>",
        title: "Vehicle",
      },
      {
        data: "busNo",
        defaultContent: "<i>Not Set</i>",
        title: "Bus No",
      },
      {
        data: "noOfStudents",
        defaultContent: "<i>Not Set</i>",
        title: "Capacity",
      },
      {
        data: "speed",
        defaultContent: "<i>Not Set</i>",
        title: "Speed",
      },
    ];
  }

  tabTofetchSpeed(data) {
    console.log("data", data);
    this.latitude = data.latitude
    this.longitude = data.longitude
    this.speed = data.speed;

    this.vehicleId = data.registrationNumber;
    console.log("tabTofetchSpeed........ ",this.vehicleId )

  }
  // private initializePath() {
  //   this.path = {
  //     editable: false, // Set to true if you want the path to be editable by the user
  //     geodesic: true, // Indicates that the path should be interpreted as a geodesic line
  //     path: [], // Initialize as an empty array
  //     strokeColor: '#FF0000', // Color of the path (red in this example)
  //     strokeOpacity: 1.0, // Opacity of the path (1.0 means fully opaque)
  //     strokeWeight: 2, // Width of the path
  //   }
  // }


  
  populateTable(active: boolean) {
    let self = this;
    if ($.fn.DataTable.isDataTable(".example")) {
      $(".example").DataTable().clear().destroy();
    }

    $(".showhideTable").css("display", "block");
    const example = $(".example").DataTable({
      data: this.trackingData,
      columns: this.coloumns,

      bFilter: true,
      bInfo: true,
      bLengthChange: true,
      ordering: true,

      language: {
        searchPlaceholder: "Search Here",
        oPaginate: {
          sNext: '<i class="fa fa-forward"></i>',
          sPrevious: '<i class="fa fa-backward"></i>',
          sFirst: '<i class="fa fa-step-backward"></i>',
          sLast: '<i class="fa fa-step-forward"></i>',
        },
      },
      lengthMenu: [
        [5, 10, 25, 50, -1],
        [5, 10, 25, 50, "All"],
      ],
    });
    $(".example tbody").on("click", "tr", function () {
      var p = this.closest("tr");
      var data = $(".example").DataTable().row(p).data();
      self.tabTofetchSpeed(data);
    });
  }

  createTable() {
    this.serv.trackSchookBus({ allVehicleFlag: false, inUseVehicleFlag: this.vehicleStatus }).subscribe((res) => {

      console.log("data........", res.msg)
      this.trackingData = res.msg
      this.populateTable(true);
      console.log("data", this.trackingData);
    }
    
    );
  }

  ngAfterViewInit() {
    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder();

      let autocomplete = new google.maps.places.Autocomplete(
        this.mapElement.nativeElement
      );
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.initialZoom = 12;
          this.stop.location.lat = this.latitude;
          this.stop.location.lng = this.longitude;

          console.log("location", this.latitude, this.longitude);
        });
      });
    });
  }

  private setCurrentLocation() {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.initialZoom = 8;
        this.getAddress(this.latitude, this.longitude);
      });
    }
  }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode(
      { location: { lat: latitude, lng: longitude } },
      (results, status) => {
        if (status === "OK") {
          if (results[0]) {
            this.initialZoom = 12;
            this.address = results[0].formatted_address;
          } else {
            window.alert("No results found");
          }
        } else {
          window.alert("Geocoder failed due to: " + status);
        }
      }
    );
  }

  onMapReady(map?: google.maps.Map) {
    console.log("dogs");
    if (map) {
      console.log("dogs");
      map.setOptions({
        streetViewControl: true,
        fullscreenControl: true,
      });
    }
  }

  markerDragEnd($event: any) {
    console.log($event);

    this.showLocation = true;
    this.fullAddress = "";
    this.latitude = $event.coords.lat;
    this.longitude = $event.coords.lng;
    this.stop.location.lat = this.latitude;
    this.stop.location.lng = this.longitude;
    this.getAddress(this.latitude, this.longitude);
  }
}

export class StoppageModel {
  name: string;
  location = new LatLngLiteral();
  address = new Address();
  amount: string;
}

export class Address {
  street: string;
  area: string;
  city: string;
  pinCode: string;
}

export class LatLngLiteral {
  lat: number;
  lng: number;
}
