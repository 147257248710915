import { SchoolListForAgentComponent } from "src/app/admission/school-list-for-agent/school-list-for-agent.component";

export class BasicSchoolDetail {
    id: string;
    schoolName: string;
    sourceId: string;
    onlineFee: boolean;
    ccAvenue: boolean = false;
    rzpHdfc: boolean = false;
    cashfree: boolean = false;
    skipClassesOnlinepayement =[];
    showFeehistory:boolean=false;
    smEmployeeId: string;
    constructor(schoolDetail: any = undefined) {
        if (schoolDetail) {
            this.id = schoolDetail._id;
            this.schoolName = schoolDetail.name;
            this.onlineFee = schoolDetail.onlineFee;
            this.ccAvenue = schoolDetail.ccAvenue;
            this.rzpHdfc = schoolDetail.rzpHdfc;
            this.cashfree = schoolDetail.cashfree;
            this.smEmployeeId = schoolDetail.smEmployeeId;
            if(typeof schoolDetail.skipClassesForOnline !=='undefined')
            {
this.skipClassesOnlinepayement=schoolDetail.skipClassesForOnline;
            }
            if(typeof schoolDetail.showFeeHistoryAndFeeSummery !=='undefined')
            {
                this.showFeehistory=schoolDetail.showFeeHistoryAndFeeSummery;
            }
        }
    }
}