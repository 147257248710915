import { Component, OnInit } from '@angular/core';

declare var $: any;
import { StudentService } from "../../StudentO/Services/student.service";
import { NotifierService } from "angular-notifier";
import { routeSchedulingService } from "src/app/CreateRoute/create-route.service";
import { OneStorage } from "src/app/GlobalDataStorage/oneStorage.service";
import { transportService } from '../transport.service';


@Component({
  selector: 'app-over-speed-details',
  templateUrl: './over-speed-details.component.html',
  styleUrls: ['./over-speed-details.component.scss']
})
export class OverSpeedDetailsComponent implements OnInit {
  tableData = [];
  columns = [];
  voucherAvailable: boolean = false;
  pdfDataObj: any;
  startDate: any;
  endDate : any;
  speedLimit:number = 5;
  allVehicle: any;
  vehicleId:any;
  showLoader:boolean = false;
  loadContent = "";
  

  constructor(
    private _studentService: StudentService,
    private globals: OneStorage,
    private _notify: NotifierService,
    private serv: transportService,
  ) { }

  ngOnInit() {
    this.vehicleId = "0"
    // this.createTable();
    this.serv.fetchVehicleAllData().subscribe(
      (res) => {
          this.allVehicle = res.msg;
          console.log("here is all vehicle........", this.allVehicle)
        }
    ); 
  }

  createTable() {
    if(this.vehicleId == "0"){
      return this._notify.notify("error", "Please select a vehicle");
    }
    this.showLoader = true;

    //need to change the code
    this.serv.getOverSpeedin2(this.startDate, this.endDate, this.speedLimit, this.vehicleId ).subscribe(
      (res) => {
        if ((res.msg).length > 0) {          
          this.tableData = res.msg[0].gpsData;
          this.populateTable();
          
        } else {
          this.tableData = null;
          this.populateTable();
        }
      },
      (error) => {
        console.log("Error", error);
      }
    );
  }

  populateTable() {
    this.showLoader = false;
    let that = this;

    this.columns = [
      { data: "number", title: "Vehicle", defaultContent: "<i>Not set</i>" },
      {
        data: "last_api_hit_sm",
        title: "Date",
        defaultContent: "<i>Not set</i>",
      },
      {
        data: "last_location",
        title: "Location",
        defaultContent: "<i>Not set</i>",
      },
      {
        data: "speed",
        title: "Speed",
        defaultContent: "<i>Not set</i>",
      },

      // {
      //   data: "odometerReading",
      //   title: "Odometer Reading",
      //   defaultContent: "<i>Not set</i>",
      // },

    ];
    if ($.fn.DataTable.isDataTable(".example")) {
      $(".example").DataTable().clear().destroy();
    }
    $(".showhideTable").css("display", "block");
    const example = $(".example").DataTable({
      data: this.tableData,
      columns: this.columns,
      columnDefs: [
        {
          visible: false,
          searchable: false,
        },
      ],

      dom: "Bfrtip",
      buttons: [
        "pageLength",
        "excel",
        {
          text: "Pdf",
          action: function () {
            generatePdf();
          },
        },
      ],
      lengthMenu: [
        [5, 10, 25, 50, -1],
        [5, 10, 25, 50, "All"],
      ],
      language: {
        searchPlaceholder: "Search Here",
      },
    });
    async function generatePdf() {
      that.voucherAvailable = true;
      try {
        if (that.columns.length > 6) {
          that.columns.pop()
        }
        that.tableData.forEach((ele) => {
          that.columns.forEach((zz) => {
            if (!ele.hasOwnProperty(zz.data)) {
              ele[zz.data] = "Not set";
            }
          });
        });
        const inputDateString = new Date();
        const inputDate = new Date(inputDateString);

        const day = inputDate.getDate().toString().padStart(2, '0');
        const month = (inputDate.getMonth() + 1).toString().padStart(2, '0');
        const year = inputDate.getFullYear();

        const hours = inputDate.getHours().toString().padStart(2, '0');
        const minutes = inputDate.getMinutes().toString().padStart(2, '0');

        const formattedDate = `${day}/${month}/${year} ${hours}:${minutes}`;
        var title = "Report generated on  :" + "-  " + formattedDate;
        var reportName = "Over Speeding Details";

        await that.globals
          .getPdfDataObj(that.columns, that.tableData, title, reportName)
          .then((res) => {
            that.pdfDataObj = res;
          });
      } catch (e) {
        console.log(e);
      }
    }
  }

}
