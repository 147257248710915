import { Component, OnInit } from "@angular/core";
import { DriverModel, salaryDetail } from "../Models/Driver";
import { NotifierService } from "angular-notifier";
import { transportService } from "../transport.service";
import { Router } from "@angular/router";
import { OneStorage } from "../../GlobalDataStorage/oneStorage.service";
import { SharedService } from "src/app/dashboardO/dashboard/shared.service";

@Component({
  selector: "app-add-driver",
  templateUrl: "./add-driver.component.html",
  styleUrls: ["./add-driver.component.scss"],
})
export class AddDriverComponent implements OnInit {
  disableScroll(event: Event): void {
    event.preventDefault();
  }
  driver = new DriverModel();
  fileToUpload : any;
  govtIdFlag = false;
  profilePicFlag = false;
  showErrorMessage: string = "";
  date: any;
  profilePicture: any;
  govtId : any;
  button: string;
  tableRowArr = [new salaryDetail()];
  salaryButton = "Add Salary";
  clickEventsubscription: any;


  constructor(
    public notifier: NotifierService,
    private serv: transportService,
    private router: Router,
    private globals: OneStorage,
    public sharedService : SharedService,

  ) {
    this.clickEventsubscription = this.sharedService
    .getClickEvent()
    .subscribe(() => {
      this.globals.activeSession = this.sharedService.getSession();
    });
  }

  ngOnInit() {

    this.driver.currentStatus = "Active"

    this.button = "Add Driver";
    if (localStorage.getItem("driverDetail")) {
      try {
        this.driver = JSON.parse(localStorage.getItem("driverDetail"));
        if(this.driver.salaryDetail.length > 0) {
          this.salaryButton = "Update/View"
          this.tableRowArr =   this.driver.salaryDetail;

        } else {
          this.tableRowArr = [new salaryDetail()];
        }
        
        if(this.driver.profilePicture){
          this.profilePicFlag = true;
        }

        if(this.driver.govtId){
          this.govtIdFlag = true;
        }
        
        this.button = "Update Driver";

        localStorage.removeItem("driverDetail");
      } catch (e) {
        alert(e); // error in the above string (in this case, yes)!
      }
    }
  }

  onFileChange(event) {
    this.fileToUpload = event.target.files[0];
    console.log("filename", this.fileToUpload);
    if (
      this.fileToUpload &&
      (this.fileToUpload.name.toLowerCase().includes("jpg") ||
        this.fileToUpload.name.includes("jpeg") ||
        this.fileToUpload.name.includes("jfif") ||
        this.fileToUpload.name.includes("pjpeg") ||
        this.fileToUpload.name.includes("pjp") ||
        this.fileToUpload.name.includes("png") ||
        this.fileToUpload.name.includes("svg"))
    ) {
      this.serv.saveId(this.fileToUpload);
      this.onAddDocument(event.target.name)
    } else {
      this.fileToUpload = null;
      this.notifier.notify("error", "Please select only image file");
    }
  }

  // parseDate(dateString: string): Date {
  //   if(dateString){
  //     this.date = new Date(dateString).toISOString().split("T")[0];
  //     console.log("what date" , this.date)
  //     return this.date;
  //   }
  // }

  validateForm() {
    if (
      Object.keys(this.driver).length === 0 ||
      !this.driver.name ||
      this.driver.name.trim() == ""
    ) {
      this.notifier.notify("error", "Please Enter a Name.");
      return false;
    }
    // if (Object.keys(this.driver).length === 0 || !this.driver.dateOfBirth) {
    //   this.notifier.notify("error", "Please Enter Date Of Birth.");
    //   return false;
    // }
    // if (
    //   Object.keys(this.driver).length === 0 ||
    //   !this.driver.localAddress.line1 ||
    //   this.driver.localAddress.line1.trim() == ""
    // ) {
    //   this.notifier.notify("error", "Please Enter local address.");
    //   return false;
    // }
    // if (
    //   Object.keys(this.driver).length === 0 ||
    //   !this.driver.localAddress.state ||
    //   this.driver.localAddress.state.trim() == ""
    // ) {
    //   this.notifier.notify("error", "Please Enter local State.");
    //   return false;
    // }
    // if (
    //   Object.keys(this.driver).length === 0 ||
    //   !this.driver.localAddress.pincode ||
    //   this.driver.localAddress.pincode == null
    // ) {
    //   this.notifier.notify("error", "Please Enter valid pincode.");
    //   return false;
    // }

    // if (this.driver.localAddress.line2) {
    //   this.driver.localAddress.line2 = this.driver.localAddress.line2.trim();
    // }

    var userDOB = new Date(this.driver.dateOfBirth); 
    const isValidAge = this.isUserAbove18(userDOB);
    if (!isValidAge) {
      return this.notifier.notify("error", "Less than 18 Years Old DOB is not allowed");
    } 

    if (
      Object.keys(this.driver).length === 0 ||
      !this.driver.contactNo ||
      this.driver.contactNo == null
    ) {
      this.notifier.notify("error", "Please Enter contact number.");
      return false;
    }

    // if (
    //   Object.keys(this.driver).length === 0 ||
    //   !this.driver.permanentAddress.line1 ||
    //   this.driver.permanentAddress.line1.trim() == ""
    // ) {
    //   this.notifier.notify("error", "Please Enter Permanent address.");
    //   return false;
    // }
    // if (
    //   Object.keys(this.driver).length === 0 ||
    //   !this.driver.permanentAddress.state ||
    //   this.driver.permanentAddress.state.trim() == ""
    // ) {
    //   this.notifier.notify("error", "Please Enter Permanent State.");
    //   return false;
    // }

    // if (this.driver.permanentAddress.line2) {
    //   this.driver.permanentAddress.line2 =
    //     this.driver.permanentAddress.line2.trim();
    // }

    // if (this.driver.otherContactNo) {
    //   this.driver.otherContactNo = this.driver.otherContactNo.trim();
    // }

    if (
      Object.keys(this.driver).length === 0 ||
      !this.driver.licenceNo ||
      this.driver.licenceNo.trim() == ""
    ) {
      this.notifier.notify("error", "Please Enter licence number.");
      return false;
    }
    if (
      Object.keys(this.driver).length === 0 ||
      !this.driver.licenceExpiryDate
    ) {
      this.notifier.notify("error", "Please Enter Date Of Licence Expiry.");
      return false;
    }

    // if (
    //   Object.keys(this.driver).length === 0 ||
    //   !this.driver.username ||
    //   this.driver.username.trim() == ""
    // ) {
    //   this.notifier.notify("error", "Please Set Driver Username.");
    //   return false;
    // }
    // if (
    //   Object.keys(this.driver).length === 0 ||
    //   !this.driver.password ||
    //   this.driver.password.trim() == ""
    // ) {
    //   this.notifier.notify("error", "Please Set Driver password.");
    //   return false;
    // }

    // if (!this.driver.currentStatus) {
    //   this.notifier.notify("error", "Please Select conductor Status.");
    //   return false;
    // }

    // if (!this.driver.profilePicture) {
    //   this.notifier.notify("error", "Please Select Valid Driver Picture.");
    //   return false;
    // }

    // if (!this.driver.govtId) {
    //   this.notifier.notify(
    //     "error",
    //     "Please Select Valid Driver Identity Document."
    //   );
    //   return false;
    // }

    const currentDate = new Date();
    const selectedVerificatiDate = new Date(this.driver.verificationDate);

    if (selectedVerificatiDate > currentDate) {
      return this.notifier.notify("error", "Future Date For Police Verification Date is not allowed");
    }

    const selectedLicenseDate = new Date(this.driver.licenceExpiryDate);
    if (selectedLicenseDate < currentDate) {
      return this.notifier.notify("error", "Past Date For License Expiry Date is not allowed");
    }


    for (let i = 0; i < this.tableRowArr.length; i++) {
      let trow = this.tableRowArr[i];
      if(trow.month)
      {
        if (!trow.month) {
          return this.notifier.notify("error", `Please Select Month`);
        }
        if (trow.salary === undefined || trow.salary === null) {
          return this.notifier.notify("error", `Please Enter Salary For Blank Field`);
        }
        if (!trow.session) {
          trow.session = this.globals.globalSession;
        }
      }

    }
    return true;
  }

  sameAddress(event){
    console.log("localAdd",event)
    let localAdd = this.driver.localAddress
    if(event.target.checked){
    if(!localAdd.line1 && !localAdd.line2 && !localAdd.state && !localAdd.pincode){
      this.notifier.notify("error", "Please Enter Local Address");
    }
    if(localAdd.line1){
      this.driver.permanentAddress.line1 = localAdd.line1
    }
    if(localAdd.line2){
      this.driver.permanentAddress.line2 = localAdd.line2
    }
    if(localAdd.state){
      this.driver.permanentAddress.state = localAdd.state
    }
    if(localAdd.pincode){
      this.driver.permanentAddress.pincode = localAdd.pincode
    }
    }else{
        this.driver.permanentAddress.line1 = ""
      
        this.driver.permanentAddress.line2 = ""
      
        this.driver.permanentAddress.state = ""
      
        this.driver.permanentAddress.pincode = ""
      
    }
  }
  removeFilePic() {
    this.profilePicFlag = false;
    this.profilePicture = null;
    this.driver["profilePicture"] = null
  }


  removeFileId() {
    this.govtIdFlag = false;
    this.govtId = null;
    this.driver["govtId"] = null
  }

  onAddDocument(fileName) {
    this.serv.saveImage(this.fileToUpload).subscribe((res) => {
      this.driver[`${fileName}`] = res.data.url;
      if(fileName == "profilePicture"){
        this.profilePicFlag = true
      }if(fileName == "govtId"){
        this.govtIdFlag = true
      }
      console.log("res", this.driver)

    });
  }

  resetPageDetails() {
    this.driver = new DriverModel();
    this.tableRowArr = [new salaryDetail()];
    this.profilePicFlag = false;
    this.govtIdFlag = false;
    this.govtId = null;
    this.profilePicture = null;
    this.driver.currentStatus = "Active"

  }

  addDriver() {
    if (!this.validateForm()) {
      return;
    }

    if(this.button == "Update Driver"){
      this.driver.session = this.globals.activeSession
      this.driver.salaryDetail = this.tableRowArr;
      this.serv.updateDriver(this.driver).subscribe(
        (res) => {
          this.notifier.notify("success", "Driver Updated successfully");
          console.log("response", res);
          this.router.navigate(["/dashboard/listDriverConductor"]);
          this.resetPageDetails();
        },
        (err) => {
          this.notifier.notify("error", "Please Try Again");
        }
      );
      localStorage.setItem("refreshTable", JSON.stringify(true));
      
    }else{
      this.driver.session = this.globals.activeSession
      console.log("driver details", this.driver);
      this.driver.salaryDetail = this.tableRowArr;
      this.serv.addDriver(this.driver).subscribe(
        (res) => {
          console.log(res);
          if(res.status){
            this.notifier.notify("success", res.msg);
            this.resetPageDetails()
          }else{
            this.notifier.notify("error",res.msg);
          }
        },
        (err) => {
          console.log(err);
          this.notifier.notify("error", "Error:" + err);
        }
      );
    }

  }

  parseDate(dateString: string): Date {
    let dateVar: any = null;
    if (dateString) {
      dateVar = new Date(dateString);
    }
    console.log(dateVar);
    return dateVar;
  }


  deleteRow(index) {
    if (index != 0) {
      let newArr = this.tableRowArr;
      newArr.splice(index, 1);
      this.tableRowArr = newArr;
    }
  }

  addRow() {
    console.log("this.tableRowArr.....", this.tableRowArr)
    
    let lastObj = this.tableRowArr[this.tableRowArr.length - 1]
    if (lastObj.month && lastObj.salary) {
      this.tableRowArr.push(new salaryDetail());

    } else {
      this.notifier.notify("error", "Empty fields not allowed")
    }

  }

  // Age Validation
  isUserAbove18(userDOB) {
    const currentDate = new Date();
    const ageInMilliseconds = currentDate.getTime() - userDOB.getTime();
    const ageInYears = ageInMilliseconds / (1000 * 60 * 60 * 24 * 365.25);
    return ageInYears >= 18;
  }
  
  getFunc() {
    // this.tableRowArr = stdDetails.concessionDetails
    if(this.tableRowArr.length === 0) {
      this.tableRowArr = [new salaryDetail()];
    }
  }

  onAddDocument2() {
    let concessionData = this.tableRowArr;
    for (let i = 0; i < this.tableRowArr.length; i++) {
      let trow = this.tableRowArr[i];

      if(trow.month)
      {
        if (!trow.month) {
          return this.notifier.notify("error", `Please Select a Month`);
        }
        if (trow.salary === undefined || trow.salary === null) {
          return this.notifier.notify("error", `Please Enter Salary For Blank Field`);
        }
      }
      
      const duplicateIndex = this.tableRowArr.findIndex((row, index) => index !== i && row.month === trow.month);
      if (duplicateIndex !== -1) {
        return this.notifier.notify("error", `'${trow.month}' is already selected.`);
      }

    }

    this.tableRowArr = [];
    for (const doc of concessionData) {
      const { ...newDocument } = doc;
      newDocument.session = this.globals.activeSession;
      this.tableRowArr.push(newDocument)
    }
  }

}
