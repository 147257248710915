import { Component, OnInit, ViewChild, ElementRef  } from "@angular/core";
import { transportService } from "../../NewTransportO/transport.service";
import { Router } from "@angular/router";
import { Chart } from 'chart.js';
import { NotifierService } from 'angular-notifier';
import { OneStorage } from 'src/app/GlobalDataStorage/oneStorage.service';
import { SharedService } from "src/app/dashboardO/dashboard/shared.service";

declare var $: any;

@Component({
  selector: "app-dashboard-transport",
  templateUrl: "./dashboard-transport.component.html",
  styleUrls: ["./dashboard-transport.component.scss"],
})
export class DashboardTransportComponent implements OnInit {
  coloumns = [];
  routeDetails: any;
  drivers: any;
  conductors: any;
  users: any;
  vehicles: any;
  allVehicles:any;
  totalVehicles =0;
  runningVehicle = 0;
  stoppedVehicle = 0;
  privateCount = 0;
  commercialCount = 0;
  tableData = [];
  columns = [];
  voucherAvailable: boolean = false;
  pdfDataObj: any;
  session:any = "2024-25";
  month: string = '';
  dateNow = new Date().toISOString().substr(0, 10);
  showLoader:boolean = false;
  tableData1 :any;
  tableData2 :any;
  studentList : any;
  studentList1 : any;
  totalAmount: any;
  totalAmount1: any;
  totalAmount2: any;
  vehicleId: string = "All Vehicle";
  vehicleAll : string = "All Vehicle";
  date = new Date().toISOString().substr(0, 10);

  view1 :Boolean = true;
  view2 :Boolean = false;

  month1:any;
  month2:any;
  vehicle1:any;
  vehicle2:any;
  selectAll:any;
  vehicleExpensesData: any;
  donutChart: any;
  canvas: any;
  ctx: any;
  shift: string = "All";
  AllShiftList : any;
  shiftWiseCommercialAndPrivate : any;
  @ViewChild("expenseOccuredChart", { static: false }) myChart: ElementRef;
  @ViewChild("expenseOccuredChartComparison", { static: false }) myChart1: ElementRef;
  expenseOccuredChart: Chart;
  expenseOccuredChartComparison: Chart;
  loaderGraph : Boolean = false;
  clickEventsubscription: any;

  hexColors: string[] = [
    "#F88919",
    "#31B173",
    "#E8D1BA",
    "#DC5388",
    "#004B85",
    "##814E25",
    "#8E1931",
    "#49BA21",
    "#9D27EF",
    "#F2A888",
    "#27B2EF",
    "#E777E5"
  ];

  filteredDataVehicle: any;
  filteredDataVehicle1: any;
  filteredDataVehicle2:any;
  public pieChartType: string = "doughnut";
  public chartType: string = "line";

  showFinePieChart: boolean = false;
  showDefaulterChart: boolean = false;
  vehicleExpensesData1: number;
  vehicleExpensesData2: number;
  vehicleExpensesData3: number;
  vehicleExpensesData4: number;
  registrationNumber: any;
  registrationNumber1: any;
  registrationNumber2: any;

  chartDataTogo: any

  constructor(private serv: transportService, private router: Router, private notifier: NotifierService, 
    private globals: OneStorage,
    public sharedService : SharedService,
    ) {      
      this.clickEventsubscription = this.sharedService
      .getClickEvent()
      .subscribe(() => {
        this.globals.activeSession = this.sharedService.getSession();
        this.session = this.globals.activeSession 
        this.getRouteAndVehicle();
        this.fetchData();
        this.onchageShiftCount();
        this.shiftwiseStudentCount();
        this.commercialVehicleExpense();
        this.commercialVehicleExpense1()
      });
    }

  ngOnInit() {
    this.showLoader = true;
    // this.commercialVehicleExpense ();

    this.session = this.globals.activeSession ;
    if(typeof  this.session === 'undefined' )
    this.session ="2024-25";
    this.fetchData();

    var i = 1;
    this.coloumns = [
      {
        defaultContent: "<i>Not Set</i>",
        title: "S. No.",
        // render: function (data, type, full, meta) {
        //   return i++;
        // },
      },
      {
        data: "vehicle.registrationNumber",
        defaultContent: "<i>Not Set</i>",
        title: "Vehicle No.",
      },
      { data: "name", defaultContent: "<i>Not Set</i>", title: "Route Name" },
      {
        data: "routeShift",
        defaultContent: "<i>Not Set</i>",
        title: "Route Shift",
      },
      {
        data: "vehicle.driverName",
        defaultContent: "<i>Not Set</i>",
        title: "Driver Name",
      },
      {
        data: "vehicle.conductorName",
        defaultContent: "<i>Not Set</i>",
        title: "Conductor Name",
      },
      {
        data: "vehicle.conductorNumber",
        defaultContent: "<i>Not Set</i>",
        title: "Conductor Contact Number",
      },
    ];

    this.serv.getVehicles({allVehicleFlag : false , inUseVehicleFlag : true}).subscribe((res) => {
      this.vehicles = res.data;
      this.allVehicles = res.data.filter(vehicle => vehicle.vehicleCategory === 'Commercial');
      
      this.totalVehicles = this.vehicles.length
      this.registrationNumber = this.allVehicles[0].registrationNumber
      this.registrationNumber1 = this.allVehicles[0].registrationNumber
      this.registrationNumber2 = this.allVehicles[1].registrationNumber


      // let privateCount = 0;
      // let commercialCount = 0;
    
      this.vehicles.forEach(vehicle => {
        if (vehicle.vehicleCategory === 'Private') {
          this.privateCount++;

        } else if (vehicle.vehicleCategory === 'Commercial') {
          this.commercialCount++;
        }
      });
    
      console.log('Private vehicles count:', this.privateCount);
      console.log('Commercial vehicles count:', this.commercialCount);

      // this.serv.getGpsData().subscribe((res) => {
      //   for(let i=0; i< this.vehicles.length; i++){
      //     for(let j=0; j<res.data.length; j++){
      //       if(res.data[j]["data"].device_id == this.vehicles[i].gpsDeviceId){
      //         if(res.data[j]["data"].motion_status == "stopped"){
      //           this.stoppedVehicle += 1
      //         }else{
      //           this.runningVehicle += 1

      //         }
      //       }
      //     }
      //   }
      // })
    });

    this.serv.getDriver().subscribe((res) => {
      this.drivers = res.data.length;
    });

    this.serv.getConductor().subscribe((res) => {
      this.conductors = res.data.length;
    });

    // this.serv.fetchStudentsMappedByVehicles(this.session, this.vehicleAll).subscribe((res) => {
    //   // this.showLoader = true;
    //   this.users = res.userNumber;
    // });

    // this.fetchData();

    this.getRouteAndVehicle();

    this.onchageShiftCount();
    this.shiftAll();
    this.shiftwiseStudentCount();
    this.studentAttendanceCount();

    // this.showLoader = false;
  }

  ngAfterViewInit() {
    this.commercialVehicleExpense ();
    this.commercialVehicleExpense1();
  }

  shiftAll () {
    // this.showLoader = true;
    this.serv.fetchAllShift().subscribe(
      (res) => {
        this.AllShiftList = res.msg;
      },
      (err) => {
        this.notifier.notify("error", err.error);
      }
    );
  }

  onchageShiftCount () {
    this.serv.getShiftWiseCount(this.shift).subscribe((res) => {
      this.shiftWiseCommercialAndPrivate = res.msg
    });
  }

  listVehicle() {
    this.router.navigate(["/dashboard/listVehicle"]);
  }

  studentAllocation() {
    this.router.navigate(["/dashboard/studentAllocationReport"]);
  }


  attendanceReport() {
    this.router.navigate(["/dashboard/studentAttendance"]);
  }

  changeAllocation() {
    this.router.navigate(["/dashboard/mapVehicleRoute"]);
  }

  populateTable(active: boolean) {
    let self = this;

    if ($.fn.DataTable.isDataTable(".exampleDashboard")) {
      $(".exampleDashboard").DataTable().clear().destroy();
    }

    $(".showhideTable").css("display", "block");
    const example = $(".exampleDashboard").DataTable({
      data: this.routeDetails,
      columns: this.coloumns,

      bFilter: true,

      bInfo: true,
      bLengthChange: true,
      ordering: true,

      fnRowCallback: function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
        //debugger;
        var index = iDisplayIndexFull + 1;
        $("td:first", nRow).html(index);
        return nRow;
      },

      language: {
        searchPlaceholder: "Search Here",
        oPaginate: {
          sNext: '<i class="fa fa-forward"></i>',
          sPrevious: '<i class="fa fa-backward"></i>',
          sFirst: '<i class="fa fa-step-backward"></i>',
          sLast: '<i class="fa fa-step-forward"></i>',
        },
      },
      lengthMenu: [
        [5, 10, 25, 50, -1],
        [5, 10, 25, 50, "All"],
      ],
    });
//    this.showLoader = false;
  }

  async fetchData() {
    try {
      this.showLoader = true;
      const res = await this.serv.fetchStudentsMappedByVehicles(this.session, this.vehicleAll).toPromise();
      this.users = res.userNumber;
      this.showLoader = false;

    } catch (error) {
      console.error('Error fetching user:', error);
      this.showLoader = false;
    }
  }


  async commercialVehicleExpense() {
    this.destroyChart();
    if (!this.month && this.month == '') {
        this.currenntMonth();
    }
    // this.session = "2023-24"
    // this.showLoader = true;

    await this.serv.fetchCommercialVehicleExpeseReportDashboard(this.session, this.month).subscribe(
        async (res) => {
            this.tableData = [];
            console.log(res.msg)
            var updatedArray1 = res.msg;
            updatedArray1.forEach(item => {
                let totalAmount = item.salary + (item.totalAmount || 0) + item.conductorSalary + item.bhabhi;
                let profitLoss = item.income - totalAmount;
                let actualProfitLoss = item.actualIncomeAsPaidAmount - totalAmount;

                this.tableData.push({
                    ...item,
                    totalAmount,
                    profitLoss,
                    actualProfitLoss
                });
            });

            this.filteredDataVehicle = this.tableData
            .filter(item => item.vehicle === this.registrationNumber)
            .map(({ FuelAmount, salary, conductorSalary, bhabhi, InsuranceAmount, TyreAmount, RepairAmount, FoodAmount, TollAmount, actualIncomeAsPaidAmount, income, OthersAmount }) => ({
              FuelAmount,
              salary,
              conductorSalary,
              bhabhi,
              InsuranceAmount,
              TyreAmount,
              RepairAmount,
              FoodAmount,
              TollAmount,
              actualIncomeAsPaidAmount,
              income,
              OthersAmount,
            }));

            this.totalAmount = this.tableData
            .filter(item => item.vehicle === this.registrationNumber)
            .map(({ totalAmount}) => ({
              totalAmount,
            }));

            this.canvas = this.myChart.nativeElement;
            this.ctx = this.canvas.getContext("2d");
            const data = {
              labels: ['Fuel', 'Driver Salary', 'Conductor Salary', 
              'Helper Salary', 'Insurance', 'Tyre', 'Repair', 'Food', 'Toll', 'Actual Income', 'Expected Income', 'Others' ],
        
              datasets: [
                {
                  data: Object.values(this.filteredDataVehicle[0]),
                  backgroundColor: this.hexColors
                },
              ],
            };
            this.expenseOccuredChart = new Chart(this.ctx, {
              type: this.pieChartType,
              data: data,
            });

            if(this.filteredDataVehicle.length > 0 ) {
              this.view1 = false;
            } 
        },
        (error) => {
            console.log("Error", error);
        }
    );
}
  chageData() {
    this.commercialVehicleExpense ();
  }

  destroyChart() {
    this.filteredDataVehicle = [];
    if (this.expenseOccuredChart) {
        this.expenseOccuredChart.destroy();
    }
}

  chageData1() {
    this.loaderGraph = true;
    this.destroyChartComparison();
    this.commercialVehicleExpense1 ();
  }

  async commercialVehicleExpense1() {
    this.destroyChartComparison();
    this.loaderGraph = true;
    if (!this.month1 && this.month1 == '') {
        this.currenntMonth();
    }

    if (!this.month2 && this.month2 == '') {
        this.currenntMonth();
    }



    // this.session = "2023-24"    


    const fetchApiData = async () => {
        const response1 = this.serv.fetchCommercialVehicleExpeseReportDashboard(this.session, this.month1).toPromise();
        const response2 = this.serv.fetchCommercialVehicleExpeseReportDashboard(this.session, this.month2).toPromise();
        return Promise.all([response1, response2]);
    };

    try {
        const [res1, res2] = await fetchApiData();
        this.tableData1 = [];
        console.log(res1.msg);
        var updatedArray3 = res1.msg;
        updatedArray3.forEach(item => {
            let totalAmount = item.salary + (item.totalAmount || 0) + item.conductorSalary + item.bhabhi;
            let profitLoss = item.income - totalAmount;
            let actualProfitLoss = item.actualIncomeAsPaidAmount - totalAmount;

            this.tableData1.push({
                ...item,
                totalAmount,
                profitLoss,
                actualProfitLoss
            });
        });

        this.filteredDataVehicle1 = this.tableData1
            .filter(item => item.vehicle === this.registrationNumber1)
            .map(({ FuelAmount, salary, conductorSalary, bhabhi, InsuranceAmount, TyreAmount, RepairAmount, FoodAmount, TollAmount, actualIncomeAsPaidAmount, income, OthersAmount }) => ({
                FuelAmount: FuelAmount || 0,
                salary: salary || 0,
                conductorSalary: conductorSalary || 0,
                bhabhi: bhabhi || 0,
                InsuranceAmount: InsuranceAmount || 0,
                TyreAmount: TyreAmount || 0,
                RepairAmount: RepairAmount || 0,
                FoodAmount: FoodAmount || 0,
                TollAmount: TollAmount || 0,
                actualIncomeAsPaidAmount: actualIncomeAsPaidAmount || 0,
                income: income || 0,
                OthersAmount: OthersAmount || 0,
            }));

        this.totalAmount1 = this.tableData1
            .filter(item => item.vehicle === this.registrationNumber1)
            .map(({ totalAmount }) => ({
                totalAmount: totalAmount || 0,
            }));

        this.tableData2 = [];
        console.log(res2.msg);
        var updatedArray2 = res2.msg;
        updatedArray2.forEach(item => {
            let totalAmount = item.salary + (item.totalAmount || 0) + item.conductorSalary + item.bhabhi;
            let profitLoss = item.income - totalAmount;
            let actualProfitLoss = item.actualIncomeAsPaidAmount - totalAmount;

            this.tableData2.push({
                ...item,
                totalAmount,
                profitLoss,
                actualProfitLoss
            });
        });

        this.filteredDataVehicle2 = this.tableData2
            .filter(item => item.vehicle === this.registrationNumber2)
            .map(({ FuelAmount, salary, conductorSalary, bhabhi, InsuranceAmount, TyreAmount, RepairAmount, FoodAmount, TollAmount, actualIncomeAsPaidAmount, income, OthersAmount }) => ({
                FuelAmount: FuelAmount || 0,
                salary: salary || 0,
                conductorSalary: conductorSalary || 0,
                bhabhi: bhabhi || 0,
                InsuranceAmount: InsuranceAmount || 0,
                TyreAmount: TyreAmount || 0,
                RepairAmount: RepairAmount || 0,
                FoodAmount: FoodAmount || 0,
                TollAmount: TollAmount || 0,
                actualIncomeAsPaidAmount: actualIncomeAsPaidAmount || 0,
                income: income || 0,
                OthersAmount: OthersAmount || 0,
            }));

        this.totalAmount2 = this.tableData2
            .filter(item => item.vehicle === this.registrationNumber2)
            .map(({ totalAmount }) => ({
                totalAmount: totalAmount || 0,
            }));

            if(this.filteredDataVehicle1.length < 1 ) {
              this.filteredDataVehicle1 = [];

            } else if (this.filteredDataVehicle2.length < 1) {
              this.filteredDataVehicle2 = [];
            }


            this.canvas = this.myChart1.nativeElement;
            this.ctx = this.canvas.getContext("2d");
                    const data = {
              labels: ['Fuel', 'Driver Salary', 'Conductor Salary', 
              'Helper Salary', 'Insurance', 'Tyre', 'Repair', 'Food', 'Toll', 'Actual Income', 'Expected Income', 'Others' ],
        
              datasets: [
                {
                  label: this.registrationNumber1,
                  // data: Object.values(this.filteredDataVehicle1[0]),
                  data: this.filteredDataVehicle1 ? Object.values(this.filteredDataVehicle1[0] || {}) : [],
                  fill: false,
                  tension: 0,
                  backgroundColor: 'rgba(255, 99, 132, 0.2)',
                  borderColor: 'rgba(255, 99, 132, 1)',
                  borderWidth: 2,
                },
                {
                  label: this.registrationNumber2,
                  // data: Object.values(this.filteredDataVehicle2[0]),
                  data: this.filteredDataVehicle2 ? Object.values(this.filteredDataVehicle2[0] || {}) : [],
                  fill: false,
                  tension: 0,
                  backgroundColor: 'rgba(54, 162, 235, 0.2)',
                  borderColor: 'rgba(54, 162, 235, 1)',
                  borderWidth: 2
                },

              ],
            };

            const options = {
              title: {
                  display: true,
                  text: 'Comparison of Expenses'
              },
              legend: {
                  display: true,
                  position: 'top',
              },
              scales: {
                  xAxes: [{
                      scaleLabel: {
                          display: true,
                          labelString: 'Expenses',
                          fontStyle: 'bold',
                          fontSize: 16
                      }
                  }],
                  yAxes: [{
                      scaleLabel: {
                          display: true,
                          labelString: 'Amount',
                          fontStyle: 'bold',
                          fontSize: 16
                      }
                  }]
              },
              tooltips: {
                  mode: 'nearest',
                  intersect: false
              },
              responsive: true,
          };
            this.expenseOccuredChartComparison = new Chart(this.ctx, {
              type: "line",
              data: data,
              options: options
            });
            this.loaderGraph = false;

            if( this.filteredDataVehicle1.length == 0 && this.filteredDataVehicle2.length == 0) {
              this.view2 = true;
              this.destroyChartComparison();
            } else {
              this.view2 = false;
            }
    } catch (error) {
        console.log("Error", error);
        this.loaderGraph = false;
    }
  }

  destroyChartComparison() {
    this.filteredDataVehicle1 = [];
    this.filteredDataVehicle2 = [];
    this.tableData1 = [];
    this.tableData2 = [];
    
    if (this.expenseOccuredChartComparison) {
        this.expenseOccuredChartComparison.destroy();
    }
}


currenntMonth(){
    var currentDate = new Date();
    var currentMonth = currentDate.getMonth();
    var monthNames = ["January", "February", "March", "April", "May", "June",
                      "July", "August", "September", "October", "November", "December"];
    this.month = monthNames[currentMonth];
    this.month1 = monthNames[currentMonth];
    this.month2 = monthNames[currentMonth];
  }

shiftwiseStudentCount() {
    // this.showLoader = true;
    this.serv.fetchShiftwiseCapacityByVehicle(this.session, this.vehicleId).subscribe(
      (res) => {
        this.studentList = res.msg;
        if((this.studentList).length > 0) {
          const result = {};
          this.studentList.forEach(entry => {
            const { routeShiftName, vacant, occupied, capacity } = entry;
            if (!result[routeShiftName]) {
                result[routeShiftName] = { vacant: 0, occupied: 0, capacity: 0 };
            }
            result[routeShiftName].vacant += vacant;
            result[routeShiftName].occupied += occupied;
            result[routeShiftName].capacity += capacity;
        });

          this.studentList = Object.keys(result).map(routeShiftName => ({
            routeShiftName,
            ...result[routeShiftName]

        }));

        }

      },
      (err) => {
        this.notifier.notify("error", "Please Try Again");
      }
    );
  }


  getRouteAndVehicle() {
    this.serv.getMappedRouteVehicleData(this.session).subscribe((res) => {
      this.routeDetails = [...res.data.morning, ...res.data.evening];
      this.populateTable(true);
    });
  }

  studentAttendanceCount() {
    // this.showLoader = true;
    const vehicle_id =  "All";
    const route_id = "All";
    this.serv.attendanceTransport(vehicle_id, route_id, this.date).subscribe(
      (res) => {
        this.studentList1 = res.msg;
        // this.showLoader = false;
      },
      (err) => {
        this.notifier.notify("error", "Please Try Again");
        // this.showLoader = false;
      }
    );
  }
}
