import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-voucher',
  templateUrl: './voucher.component.html',
  
})
export class VoucherComponent implements OnInit{
 
  @Input() public voucherDetail: any;

 voucherVar={
 productKind_var: undefined,
 subLedger_var: undefined,
 mode_var: undefined,
 amount_var: undefined,
 transactionRemark_var: undefined,
 expenseDate_var: undefined,
 ledger_var: undefined,
 voucherNo_var: undefined,
 inWords: undefined,
 school_name_var:undefined,
 school_address_var:undefined,
 school_phoneno_var:undefined,
 logo_url_var:undefined
 }

  constructor() { }

  ngOnInit(): void {
  
  }

  ngOnChanges() {
    console.log(this.voucherDetail);
    this.voucherVar.productKind_var = this.voucherDetail.productKind;
    this.voucherVar.subLedger_var = this.voucherDetail.subLedger;
    this.voucherVar.mode_var = this.voucherDetail.mode;
    this.voucherVar.amount_var = this.voucherDetail.amount;
    this.voucherVar.transactionRemark_var = this.voucherDetail.transactionRemark;
    this.voucherVar.expenseDate_var = this.voucherDetail.expenseDate;
    this.voucherVar.ledger_var = this.voucherDetail.ledger;
    this.voucherVar.voucherNo_var = this.voucherDetail.voucherNo;
    this.voucherVar.inWords =  this.intToWords(parseInt(this.voucherDetail.amount));
    this.voucherVar.school_name_var = this.voucherDetail.school_name;
    this.voucherVar.school_address_var = this.voucherDetail.school_address,
    this.voucherVar.school_phoneno_var = this.voucherDetail.school_phoneno,
    this.voucherVar.logo_url_var = this.voucherDetail.logo_url=="N/A" || "null"?"/assets/voucher/logo.png":this.voucherDetail.logo_url;
    setTimeout(() => {
      this.setData(this.printNow);
    }, 500)
  }

  setData(printNow) {
    // console.log(this.pdfData)
    let innHtml = document.getElementById("printarea").innerHTML;
    return this.printNow(innHtml);
  }

  printNow(contentToPrint) {
    //console.log(contentToPrint)
    let popupWin;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
        <html>
          <head>
            <title></title>
            <style>
                      .page {
                        background: white;
                        display: block;
                        margin: 0 auto;
                        margin-bottom: 0.5cm;
                        box-shadow: 0 0 0.2cm rgba(0, 0, 0, 0.5);
                        /* border: 5px solid black;*/
                    }
                  
                    .page{
                        width: 21cm;
                        height: 29.7cm;
                  
                    }
                  
                    @media print {
                  
                        body,
                        page {
                            margin: 0;
                            box-shadow: 0;
                        }
                    }
                  
                    .singleTcContainer {
                        width: 46.5%;
                        margin: 25px 10px;
                        border: 2px solid black;
                        display: inline-block;
                        clear: left;
                        height: 495px;
                    }
                  
                    .headerContainer {
                        height: 65px;
                    }
                  
                    .headerContainer,
                    .receiptDetail,
                    .studentDetail,
                    .noteContainer,
                    .signContainer {
                        border-bottom: 2px solid black;
                    }
                  
                    .headerContainer{
                      display:flex;
                    }
                    .logoContainer {
                       flex-basis:20%;                      
                    }
                  
                    .schoolDetailContainer {
                      flex-basis: 80%;
                       text-align: center;
                    }
                  
                    .schoolDetailContainer h1 {
                        font-size: 24px;
                    }
                  
                    .schoolDetailContainer h1,
                    .schoolDetailContainer h5 {
                        line-height: 0;
                    }
                  
                    .schoolDetailContainer h3 {
                        font-size: 12px;
                    }
                  
                    .schoolDetailContainer h3,
                    .schoolDetailContainer h6 {
                        line-height: 3px;
                    }
                  
                    .receiptDetail td,
                    .studentDetail td {
                        font-weight: bold;
                        padding-left: 5px;
                    }
                  
                    .receiptDetail td:nth-child(1) {
                        width: 18%;
                    }
                  
                    .receiptDetail td:nth-child(3) {
                        width: 24%;
                    }
                  
                    .receiptDetail td:nth-child(2) {
                        width: 36%;
                    }
                  
                    .transactionDetailContainer table,
                    .transactionDetailContainer table td {
                        border: 1px solid;
                        border-collapse: collapse;
                    }
                    .transactionDetailContainer table td{
                        height: 20px;
                    }
                    .transactionWord p, .signContainer p{
                        margin: 0;
                        padding: 5px;
                        font-weight: bold;
                    }
                      </style>
          </head>
      <body (load)="loadImage()">`);
      popupWin.document.write(contentToPrint);
      popupWin.document.write(`<div style="text-align:center;margin-bottom:0px;font-size:small">
        <div>
       <script>
       var is_chrome = function () { return Boolean(window.chrome); }
       if(is_chrome) 
        {
            window.print();
            setTimeout(function(){window.close();}, 10000); 
         }
         else
         {
           window.print();
           window.close();
           }
       </script>
      </body>
        </html>`
    );
    popupWin.document.close();
  }
  
  loadImage() {
    console.log("lllllllllllll")
   }


  intToWords(rawValue:any){
    var num=rawValue,
      a=['','one ','two ','three ','four ', 'five ','six ','seven ','eight ','nine ','ten ','eleven ','twelve ','thirteen ','fourteen ','fifteen ','sixteen ','seventeen ','eighteen ','nineteen '],
      b=['','','twenty','thirty','forty','fifty','sixty','seventy','eighty','ninety'],
      c=['thousand', 'million',''],
      words='';
  
    num=('000000000'+num.toString()).substr(-9) // Make number into a predictiable nine character string
      .match(/.{3}/g); // Split string into chuncks of three numbers then reverse order of returned array
  
    for(var i=0;i<c.length;i++){
      var n=num[i],
        str='';
      str+=(words!='')?' '+c[c.length-1-i]+' ':'';
      str+=(n[0]!=0)?(a[Number(n[0])]+'hundred '):'';
      n=n.substr(1);
      str+=(n!=0)?((str!='')?'and ':'')+(a[Number(n)]||b[n[0]]+' '+a[n[1]]):'';
      words+=str;
    }
  return words.replace(/ +/g,' ').replace(/ $/,'');
  }
  
  // window.addEventListener('load',function(){
  //     document.querySelector('[type=number]').addEventListener('change',function(){
  //         document.querySelector('[type=text]').value=intToWords(Number(this.value));
  //     });
  // });
}