import { Component, OnInit } from '@angular/core';
import { transportService } from '../transport.service';
import { NotifierService } from "angular-notifier";
import { OneStorage } from "src/app/GlobalDataStorage/oneStorage.service";

declare var $: any;

@Component({
  selector: 'app-student-attendance-report',
  templateUrl: './student-attendance-report.component.html',
  styleUrls: ['./student-attendance-report.component.scss']
})
export class StudentAttendanceReportComponent implements OnInit {
  allVehicle: any;
  vehicle_id: any;
  session: any = "2023-24";
  studentList: any;
  showLoader: boolean = false;
  loadContent = "";
  routeDetails: any;
  route_id: any;
  date = new Date().toISOString().substr(0, 10);
  viewStudentList : any;
  routeData: any;

  presentStudents = [];
  absentStudents = []
  columns = [];
  voucherAvailable: boolean = false;
  pdfDataObj: any;
  studentStatus : string;
  studentStatusDate : any;

  constructor(
    private serv: transportService,
    public notifier: NotifierService,
    private globals: OneStorage,


  ) { }

  ngOnInit() {
    this.vehicle_id = "0";
    this.route_id = "0";
    this.serv.getRoute(this.globals.activeSession).subscribe((res) => {
      this.routeDetails = res.data;
      this.routeDetails = this.routeDetails.filter((item) => {
        return item.routeShift === "Morning";
      });
    });

    this.serv.getVehicles({ allVehicleFlag: false, inUseVehicleFlag: true }).subscribe((res) => {
      this.allVehicle = res.data.filter(vehicle => vehicle.vehicleCategory === 'Commercial');
    });
  }


  createTable() {
    this.studentList = [];
    this.presentStudents = null;
    this.absentStudents =null;
    this.showLoader = true;
    let vehicle = this.vehicle_id.id;
    let routeId = this.route_id.id;
    let registrationNumber = this.vehicle_id.registrationNumber;
    if(this.route_id.id === undefined) {
      routeId = "All"
    }

    let studentData;
    this.serv.attendanceTransport(vehicle, routeId, this.date).subscribe(
      (res) => {
        this.studentList = res.msg;
         studentData = Array.isArray(res.studentData) ? res.studentData : [];
         studentData.forEach(function(item) {
          item.registrationNo = registrationNumber;
      });

        this.presentStudents = studentData.filter(entry => entry.is_absent === false);
        this.absentStudents  = studentData.filter(entry => entry.is_absent === true);
        this.viewStudentList = this.presentStudents;
        this.showLoader = false;
      },
      (err) => {
        this.notifier.notify("error", "Please Try Again");
        this.showLoader = false;
      }
    );
  }

  routePopulate() {
    let vehicle = this.vehicle_id.id;
    this.serv.getRoutesByVehicleId(vehicle).subscribe(
      (res) => {
        this.routeData = res.routes;
      },
      (err) => {
        this.notifier.notify("error", "Please Try Again");
      }
    );
  }

  presentStudent(){
    this.viewStudentList = this.presentStudents;
    this.studentStatus = "List Of Present Students"

    $("#viewStudentList").modal("show");
    this.populateTablePresent()
  }

  absetStudent(){
    this.viewStudentList = this.absentStudents;
    this.studentStatus = "List Of Absent Students"
    $("#viewStudentList").modal("show");
    this.populateTableAbsent();
  }

  populateTablePresent() {
    // this.showLoader = false;
    let serialNumber = 1;
    let that = this;
    this.columns = [
      // { 
      //   data: null,
      //   title: "Serial No", 
      //   defaultContent: "<i>_</i>",
      //   render: function (data, type, row, meta) {
      //       return serialNumber++;
      //   }
      // },
      { 
        data: "name", 
        title: "Name", 
        defaultContent: "<i>_</i>" 
      },
      { 
        data: "standard", 
        title: "Standard", 
        defaultContent: "<i>_</i>" 
      },
      {
        data: "section",
        title: "Section",
        defaultContent: "<i>not set</i>",
      },
      { 
        data: "registrationNo", 
        title: "Vehicle", 
        defaultContent: "<i>_</i>" 
      },
      { 
        data: "routeName", 
        title: "Route Name", 
        defaultContent: "<i>_</i>" 
      },
    ];

    if ($.fn.DataTable.isDataTable(".example")) {
      $(".example").DataTable().clear().destroy();
    }
    $(".showhideTable").css("display", "block");
    const example = $(".example").DataTable({
      data: this.viewStudentList,
      columns: this.columns,
      columnDefs: [
        {
          visible: false,
          searchable: false,
        },
      ],

      dom: "Bfrtip",
      // order: [],
      // order: [[2, 'asc']],
      buttons: [
        "pageLength",
        {
          extend: 'excelHtml5',
          exportOptions: {
              columns: ':not(:nth-child(10))'
        }
        },
        {
          text: "Pdf",
          action: function () {
            generatePdf();
          },
        },
      ],
      lengthMenu: [
        [10, 25, 50, -1],
        [10, 25, 50, "All"],
      ],
      language: {
        searchPlaceholder: "Search Here",
      },
    });
    async function generatePdf() {
      that.voucherAvailable = true;
      try {
        that.columns.forEach((ele) => {
          that.columns.forEach((zz) => {
            if (!ele.hasOwnProperty(zz.data)) {
              ele[zz.data] = "Not set";
            }
          });
        });
        const inputDateString = new Date();
        const inputDate = new Date(inputDateString);

        const day = inputDate.getDate().toString().padStart(2, '0');
        const month = (inputDate.getMonth() + 1).toString().padStart(2, '0');
        const year = inputDate.getFullYear();

        const hours = inputDate.getHours().toString().padStart(2, '0');
        const minutes = inputDate.getMinutes().toString().padStart(2, '0');

        const formattedDate = `${day}/${month}/${year} ${hours}:${minutes}`;
        var title = "Report generated on  :" + "-  " + formattedDate;
        var reportName = "List Of Present Students";

        await that.globals
          .getPdfDataObj(that.columns, that.viewStudentList, title, reportName)
          .then((res) => {
            that.pdfDataObj = res;
          });
      } catch (e) {
        console.log(e);
      }
    }
  }

  populateTableAbsent() {
    // this.showLoader = false;
    let that = this;
    let serialNumber = 1;
    this.columns = [
      // { 
      //   data: null,
      //   title: "Serial No", 
      //   defaultContent: "<i>_</i>",
      //   render: function (data, type, row, meta) {
      //       return serialNumber++;
      //   }
      // },
      { 
        data: "name", 
        title: "Name", 
        defaultContent: "<i>_</i>" 
      },
      { 
        data: "standard", 
        title: "Standard", 
        defaultContent: "<i>_</i>" 
      },
      {
        data: "section",
        title: "Section",
        defaultContent: "<i>not set</i>",
      },
      { 
        data: "registrationNo", 
        title: "Vehicle", 
        defaultContent: "<i>_</i>" 
      },
      { 
        data: "routeName", 
        title: "Route Name", 
        defaultContent: "<i>_</i>" 
      },
    ];

    if ($.fn.DataTable.isDataTable(".example")) {
      $(".example").DataTable().clear().destroy();
    }
    $(".showhideTable").css("display", "block");
    const example = $(".example").DataTable({
      data: this.absentStudents,
      columns: this.columns,
      columnDefs: [
        {
          visible: false,
          searchable: false,
        },
      ],

      dom: "Bfrtip",
      // order: [],
      // order: [[2, 'asc']],
      buttons: [
        "pageLength",
        {
          extend: 'excelHtml5',
          exportOptions: {
              columns: ':not(:nth-child(10))'
        }
        },
        {
          text: "Pdf",
          action: function () {
            generatePdf();
          },
        },
      ],
      lengthMenu: [
        [10, 25, 50, -1],
        [10, 25, 50, "All"],
      ],
      language: {
        searchPlaceholder: "Search Here",
      },
    });
    async function generatePdf() {
      that.voucherAvailable = true;
      try {
        that.columns.forEach((ele) => {
          that.columns.forEach((zz) => {
            if (!ele.hasOwnProperty(zz.data)) {
              ele[zz.data] = "Not set";
            }
          });
        });
        const inputDateString = new Date();
        const inputDate = new Date(inputDateString);
        const day = inputDate.getDate().toString().padStart(2, '0');
        const month = (inputDate.getMonth() + 1).toString().padStart(2, '0');
        const year = inputDate.getFullYear();
        const hours = inputDate.getHours().toString().padStart(2, '0');
        const minutes = inputDate.getMinutes().toString().padStart(2, '0');
        const formattedDate = `${day}/${month}/${year} ${hours}:${minutes}`;
        var title = "Report generated on  :" + "-  " + formattedDate;
        var reportName = "List of Absent Students";

        await that.globals
          .getPdfDataObj(that.columns, that.viewStudentList, title, reportName)
          .then((res) => {
            that.pdfDataObj = res;
          });
      } catch (e) {
        console.log(e);
      }
    }
  }

}
