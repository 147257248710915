import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from "@angular/common/http";
import { throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { StudentModel, StudentsList } from "../ModelClasses/studentModel";
import { AllApisService } from "../../all-apis.service";

@Injectable({
  providedIn: "root",
})
export class StudentService {
  //addStudentUrl = this.allApis.addStudentUrl; //http://192.168.0.85:8080/user
  studentListUrl = this.allApis.studentListUrl;
  studentListToPromoteUrl = this.allApis.studentListToPromoteUrl;
  promoteStudentUrl = this.allApis.promoteStudentUrl;
  getSeatAllocationApi = this.allApis.getSeatAllocationApi
  dueStudentUrl = this.allApis.dueStudentUrl;
  syncStudentUrl = this.allApis.syncStudentUrl;
  syncAllStudentUrl = this.allApis.syncAllStudentUrl;
  getAllSections = this.allApis.getSection;
  getAllStandards = this.allApis.getClass;
  getAllSessions = this.allApis.sessionList;
  getReportingList = this.allApis.studentListReportingUrl;
  getReportingList2 = this.allApis.studentListReportingUrl2;
  errorData: {};
  constructor(
    private _http: HttpClient,
    private router: Router,
    private allApis: AllApisService
  ) {}

  getStudentCodeInfo() {
    return this._http
      .get<any>(
        this.allApis.studentCodeAdmissionNoInfo,
        this.allApis.httpOptions
      )
      .pipe(catchError(this.handleError));
  }
  //   getAdmissionNoInfo()
  // {

  //   return this._http.get<any>(this.allApis.studentCodeAdmissionNoInfo,this.allApis.httpOptions).pipe(catchError(this.handleError));

  // }
  getCocnessionList(session) {
    let params = new HttpParams();
    //this.sibling,this.staff,this.studentstatus
    params = params.append("session", session);
    this.allApis.httpOptions.params = params;

    return this._http
      .get<any>(
        this.allApis.customeConcessionListbyId,
        this.allApis.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  //student
  sendStudentDetails(studentReg: StudentModel) {
    return this._http
      .post<any>(this.allApis.addStudent, studentReg, this.allApis.httpOptions)
      .pipe(catchError(this.handleError));
  }

  addSpecialCategory(specialCategory) {
    this.allApis.httpOptions.params = new HttpParams();
    return this._http
      .patch<any>(
        this.allApis.specialCategory,
        { specialCategory: specialCategory },
        this.allApis.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  getSpecialCategory() {
    this.allApis.httpOptions.params = new HttpParams();
    return this._http
      .get<any>(this.allApis.specialCategory, this.allApis.httpOptions)
      .pipe(catchError(this.handleError));
  }

  updateStudentDetails(studentReg: StudentModel) {
    this.allApis.httpOptions.params = new HttpParams();
    return this._http
      .patch<any>(this.allApis.addStudent, studentReg, this.allApis.httpOptions)
      .pipe(catchError(this.handleError));
  }
  //student list
  getStudentList(studentList) {
    return this._http
      .post<any>(this.studentListUrl, studentList, this.allApis.httpOptions)
      .pipe(catchError(this.handleError));
  }
  addFeeDetails(fee) {
    console.log("heello", fee);
    return this._http
      .patch<any>(this.allApis.addFeeToStudent, fee, this.allApis.httpOptions)
      .pipe(catchError(this.handleError));
  }
  deleteFeeDetails(fee) {
    console.log("heello", fee);
    return this._http
      .patch<any>(
        this.allApis.deleteFeeToStudent,
        fee,
        this.allApis.httpOptions
      )
      .pipe(catchError(this.handleError));
  }
  getMonthListForSession(ses) {
    //     let params=new HttpParams();
    //     //this.sibling,this.staff,this.studentstatus
    //     params=params.append('session',ses);
    //  this.allApis.httpOptions.params=params;
    //     var a = this.allApis.getsessionMonths+"?"+"session="+ses;
    //     console.log(a);

    return this._http
      .get<any>(this.allApis.getsessionMonths, this.allApis.httpOptions)
      .pipe(catchError(this.handleError));
  }
  addSkipMonth(
    studnetId,
    skipmonths,
    skipCoDiscount,
    skipCoConcessions,
    session,
    disAllowFeeCollection
  ) {
    return this._http
      .patch<any>(
        this.allApis.studentSkipMonth,
        {
          skipmonth: skipmonths,
          studnetId: studnetId,
          skipCoDiscount: skipCoDiscount,
          skipCoConcessions: skipCoConcessions,
          session: session,
          disAllowFeeCollection: disAllowFeeCollection,
        },
        this.allApis.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  getFeeDetails(studnetId) {
    let params = new HttpParams();
    //this.sibling,this.staff,this.studentstatus
    params = params.append("studentId", studnetId);
    this.allApis.httpOptions.params = params;
    console.log(
      "-------------get student list for reporting",
      this.allApis.httpOptions.params
    );

    return this._http
      .get<any>(this.allApis.addFeeToStudent, this.allApis.httpOptions)
      .pipe(catchError(this.handleError));
  }

  getStudentListForReporting(
    session,
    std,
    section,
    newStudent,
    category,
    gender,
    sibling,
    staff,
    studentstatus,
    rte,
    concessionList,
    specialCategory,
    tcSubmitted
  ) {
    let params = new HttpParams();
    //this.sibling,this.staff,this.studentstatus
    tcSubmitted;
    params = params.append("tcSubmitted", tcSubmitted);
    params = params.append("session", session);
    params = params.append("standard", std);
    params = params.append("section", section);
    params = params.append("newStudnet", newStudent);
    params = params.append("category", category);
    params = params.append("gender", gender);
    params = params.append("sibling", sibling);
    params = params.append("staff", staff);
    params = params.append("studentstatus", studentstatus);
    params = params.append("rte", rte);
    params = params.append("concessionIdList", concessionList);
    params = params.append("specialCategory", specialCategory);

    this.allApis.httpOptions.params = params;
    console.log(
      "-------------get student list for reporting",
      this.allApis.httpOptions.params
    );

    return this._http
      .get<any>(this.getReportingList, this.allApis.httpOptions)
      .pipe(catchError(this.handleError));
  }

  getStudentListForReporting1(
    session,
    std,
    section,
    newStudent,
    category,
    gender,
    sibling,
    staff,
    studentstatus,
    rte,
    concessionList,
    specialCategory,
    tcSubmitted,
    stopage,
    route,
    stopageIdList
  ) {
    let params = new HttpParams();
    //this.sibling,this.staff,this.studentstatus
    tcSubmitted;
    params = params.append("tcSubmitted", tcSubmitted);
    params = params.append("session", session);
    params = params.append("standard", std);
    params = params.append("section", section);
    params = params.append("newStudnet", newStudent);
    params = params.append("category", category);
    params = params.append("gender", gender);
    params = params.append("sibling", sibling);
    params = params.append("staff", staff);
    params = params.append("studentstatus", studentstatus);
    params = params.append("rte", rte);
    params = params.append("concessionIdList", concessionList);
    params = params.append("specialCategory", specialCategory);
    params = params.append("stopage", stopage);
    params = params.append("route", route);
    params = params.append("stopageIdList", stopageIdList);
    this.allApis.httpOptions.params = params;
    console.log(
      "-------------get student list for reporting",
      this.allApis.httpOptions.params
    );

    return this._http
      .get<any>(
        this.allApis.studentListReportingrouteUrl,
        this.allApis.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  getStudentListForReporting_new(
    session,
    standard,
    section,
    studentstatus,
    stopId,
    routeId,
    routeShift,
    routeName
  ) {
    let params = new HttpParams();

    params = params.append("session", session);
    params = params.append("standard", standard);
    params = params.append("section", section);
    params = params.append("studentstatus", studentstatus);
    params = params.append("stopId", stopId);
    params = params.append("routeId", routeId);
    params = params.append("routeShift" , routeShift);
    params = params.append("routeName" , routeName);
    
    // params = params.append('stopageIdList',stopageIdList);
    this.allApis.httpOptions.params = params;
    console.log(
      "-------------get student list for reporting",
      this.allApis.httpOptions.params
    );

    return this._http
      .get<any>(
        this.allApis.studentListReportingrouteUrl1,
        this.allApis.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  getStudentListForReporting2(
    session,
    std,
    section,
    newStudent,
    category,
    gender,
    sibling,
    staff,
    studentstatus,
    rte,
    concessionList
  ) {
    let params = new HttpParams();
    //this.sibling,this.staff,this.studentstatus
    params = params.append("session", session);
    params = params.append("standard", std);
    params = params.append("section", section);
    params = params.append("newStudnet", newStudent);
    params = params.append("category", category);
    params = params.append("gender", gender);
    params = params.append("sibling", sibling);
    params = params.append("staff", staff);
    params = params.append("studentstatus", studentstatus);
    params = params.append("rte", rte);
    params = params.append("concessionIdList", concessionList);

    this.allApis.httpOptions.params = params;
    console.log(
      "-------------get student list for reporting",
      this.allApis.httpOptions.params
    );

    return this._http
      .get<any>(this.getReportingList2, this.allApis.httpOptions)
      .pipe(catchError(this.handleError));
  }

  getSection(std: string) {
    let params = new HttpParams();
    params = params.append("standard", std);
    this.allApis.httpOptions.params = params;
    return this._http
      .get<any>(this.getAllSections, this.allApis.httpOptions)
      .pipe(catchError(this.handleError));
  }

  getStandard() {
    return this._http
      .get<any>(this.getAllStandards, this.allApis.httpOptions)
      .pipe(catchError(this.handleError));
  }

  getSession() {
    return this._http
      .get<any>(this.getAllSessions, this.allApis.httpOptions)
      .pipe(catchError(this.handleError));
  }

  getSudentListToPromote(standard: string, section: string, session: any) {
    let params = new HttpParams();
    params = params.append("class", standard);
    params = params.append("section", section);
    params = params.append("session", session);
    this.allApis.httpOptions.params = params;
    return this._http
      .get<any>(this.studentListToPromoteUrl, this.allApis.httpOptions)
      .pipe(catchError(this.handleError));
  }

  promoteStudent({
    newSessionDetails: newSessionDetails,
    oldSessionDetails: oldSessionDetails,
    userIds: userIds,
  }) {
    return this._http
      .patch<any>(
        this.promoteStudentUrl,
        {
          newSessionDetails: newSessionDetails,
          oldSessionDetails: oldSessionDetails,
          userIds: userIds,
        },
        this.allApis.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  getSeatAllocation(session : string){
    let params = new HttpParams();
    params = params.append("session", session);
    this.allApis.httpOptions.params = params;

    return this._http
      .get<any>(this.getSeatAllocationApi, this.allApis.httpOptions)
      .pipe(catchError(this.handleError));
  }

  postStudentDueDetails(obj) {
    return this._http
      .patch<any>(this.dueStudentUrl, obj, this.allApis.httpOptions)
      .pipe(catchError(this.handleError));
  }

  updateSyncStudent(obj) {
    return this._http
      .patch<any>(this.syncStudentUrl, obj, this.allApis.httpOptions)
      .pipe(catchError(this.handleError));
  }

  updateSyncAllStudent(obj) {
    return this._http
      .patch<any>(this.syncAllStudentUrl, obj, this.allApis.httpOptions)
      .pipe(catchError(this.handleError));
  }
  //  error handling
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.

      console.error("An error occurred:", error.error.message);
    } else {
      // The backend returned an unsuccessful response code.

      // The response body may contain clues as to what went wrong,

      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }

    // return an observable with a user-facing error message

    this.errorData = {
      errorTitle: "Oops! Request for document failed",
      errorDesc: "Something bad happened. Please try again later.",
    };
    return throwError(this.errorData);
  }
}
