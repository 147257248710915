import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from "@angular/router"
import { UiSwitchModule } from "ngx-ui-switch";

import { DataTablesModule } from 'angular-datatables';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
// import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
// import { BrowserModule } from '@angular/platform-browser';
// import { routes } from '../app-routing.module'
// components which are beeing used at multiple places
import { GlobalControllersModule } from '../GlobalControllersO/global-controllers-o.module'
import {WebcamModule} from 'ngx-webcam';
import { PrintTablePdfComponent} from './print-table-pdf/print-table-pdf.component'
import {VoucherComponent } from './voucher/voucher.component'
import {PageLoaderComponent } from './page-loader/page-loader.component';
import { PageLoader2Component } from './page-loader2/page-loader2.component'

@NgModule({
  declarations: [PrintTablePdfComponent,VoucherComponent, PageLoaderComponent, PageLoader2Component],
  imports: [
    CommonModule,
   
    UiSwitchModule.forRoot({
      // size: 'small',
       color: 'rgb(0, 189, 99)',
       switchColor: '#fff',
       defaultBgColor: '#ff0000',
       defaultBoColor : '#fff',
      // checkedLabel: 'on',
      // uncheckedLabel: 'off'
    }),
    // RouterModule.forRoot(routes),
    NgMultiSelectDropDownModule.forRoot()
  ],
  exports: [
    // BrowserModule,
    CommonModule,
    FormsModule,
    RouterModule,
    UiSwitchModule,
    
    DataTablesModule,
    NgMultiSelectDropDownModule,
    // BrowserAnimationsModule,
    HttpClientModule,
    GlobalControllersModule,
    WebcamModule,
    PrintTablePdfComponent,
    PageLoaderComponent,
    VoucherComponent,
    PageLoader2Component
  ]
})
export class CommonImportsModule { }
