import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from "@angular/common/http";
import { environment } from "../environments/environment";

@Injectable({
  providedIn: "root",
})
export class AllApisService {
  constructor(private router: Router) {}
  baseurl = environment.apiHost;
  reporturl = environment.reportHost;
  query = this.baseurl + "/query";

  signinUrl = this.baseurl + "/user/login";
  signupUrl = this.baseurl + "/user/admin";
  sendOtp = this.baseurl + "/user/sendOtp";
  verifyOtp = this.baseurl + "/user/verifyOtp";
  resetPass = this.baseurl + "/user/resetPass";
  updateToken = this.baseurl + "/user/updatedToken";

  signInSmUrl = this.baseurl + "/user/sm_login";
  signInWithSMToken = this.baseurl + "/user/sm_authenticate";

  getbalanceSheetreport =
    this.reporturl + "/transactions/getBalanceSheetreport";

  addSchoolUrl = this.baseurl + "/school";
  specialCategory = this.baseurl + "/school/specialCategory";

  fatchSchoolDetailsUrl = this.baseurl + "/school";
  schoolList = this.baseurl + "/school/schoolList";
  schoolNameListUrl = this.baseurl + "/school/display";
  sessionList = this.baseurl + "/school/session";
  editInstallment = this.baseurl + "/school/sessionInstallment";
  editMiscInstallment = this.baseurl + "/school/sessionMiscInstallment";
  addFeeCategoryGroups = this.baseurl + "/school/addFeeCategoryGroups";
  discount = this.baseurl + "/school/discount";

  onlinePayementSetting = this.baseurl + "/school/onlinePayementSetting";
  commonConcession = this.baseurl + "/school/commonConcession";
  deleteFeeCategoryGroups = this.baseurl + "/school/deleteFeeCategoryGroups";
  // cahnge to add user @ abhiskek
  addShop = this.baseurl + "/school/addShop";
  addStudentUrl = this.baseurl + "/user";

  addStudent = this.baseurl + "/user/student";

  fatchStudentDetails = "";
  getSudents = this.baseurl + "/user/studentByClass";

  addUser = this.baseurl + "/user";
  fatchUserList = this.baseurl + "/user/userList";
  userRoleListUrl = this.baseurl + "/school/role";

  addFee = this.baseurl + "/feeCollection";
  transferStructureToNextSession =
    this.baseurl + "/feeCollection/transferStructureToNextSession";
  updateFeeDetails = this.baseurl + "/feeCollection";
  fatchFeeDetails = this.baseurl + "/feeCollection/all";
  getFeeCategory = this.baseurl + "/school/feeCategory";
  getInstallments = this.baseurl + "/school/sessionInstallment";
  getsessionMonths = this.baseurl + "/school/sessionMonths"; //sessionMonths
  // add concession
  studentCodeAdmissionNoInfo =
    this.baseurl + "/school/studentCodeAdmissionNoInfo";

  addRTEConcession = this.baseurl + "/school/RTEConcession";
  addStaffConcession = this.baseurl + "/school/StaffConcession";
  addSiblingConcession = this.baseurl + "/school/SiblingConcession";
  addCustomConcession = this.baseurl + "/school/customStudentConcession";
  customeConcession = this.baseurl + "/customeConcession";
  customeConcessionList = this.baseurl + "/customeConcession/list";
  customeConcessionListbyId = this.baseurl + "/customeConcession/listById";
  getStandardConcession = this.baseurl + "/school/standardConcession";
  // fetchFeeDetails=this.baseurl+'/feeCollection/data';
  // fetchFeeParticulars=this.baseurl+'/feeCollection/feeParticular';

  studentListUrl = this.baseurl + "/user/studentList";
  studentSkipMonth = this.baseurl + "/user/skipmonth";
  studentListToPromoteUrl = this.baseurl + "/user/studentListToPromote";
  promoteStudentUrl = this.baseurl + "/user/transfer/nextsession";

  getSeatAllocationApi = this.baseurl + "/transport_new/getSeatAllocationApi"

  dueStudentUrl = this.baseurl + "/user/dueStudentUrl";
  syncStudentUrl = this.baseurl + "/userSync/syncStudentUrl";
  syncAllStudentUrl = this.baseurl + "/userSync/syncAllStudentUrl";
  studentListReportingUrl = this.reporturl + "/user/reportingstudentList";
  studentListReportingrouteUrl =
    this.baseurl + "/user/reportingstudentList/route";

    studentListReportingrouteUrl1 = this.baseurl + "/transport_new/studentReport"
  studentListReportingUrl2 = this.baseurl + "/user/reportingstudentList2";
  addFeeToStudent = this.baseurl + "/user/addFee";
  addFeeToStudent1 = this.baseurl + "/user/addFee1";
  deleteFeeToStudent = this.baseurl + "/user/deleteFee";
  getSection = this.baseurl + "/school/getSection";
  getClass = this.baseurl + "/school/getStandard";
  tcseting = this.baseurl + "/school/tcseting";
  fetchFeeDetails = this.baseurl + "/feeCollection/data";
  fetchFeeParticulars = this.baseurl + "/feeCollection/feeParticular";
  copyScheduling = this.baseurl + "/feeCollection/copyScheduling";
  createRoute = this.baseurl + "/transport";
  deleteRoute = this.baseurl + "/transport/delete";
  updateRoute = this.baseurl + "/transport/update";
  enableRoute = this.baseurl + "/transport/enable";
  toggleIsDestinationRoute = this.baseurl + "/transport/destination";

  mapRoute = this.baseurl + "/user/userList";

  feeStructure = this.baseurl + "/feeCollection/getFeeStructuree";
  //
  itrReport = this.baseurl + "/feeCollection/itrReport";
  itrReportreciept = this.baseurl + "/feeCollection/itrReport/reciept";
  itrReports = this.baseurl + "/feeCollection/itrReports";
  ///inventory
  addCategory = this.baseurl + "/inventory/addCategory";
  addCategoryName = this.baseurl + "/inventory/addCategoryName";
  getCategory = this.baseurl + "/inventory/getCategory";
  addProduct = this.baseurl + "/inventory";
  getAllProduct = this.baseurl + "/inventory/getSchoolProducts";
  addVariant = this.baseurl + "/variant";
  addShopDistribution = this.baseurl + "/shopData";
  postSupplies = this.baseurl + "/shopData/supplies";
  getSupplyList = this.baseurl + "/shopData/getSupplyReport";
  getSupplier = this.baseurl + "/shopData/supplies";
  addTransaction = this.baseurl + "/inventoryTransaction";
  getStudentPurchased =
    this.baseurl + "/inventoryTransaction/getStudentDetails";
  generateReceipt = this.baseurl + "/inventoryTransaction/generateReceipt";
  voucherReceipt = this.baseurl + "/inventoryTransaction/voucherReceipt";
  cancelReceipt = this.baseurl + "/inventoryTransaction/cancel";
  // getTransaction=this.baseurl+'/inventoryTransactionReport';


  // new transport
  getStopApi = this.baseurl + "/transport_new/getStop"
  getStopNewApi = this.baseurl + "/transport_new/getStopNew"

  getStopNameApi = this.baseurl + "/transport_new/getStopName"

  getStopAmountApi = this.baseurl + "/transport_new/getStopAmount"

  addStopApi = this.baseurl + "/transport_new/addStop"

  updateStopApi = this.baseurl + "/transport_new/updateStop"

  deleteStopApi = this.baseurl + "/transport_new/deleteStop"

  addDriverApi = this.baseurl + "/transport_new/addDriver"
  addConductorApi = this.baseurl + "/transport_new/addConductor"

  getDriverApi = this.baseurl + "/transport_new/getDriver"

  getConductorApi = this.baseurl + "/transport_new/getConductor"

  getDriverWithSessionApi = this.baseurl + "/transport_new/getDriverWithSession"

  getConductorWithSessionApi = this.baseurl + "/transport_new/getConductorWithSession"

  getUserNumberApi = this.baseurl + "/transport_new/getUserNumber"

  allocateData = this.baseurl + "/transport_new/allocateVehicleToDriverConductor"

  saveRouteApi = this.baseurl + "/transport_new/addRoute"

  getRouteApi = this.baseurl + "/transport_new/listRoute"

  saveVehicleApi = this.baseurl + "/transport_new/addVehicle"

  updateVehicleApi = this.baseurl + "/transport_new/updateVehicle"

  updateDriverApi = this.baseurl + "/transport_new/updateDriver"

  updateConductorApi = this.baseurl + "/transport_new/updateConductor"

  getVehicleApi = this.baseurl + "/transport_new/getVehicle"

  gpsData = this.baseurl + "/transport_new/getData"

  getMappedData = this.baseurl + "/transport_new/getMappedData"

  mapApi = this.baseurl + "/transport_new/mapApi"

  addTransportApi = this.baseurl + "/transport_new/addTransport"

  uploadFile = this.baseurl + "/transport_new/addFile"

  validateGpsId = this.baseurl + "/transport_new/validateGps"

  validateRegNum = this.baseurl + "/transport_new/validateRegNum"

  checkAllocationApi = this.baseurl + "/transport_new/checkAllocation"

  trackSchoolBusData = `${this.baseurl}/transport_new/trackSchoolBus`

  overSpeedFetch = `${this.baseurl}/transport_new/fetchSchoolBusOverSpeeding`
  
  trackSchoolSingleBusData = `${this.baseurl}/transport_new/trackSchoolBusSingle`


  trackSchoolBusDropDown = `${this.baseurl}/transport_new/trackSchoolBusDropDown`

  fetchVehicleAll = `${this.baseurl}/transport_new/fetchAllVehicle`


  fetchOverSpeedingData = `${this.baseurl}/transport_new/fetchSchoolBusOverSpeeding2`;

  fetchDistanceReportData = `${this.baseurl}/transport_new/fetchDistanceReport`;

  transportExpensePrivate = `${this.baseurl}/transport_new/transportExpensePrivate`;

  transportExpenseCommercial = `${this.baseurl}/transport_new/transportExpenseCommercial`;
  
  transportExpenseCommercialInstallment = `${this.baseurl}/transport_new/transportExpenseCommercialInstallment`;

  transportExpenseCommercialDashboard = `${this.baseurl}/transport_new/transportExpenseCommercialDashboard`;

  transportInstallments = `${this.baseurl}/transport_new/getTransportFeeInstallment`;

  updateMailSetting = `${this.baseurl}/sendMail/updateTransportMailSetting`;

  getMailSetting = `${this.baseurl}/sendMail/getMailSetting`;
   
  addHelperApi = this.baseurl + "/transport_new/addHelper"

  getHelperApi = this.baseurl + "/transport_new/getHelper"

  getHelperWithSessionApi = this.baseurl + "/transport_new/getHelperWithSession"

  fetchExpenseData = this.baseurl + "/transport_new/getDriverExpenseWeb";

  fetchStudentListByVehicle = `${this.baseurl}/transport_new/fetchStudentListByVehicle`;
  fetchshiftWiseByVehicle = `${this.baseurl}/transport_new/shiftwiseCapacityByVehicle`;
  fetchAttendance = `${this.baseurl}/transport_new/studentAttendance`;
  fetchAllRoutesByVehicleId = `${this.baseurl}/transport_new/getRoutesByVehicleId`;


  incomeVehicle = `${this.baseurl}/transport_new/incomeVehicle`;

  updateExpenseByIncharge = `${this.baseurl}/transport_new/updateExpenseWeb`;
  deleteExpenseByIncharge = `${this.baseurl}/transport_new/deleteExpenseWeb`;
  addExpenseByIncharge = `${this.baseurl}/transport_new/addExpenseWeb`;
  addShift = `${this.baseurl}/transport_new/shift`;
  getShiftWiseCount = `${this.baseurl}/transport_new/vehicleCountShift`;
  storeCopyStoppage = `${this.baseurl}/transport_new/copyStoppage`;
  storeCopyRoute = `${this.baseurl}/transport_new/copyRoute`;
  idpAuth0Login = `${this.baseurl}/transport_new/getIdpLoginPopupAuth0ForMapple`;

  //suppliers
  addSupplier = this.baseurl + "/supplier";

  //admission
  enquiryFormUrl = this.baseurl + "/enquiry";

  newEnquiryFormUrl = this.baseurl + "/generatePdf/newEnquiry";
  schoolDetailUrl = this.baseurl + "/generatePdf/schoolDetailUrl";
  mailNewServiceUrl = this.baseurl + "/generatePdf/sendMail";

  registrationFormUrl = this.baseurl + "/registration";
  admissionPageUrl = this.baseurl + "/admission";
  admissionFeeUrl = this.baseurl + "/admissionFee";
  messageUrl = this.baseurl + "/message";
  othersEnquiryUrl = this.baseurl + "/others";
  preAdmissionUrl = this.baseurl + "/preAdmission";
  mailServiceUrl = this.baseurl + "/sendMail";
  generatePdfUrl = this.baseurl + "/filterPdf";

  ///school Expenses
  addSchoolProductDistribution =
    this.baseurl + "/schoolExpense/schoolProductDistribution";
  manageSchoolInventory = this.baseurl + "/schoolExpense/schoolInventory";
  schoolProductTransaction =
    this.baseurl + "/schoolExpense/schoolProductTransaction";
  variantPrice = this.baseurl + "/schoolExpense";
  addExpense = this.baseurl + "/schoolExpense/createExpense";
  updateExpense = this.baseurl + "/schoolExpense/updateExpense";
  expenseReport = this.baseurl + "/schoolExpense/report";
  ledger = this.baseurl + "/schoolExpense/ledger";

  getStudent = this.baseurl + "/user/studentNameList";
  studentShopFlagCheck = this.baseurl + "/configuration/studentShopFlagCheck";
  //passbook
  passbook = this.baseurl + "/schoolExpense/passbook";
  //Master Settings
  studentcolumnUrl = this.baseurl + "/school/studentColumn";
  presentsessionUrl = this.baseurl + "/school/presentsession";
  activesessionUrl = this.baseurl + "/school/activesession";

  getSmsInfoUrl = this.baseurl + "/school/getSmsInfo";
  getNsetSmsUseInfoUrl = this.baseurl + "/school/getSmsUseInfo";
  getItrInfo = this.baseurl + "/school/getItrInfo";
  getNsetreceiptInfoUrl = this.baseurl + "/school/receiptInfo";
  updateSchoolShopUrl = this.baseurl + "/school/schoolShopUpdate";
  updateTransportConfigUrl = this.baseurl + "/school/updateTransportConfig";

  sendAdmitCardOnCollectFeeUrl =
    this.baseurl + "/configuration/updateOthersInfo";
  sendRouteInfoFeeUrl = this.baseurl + "/configuration/updateRouteInfo";
  sendScholarInfoFeeUrl = this.baseurl + "/configuration/updateScholarInfo";
  sendRemarkInfoFeeUrl = this.baseurl + "/configuration/updateRemarkInfo";
  sendFontSizeInfoUrl = this.baseurl + "/configuration/updateFontSizeInfo";
  sendShoppingForStudentUrl =
    this.baseurl + "/configuration/shoppingForStudent";
  aluminiStudentConfigUrl =
    this.baseurl + "/configuration/aluminiStudentConfig";
  getAdmitCardOnCollectFeeUrl = this.baseurl + "/configuration/getOthersInfo";

  updateFeeValueTypeUrl = this.baseurl + "/configuration/feeValueType";

  getFeeValueTypeUrl = this.baseurl + "/configuration/feeValueType";

  schoolPaymentModuleUrl = this.baseurl + "/school/schoolPaymentModule";
  schoolRoleModuleUrl = this.baseurl + "/school/schoolRoleModule";
  schoolOthersPaymentUrl = this.baseurl + "/school/schoolOthersPayment";

  presetsession = this.baseurl + "/school/presentsession";
  updateSession = this.baseurl + "/user/updateSession";

  session = this.baseurl + "/school/session";

  //addFine
  getPatchDayWiseFine = this.baseurl + "/school/daywisefine";
  getPatchMonthWiseFine = this.baseurl + "/school/monthwisefine";
  getPatchIntervalWise = this.baseurl + "/school/intervalwise";

  rtiReport = this.baseurl + "/school/rtiReport";
  feeRecieptSetting = this.baseurl + "/school/feeRecieptSetting";

  setRoleAccess = this.baseurl + "/role/roleGrants";
  getAllRoles = this.baseurl + "/role/fetchAllRoles";
  changeActivity = this.baseurl + "/role/changeRoleActivity";

  ///////////// Master setting///////////////
  getStudentFields = this.baseurl + "/configuration/getStudentFields";
  updateStudentFields = this.baseurl + "/configuration/updateStudentFields";
  updateFeeTemplate = this.baseurl + "/configuration/updateFeeTemplate";
  updateStudentFields1 =
    this.baseurl + "/configuration/updateStudentFieldsForStudentlist";

  /////////////Token//////////////////////////////////
  httpOptions = {
    headers: new HttpHeaders({
      Authorization: "Bearer " + this.getToken(),
      //localStorage.getItem('token')
      // 'ID': emp.UserID,
    }),
    params: new HttpParams(),
  };

  updateTokenFun() {
    console.log("this", this.getToken());
    this.httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + this.getToken(),
        //localStorage.getItem('token')
        // 'ID': emp.UserID,
      }),
      params: new HttpParams(),
    };
  }

  /*  to remove the token from the local storage */
  logoutUser() {
    localStorage.removeItem("token");
    localStorage.removeItem("userDetail");
    localStorage.clear();
    // this.httpOptions=null;
    //this.router.navigate(["/signin"]);
    window.location.href = "https://schoolmitra.com/users/force_logout";
  }

  /* to retrive token to send to the server */
  getToken() {
    return localStorage.getItem("token");
  }

  /* to check token is present in local storage */
  isLoggedIn() {
    return !!localStorage.getItem("token"); /* true or false */
  }

  getSchoolId() {
    return localStorage.getItem("schoolId");
  }
}
