export class VehicleModel {
  registrationNumber: string;
  registrationDate: Date;
  ownerName: string;
  ChesisNumber: string;
  EngineNumber: string;
  VehicleColor: String;
  modelNumber: String;
  manufactureDate: Date;
  purchaseDate: Date;
  insuranceValidity: Date;
  fitnessValidity: Date;
  polutionValidity: Date;
  financeDetails  :string;
  VehicleType: string;
  seatingCapacity: string;
  mileage: string;
  fuelCapacity: string;
  purchaseType: string;
  status: string;
  gpsDeviceId: string;
  vehicleDetails : vehicleDetails[] = [];
  gpsType: string;
  gpsStatus: string;
  helper: string;
  helperId: string;
  initialOdometer: number;
  vehicleCategory: string;
  shiftAndCapacity:Array <any> = [];
}

export class vehicleDetails {
  issuedAt: Date;
  expiredAt: Date;
  documentType: string;
  certificate:any;
  remarks: string;
};


export class shiftAndCapacity {
  shiftName: string;
  shiftId : string;
  shiftCapacity: string;
};

