import { Component, OnInit, Inject } from "@angular/core";
import { transportService } from "../transport.service";
import { NotifierService } from "angular-notifier";
import { OneStorage } from "src/app/GlobalDataStorage/oneStorage.service";
import { SharedService } from "src/app/dashboardO/dashboard/shared.service";
import { DOCUMENT } from '@angular/common';


declare var $: any;

@Component({
  selector: "app-map-route-vehicle",
  templateUrl: "./map-route-vehicle.component.html",
  styleUrls: ["./map-route-vehicle.component.scss"],
})
export class MapRouteVehicleComponent implements OnInit {
  vehicleList = [];
  routeList = [];
  selectedRouteUp = "0";
  selectedRouteDown = "0";
  selectedVehicleUp = "0";
  selectedVehicleDown = "0";
  routeDetails: any;
  routeList2: any[];
  coloumns = [];
  clickEventsubscription: any;

  previousMorningVehicleDetails = {};

  previousEveningVehicleDetails = {};

  updateFlag = false;

  morningTableData = [];
  eveningTableData = [];
  showLoader:boolean = false;
  loadContent = "";

  constructor(
    private serv: transportService,
    public notifier: NotifierService,
    public globals: OneStorage,
    public sharedService : SharedService,
    @Inject(DOCUMENT) private document: Document

  ) {
    this.clickEventsubscription = this.sharedService
    .getClickEvent()
    .subscribe(() => {
      this.globals.activeSession = this.sharedService.getSession();
      this.ngOnInit();
    });
  }

  ngOnInit() {
    this.updateFlag = false;
    let action_btn_edit =
      '<span class="editBtn"><i title="Edit" style="padding-left:10px;position:pointer;cursor: pointer"><img src="../../../assets/dash/pencil.svg"> </i></span>'
      //  <span class="deleteBtn"><i style="padding-left:10px;position:pointer;" title="delete"><img src="../../../assets/dash/Group45993.svg"></i></span> ';

    var i = 1;
    this.coloumns = [
      {
        defaultContent: "<i>Not Set</i>",
        title: "S. No.",
        // render: function (data, type, full, meta) {
        //   return i++;
        // },
      },
      { data: "session", defaultContent: "<i>Not Set</i>", title: "Session" },
      { data: "name", defaultContent: "<i>Not Set</i>", title: "Route Name" },
      {
        data: "vehicle.registrationNumber",
        defaultContent: "<i>Not Set</i>",
        title: "Vehicle No.",
      },
      {
        data: "vehicle.driverName",
        defaultContent: "<i>Not Set</i>",
        title: "Driver Name",
      },
      {
        data: null,
        className: "",
        render: function (data) {
          return action_btn_edit;
        },
        defaultContent: action_btn_edit,
      },
    ];

    this.serv.getVehicles({allVehicleFlag : false , inUseVehicleFlag : true}).subscribe((res) => {
      this.vehicleList = res.data;
    });

    this.serv.getRoute(this.globals.activeSession).subscribe((res) => {
      this.routeDetails = res.data;

      let morningRoute = [];
      let eveningRoute = [];

      this.routeDetails.forEach((elem) => {
        if (elem.routeShift == "Morning") {
          morningRoute.push(elem);
        } else {
          eveningRoute.push(elem);
        }
      });

      this.routeList = morningRoute;
      this.routeList2 = eveningRoute;
    });
    this.getRoutes()
   
  }

  scrollToTop() {
    this.document.body.scrollTop = 0; 
    this.document.documentElement.scrollTop = 0; 
  }

  getRoutes(){
    this.showLoader = true;
    this.serv.getMappedRouteVehicleData(this.globals.activeSession).subscribe((res) => {
      this.morningTableData = res.data.morning;
      this.populateTable(true);
      this.eveningTableData = res.data.evening;
      this.populateTable1(true);
      console.log("res", this.morningTableData, this.eveningTableData);
    });
  }

  validateFormForMorning() {
    if (this.selectedRouteUp == "0" || this.selectedRouteUp.trim() == "") {
      this.notifier.notify("error", "Please Select Route.");
      return false;
    }
    if (this.selectedVehicleUp == "0" || this.selectedVehicleUp.trim() == "") {
      this.notifier.notify("error", "Please Select Vehicle.");
      return false;
    }
    return true;
  }

  validateFormForEvening() {
    if (!this.selectedRouteDown || this.selectedRouteDown.trim() == "") {
      this.notifier.notify("error", "Please Select Route.");
      return false;
    }
    if (!this.selectedVehicleDown || this.selectedVehicleDown.trim() == "") {
      this.notifier.notify("error", "Please Select Vehicle.");
      return false;
    }
    return true;
  }

  addMorningRoute() {

    if (!this.validateFormForMorning()) {
      return;
    }
    let mapData = { route: this.selectedRouteUp, vehicle: this.selectedVehicleUp };

    if(this.updateFlag && this.selectedVehicleUp !==this.previousMorningVehicleDetails["vehicle"] ){
      mapData["previousVehicleDetails"] = this.previousMorningVehicleDetails;
    }


    this.serv
      .mapVehicleToRoute(mapData)
      .subscribe((res) => {
        console.log("res", res);
        this.notifier.notify("success", "Route Vehicle Updated.");
        this.getRoutes()
      });

      this.selectedVehicleUp = null;
      this.selectedRouteUp = null;
  }

  addEveningRoute() {
    if (!this.validateFormForEvening()) {
      return;
    }

    let mapData = { route: this.selectedRouteDown, vehicle: this.selectedVehicleDown };

    if(this.updateFlag && this.selectedVehicleDown !==this.previousEveningVehicleDetails["vehicle"] ){
      mapData["previousVehicleDetails"] = this.previousEveningVehicleDetails;
    }


    this.serv
      .mapVehicleToRoute(mapData)
      .subscribe((res) => {
        console.log("res", res);
        this.notifier.notify("success", "Route Vehicle Updated.");
        this.getRoutes()
      });

      this.selectedRouteDown = null;
      this.selectedVehicleDown = null;
  }

  editMorningTable(data) {
    this.scrollToTop();
    this.updateFlag = true;
    this.selectedRouteUp = data._id;
    this.previousMorningVehicleDetails["vehicle"] = data.vehicle._id;
    this.previousMorningVehicleDetails["status"] = "updated";
  }

  editEveningTable(data){
    this.scrollToTop();
    this.updateFlag = true;
    this.selectedRouteDown = data._id;
    this.previousEveningVehicleDetails["vehicle"] = data.vehicle._id;
    this.previousEveningVehicleDetails["status"] = "updated";
  }

  onMorningSelect(route){
    this.routeList.forEach(elem => {
      if(elem._id ==  route.target.value){
        let routeName = elem.name.split("-")
        this.routeList2.forEach(elem2 => {
          if(elem2.name.includes(routeName[0])){
            this.selectedRouteDown = elem2._id
          }
        })
      }
    })
  }

  onVehicleSelect(vehicle){
    this.selectedVehicleDown = vehicle.target.value
  }

  populateTable(active: boolean) {
    this.showLoader = false;
    let self = this;

    if ($.fn.DataTable.isDataTable(".exampleMapVehicle1")) {
      $(".exampleMapVehicle1").DataTable().clear().destroy();
    }

    $(".showhideTable").css("display", "block");
    const example = $(".exampleMapVehicle1").DataTable({
      data: this.morningTableData,
      columns: this.coloumns,

      bFilter: true,

      bInfo: true,
      bLengthChange: true,
      ordering: true,

      "fnRowCallback": function(nRow, aData, iDisplayIndex, iDisplayIndexFull) {
        //debugger;
        var index = iDisplayIndexFull + 1;
        $("td:first", nRow).html(index);
        return nRow;
      },


      language: {
        searchPlaceholder: "Search Here",
        oPaginate: {
          sNext: '<i class="fa fa-forward"></i>',
          sPrevious: '<i class="fa fa-backward"></i>',
          sFirst: '<i class="fa fa-step-backward"></i>',
          sLast: '<i class="fa fa-step-forward"></i>',
        },
      },
      lengthMenu: [
        [5, 10, 25, 50, -1],
        [5, 10, 25, 50, "All"],
      ],
    });
    $(".exampleMapVehicle1 tbody").on("click", "tr .editBtn", function () {
      var p = this.closest("tr");
      var data = $(".exampleMapVehicle1").DataTable().row(p).data();
      self.editMorningTable(data);
    });
    $(".exampleMapVehicle1 tbody").on("click", "tr .deleteBtn", function () {
      var p = this.closest("tr");
      var data = $(".exampleMapVehicle1").DataTable().row(p).data();
      // self.globals.confirmDialogService.confirmThis(
      //   'Delete Allocation',
      //   "Do You Want To Remove Allocation ?",
      //   function () {
      //     self.deleteAllocation(data);
      //   },
      //   function () {}
      // );
    });
  }

  populateTable1(active: boolean) {
    this.showLoader = false;
    let self = this;

    if ($.fn.DataTable.isDataTable(".example1")) {
      $(".example1").DataTable().clear().destroy();
    }

    $(".showhideTable").css("display", "block");
    const example = $(".example1").DataTable({
      data: this.eveningTableData,
      columns: this.coloumns,
      bFilter: true,

      bInfo: true,
      bLengthChange: true,
      ordering: true,

      "fnRowCallback": function(nRow, aData, iDisplayIndex, iDisplayIndexFull) {
        //debugger;
        var index = iDisplayIndexFull + 1;
        $("td:first", nRow).html(index);
        return nRow;
      },


      language: {
        searchPlaceholder: "Search Here",
        oPaginate: {
          sNext: '<i class="fa fa-forward"></i>',
          sPrevious: '<i class="fa fa-backward"></i>',
          sFirst: '<i class="fa fa-step-backward"></i>',
          sLast: '<i class="fa fa-step-forward"></i>',
        },
      },
      lengthMenu: [
        [5, 10, 25, 50, -1],
        [5, 10, 25, 50, "All"],
      ],
    });
    $(".example1 tbody").on("click", "tr .editBtn", function () {
      var p = this.closest("tr");
      var data = $(".example1").DataTable().row(p).data();
      self.editEveningTable(data);
    });
    $(".example1 tbody").on("click", "tr .deleteBtn", function () {
      var p = this.closest("tr");
      var data = $(".example1").DataTable().row(p).data();
      // self.globals.confirmDialogService.confirmThis(
      //   'Delete Allocation',
      //   "Do You Want To Remove Allocation ?",
      //   function () {
      //     self.deleteAllocation(data);
      //   },
      //   function () {}
      // );
    });
  }
}
