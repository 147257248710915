import { Component, OnInit } from "@angular/core";
import { DragulaService } from "ng2-dragula";
import { transportService } from "../transport.service";
import { NotifierService } from "angular-notifier";
import { Router } from "@angular/router";
import { OneStorage } from "src/app/GlobalDataStorage/oneStorage.service";
import { SharedService } from "src/app/dashboardO/dashboard/shared.service";

@Component({
  selector: "app-add-route",
  templateUrl: "./add-route.component.html",
  styleUrls: ["./add-route.component.scss"],
})
export class AddRouteComponent implements OnInit {
  selectedStops: any;

  route = new RouteModel();

  // shiftList = ["Morning", "Evening"];

  // routeShift;

  stopArray: any = [];
  clickEventsubscription: any;

  Vampires = "VAMPIRES";

  dropdownSettings = {};

  dropdownSettings2 = {
    singleSelection: false,
    textField: "name",
    selectAllText: "Select All",
    unSelectAllText: "UnSelect All",
    itemsShowLimit: 3,
    allowSearchFilter: true,
  };
  newData = [];
  stoppage: String;

  stopNames = [];

  stopDetails: any;

  stopOrderCount = 0;
  button: string = "Save Route";

  AllShiftList = [];

  constructor(
    private dragulaService: DragulaService,
    private serv: transportService,
    public notifier: NotifierService,
    private router: Router,
    public globals: OneStorage,
    public sharedService : SharedService,
  ) {
    // this.dragulaService.createGroup("VAMPIRES", {
    //   removeOnSpill: false,
    // } );

    this.dragulaService.dropModel("VAMPIRES").subscribe((args) => {
      this.stopArray = [];
      for (let i = 0; i < args.sourceModel.length; i++) {
        for (let j = 0; j < this.stopDetails.length; j++) {
          if (args.sourceModel[i]._id == this.stopDetails[j]._id) {
            this.stopArray.push({
              _id: this.stopDetails[j]._id,
              location: this.stopDetails[j].location,
              name: this.stopDetails[j].name,
              order: i,
            });
          }
        }
      }

      console.log("sort map", this.stopArray);
    });

    this.clickEventsubscription = this.sharedService
    .getClickEvent()
    .subscribe(() => {
      this.selectedStops = null;
      this.globals.activeSession = this.sharedService.getSession();
      this.route.session = this.globals.activeSession;
      this.serv.getStop(this.globals.activeSession).subscribe((res) => {
        this.stopDetails = res.data;
        console.log(this.stopDetails, "stops");
      });

    });

  }

  ngOnInit() {
    // this.routeShift = "0";
    this.route.session = this.globals.activeSession;

    this.button = "Save Route";
    this.dropdownSettings = {
      singleSelection: false,
      idField: "_id",
      textField: "name",
      location: "location",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 3,
      allowSearchFilter: true,
    };

    this.serv.getStop(this.globals.activeSession).subscribe((res) => {
      this.stopDetails = res.data;
      console.log(this.stopDetails, "stops");
    });

    this.serv.fetchAllShift().subscribe(
      (res) => {
        this.AllShiftList = res.msg;
      },
      (err) => {
        this.notifier.notify("error", err.error);
      }
    );

    if (localStorage.getItem("routeDetails")) {
      try {
        this.route = JSON.parse(localStorage.getItem("routeDetails"));

        console.log("route", typeof this.route.stoppage);
        this.stopArray = this.route.stoppage;
        this.selectedStops = this.route.stoppage;
        // this.routeShift = this.route.routeShift;
        this.route.session = this.route.session;

        this.stopOrderCount = Object.keys(this.route.stoppage).length;

        this.button = "Update Route";
        if(!this.route.shiftName) {
          this.route.shiftName = ""
        }

        localStorage.removeItem("routeDetails");
      } catch (e) {
        alert({msg:"this alert coming", err:e}); // error in the above string (in this case, yes)!
      }
    }
  }

  onInput(event: any) {
    const input = event.target as HTMLInputElement;
    const inputValue = input.value;
    const filteredValue = inputValue.replace(/-/g, '');
  
    if (filteredValue !== inputValue) {
      input.value = filteredValue;
    }
  }

  validateForm() {
    if (!this.route.session) {
      this.notifier.notify("error", "Please Select a Session.");
      return false;
    }

    if (
      Object.keys(this.route).length === 0 ||
      !this.route.name ||
      this.route.name.trim() == ""
    ) {
      this.notifier.notify("error", "Please Enter a Route Name.");
      return false;
    }

    return true;
  }

  resetPageDetails() {
    this.route = new RouteModel();
    this.selectedStops = [];
  }

  onItemSelect(item: any) {
    for (let j = 0; j < this.stopDetails.length; j++) {
      if (item._id == this.stopDetails[j]._id) {
        this.stopArray.push({
          _id: this.stopDetails[j]._id,
          location: this.stopDetails[j].location,
          name: this.stopDetails[j].name,
          order: this.stopOrderCount,
        });
      }
    }
    this.stopOrderCount = this.stopOrderCount + 1;

    console.log("sort map", this.stopArray);
  }
  OnItemDeSelect(item: any) {
    console.log(item);
    console.log(this.selectedStops);

    for (let j = 0; j < this.stopArray.length; j++) {
      if (item._id == this.stopArray[j]._id) {
        this.stopArray.splice(j, 1);
      }
    }

    console.log("sort map", this.stopArray);
  }
  onSelectAll(items: any) {
    this.stopArray = [];

    for (let i = 0; i < items.length; i++) {
      for (let j = 0; j < this.stopDetails.length; j++) {
        if (items[i]._id == this.stopDetails[j]._id) {
          this.stopArray.push({
            _id: this.stopDetails[j]._id,
            location: this.stopDetails[j].location,
            name: this.stopDetails[j].name,
            order: i,
          });
        }
      }
    }

    console.log("sort map", this.stopArray);
  }
  onDeSelectAll(items: any) {
    this.stopOrderCount = 0;
    this.stopArray = [];

    console.log("sort map", this.stopArray);
  }

  addRoutes() {
    if (!this.validateForm()) {
      return;
    }

    if (this.button == "Update Route") {
      // this.route.routeShift = this.routeShift;
      if (this.stopArray.length !== 0) {
        this.route.stoppage = this.stopArray;
      }
      console.log("here uodate", this.route);

      if(this.route.shiftName == "No Shift") {
        this.route.shiftName = null;
      }

      this.serv.updateRoute(this.route).subscribe(
        (res) => {
          this.notifier.notify("success", "Route Updated successfully");
          console.log("response", res);
        },
        (err) => {
          this.notifier.notify("error", "Please Try Again");
        }
      );
      localStorage.setItem("refreshTable", JSON.stringify(true));
      this.router.navigate(["/dashboard/listRoute"]);
      this.resetPageDetails();
    } else {

      if(this.stopArray.length == 0){
        this.notifier.notify("error", "Pleasen Select Stops to add in the route");
        return 
      }

      this.route.stoppage = this.stopArray;
      // this.route.shiftName =   this.route.shiftName.map(item => item.name);

      if(this.route.shiftName == "No Shift") {
        this.route.shiftName = null;
      }
      this.serv.addRoute(this.route).subscribe(
        (res) => {

          if(res.status == false){
            this.notifier.notify("info", res.msg);
          }else{
            this.notifier.notify("success", "Route added successfully");
            localStorage.setItem("refreshTable", JSON.stringify(true));
            this.resetPageDetails();
            this.router.navigate(["/dashboard/listRoute"]);
            console.log("response", res);
          }
        },
        (err) => {
          this.notifier.notify("error", "Please Try Again" , err);
        }
      );
    }
  }
  sessionChange(){
    this.serv.getStop(this.route.session).subscribe((res) => {
      this.stopDetails = res.data;
  }) 
 }
}

export class RouteModel {
  name: String;
  stoppage: object;
  // routeShift: string;
  shiftName : String;
  session ; String;
}
