import { Component, OnInit } from '@angular/core';

declare var $: any;
import { StudentService } from "../../StudentO/Services/student.service";
import { NotifierService } from "angular-notifier";
import { routeSchedulingService } from "src/app/CreateRoute/create-route.service";
import { OneStorage } from "src/app/GlobalDataStorage/oneStorage.service";
import { transportService } from '../transport.service';


@Component({
  selector: 'app-distance-report',
  templateUrl: './distance-report.component.html',
  styleUrls: ['./distance-report.component.scss']
})
export class DistanceReportComponent implements OnInit {


  tableData = [];
  columns = [];
  voucherAvailable: boolean = false;
  pdfDataObj: any;
  startDate: any;
  endDate : any;
  allVehicle: any;
  vehicleId:any = "All Vehicle";
  today: string;

  fetchDataForTable:any;
  showLoader:boolean = false;
  loadContent = "";

  constructor(
    private _studentService: StudentService,
    private globals: OneStorage,
    private _notify: NotifierService,
    private serv: transportService,
  ) { }

  ngOnInit() {
    // this.createTable();
    this.vehicleId = "0";
    this.today = new Date().toISOString().split('T')[0];

    this.serv.fetchVehicleAllData().subscribe(
      (res) => {
          this.allVehicle = res.msg;
        }
    );
  }

  createTable() {
    let currentDate = new Date(this.endDate); 
    currentDate.setDate(currentDate.getDate() + 1);
    let year = currentDate.getFullYear();
    let month = String(currentDate.getMonth() + 1).padStart(2, '0');
    let day = String(currentDate.getDate()).padStart(2, '0');
    let endDatePlus1 = `${year}-${month}-${day}`;
    if(this.vehicleId == "0"){
      return this._notify.notify("error", "Please select a vehicle");
    }
    this.showLoader = true;

    this.serv.fetchDistanceReport(this.startDate, endDatePlus1,this.vehicleId ).subscribe(
      (res) => {
        if ((res.msg).length > 0) {    
          var data = res.msg
          for (let i = 0; i < data.length; i++) {
            if (i < data.length - 1) {
              data[i].odometerReadingLast = data[i + 1].odometerReadingFirst;
              data[i].totalDifference = parseInt(data[i].odometerReadingLast) - parseInt(data[i].odometerReadingFirst);

            } else {
              data[i].odometerReadingLast = "_";
              data[i].totalDifference = 0;
            }
          }
          this.fetchDataForTable = data;
          const totalDistanceReduced = this.fetchDataForTable.reduce((accumulator, item) => accumulator + item.totalDifference, 0);

          var newRowData = {
            Date: "",
            lastStatusReceipedAtFirst: "",
            lastStatusReceipedAtLast: "",
            avgSpeed: "",
            odometerReadingFirst: "",
            odometerReadingLast: "Total Distance Covered",
            totalDifference: totalDistanceReduced + " KM"
        };
        this.fetchDataForTable.pop();
        this.fetchDataForTable.push(newRowData);
          this.populateTable();
         
        } else {
          this.fetchDataForTable = null
          this.populateTable();
        }
      },
      (error) => {
        console.log("Error", error);
      }
    );
  }

  populateTable() {
    this.showLoader = false;
    let that = this;

    this.columns = [
      { data: "Date", title: "Date", defaultContent: "<i>Not set</i>" },
      {
        data: "lastStatusReceipedAtFirst",
        title: "Start",
        defaultContent: "<i>Not set</i>",
      },
      {
        data: "lastStatusReceipedAtLast",
        title: "End",
        defaultContent: "<i>Not set</i>",
      },
      {
        data: "avgSpeed",
        title: "Avg Speed",
        defaultContent: "<i>Not set</i>",
      },
      {
        data: "odometerReadingFirst",
        title: "Odometer Start",
        defaultContent: "<i>Not set</i>",
      },
      {
        data: "odometerReadingLast",
        title: "Odometer End",
        defaultContent: "<i>Not set</i>",
      },
      {
        data: "totalDifference",
        title: "Total Distance",
        defaultContent: "<i>Not set</i>",
      },

    ];
    if ($.fn.DataTable.isDataTable(".example")) {
      $(".example").DataTable().clear().destroy();
    }
    $(".showhideTable").css("display", "block");
    const example = $(".example").DataTable({
      data: this.fetchDataForTable,
      columns: this.columns,
      columnDefs: [
        {
          visible: false,
          searchable: false,
        },
      ],

      dom: "Bfrtip",
      buttons: [
        "pageLength",
        "excel",
        {
          text: "Pdf",
          action: function () {
            generatePdf();
          },
        },
      ],
      order: [],
      lengthMenu: [
        [5, 10, 25, 50, -1],
        [5, 10, 25, 50, "All"],
      ],
      language: {
        searchPlaceholder: "Search Here",
      },
    });
    async function generatePdf() {
      that.voucherAvailable = true;
      try {
        that.fetchDataForTable.forEach((ele:any) => {
          that.columns.forEach((zz) => {
            if (!ele.hasOwnProperty(zz.data)) {
              ele[zz.data] = "Not set";
            }
          });
        });
        const inputDateString = new Date();
        const inputDate = new Date(inputDateString);

        const day = inputDate.getDate().toString().padStart(2, '0');
        const month = (inputDate.getMonth() + 1).toString().padStart(2, '0');
        const year = inputDate.getFullYear();

        const hours = inputDate.getHours().toString().padStart(2, '0');
        const minutes = inputDate.getMinutes().toString().padStart(2, '0');

        const formattedDate = `${day}/${month}/${year} ${hours}:${minutes}`;
        var title = "Report generated on  :" + "-  " + formattedDate;
        var reportName = "Distance Report";

        await that.globals
          .getPdfDataObj(that.columns, that.fetchDataForTable, title, reportName)
          .then((res) => {
            that.pdfDataObj = res;
          });
      } catch (e) {
        console.log(e);
      }
    }
  }

}
