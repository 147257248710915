// import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from "@angular/core";
import { AgmCoreModule } from '@agm/core';
import { OrderModule } from "ngx-order-pipe";
import { CommonImportsModule } from "./CommonImportsO/common-imports.module";

import { SigninSignoutOModule } from "./SigninSignoutO/SigninSignoutO.module";

import { DashboardModule } from "./dashboardO/dashboard.module";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";

// import { AddUserComponent } from './UserDetailsO/AddUser/add-user.component';6+
import { AddFineComponent } from "./AddFine/add-fine.component";

import { CreateRouteComponent } from "./CreateRoute/create-route.component";
import { FeeCollectionService } from "./CollectFeeO/GenerateReceipt/service/fee-collection.service";
import { BrowserModule } from "@angular/platform-browser";
import { FileUploadModule } from "ng2-file-upload";
import { DragulaModule, DragulaService } from "ng2-dragula";
import { NotifierModule } from "angular-notifier";
import { MapTransportComponent } from "./map-transport/map-transport.component";

import { Globals } from "./global";

import { OneStorage } from "./GlobalDataStorage/oneStorage.service";
import { SiteV2Component } from "./site-v2/site-v2.component";

import { FeeHistoryComponent } from "../app/fee-historyComponent/fee-historyComponent.component";
import { AuthGuard } from "./auth_guard/auth.guard";
import { SiteV1Component } from "./site-v1/site-v1.component";
import { DashGuard } from "./auth_guard/dash-guard.guard";
import { DashContentComponent } from "./dash-content/dash-content.component";

//import { SelectFeeTemplateComponent } from './select-fee-template/select-fee-template.component';
import { SharedModule } from "./shared/shared.module";

import { EmailVerificationComponent } from "./SigninSignoutO/email-verification/email-verification.component";
//import { AdmissionModule } from './admission/admission.module';
import { PPAdminModule } from "./ppadmin/ppadmin.module";
import { TypeOfExamComponent } from "./type-of-exam/type-of-exam.component";
//import { Sitev2Module } from './site-v2/sitev2.module';

import { PrivacyComponent } from "./privacy/privacy.component";
//import { PaymentModule } from './payment/payment.module';
import { StatusMonthWiseComponent } from "./status-month-wise/status-month-wise.component";

//import { MerchantModuleModule } from './merchant-module/merchant-module.module';
// import { ShowNewEnquiryDetailsComponent } from './admission/show-new-enquiry-details/show-new-enquiry-details.component';
import { SchoolPaymentListComponent } from "./school-payment-list/school-payment-list.component";
import { ImportStudentSchedulingExcelComponent } from "./import-student-scheduling-excel/import-student-scheduling-excel.component";
// import { NewEnquiryFormComponent } from './admission/new-enquiry-form/new-enquiry-form.component';
//import { StudentRoutingModule } from './StudentO/student-routing.module';
import { CreatefeecategorygroupComponent } from "./createfeecategorygroup/createfeecategorygroup.component";
import { TermconditionComponent } from "./termcondition/termcondition.component";
import { AboutusComponent } from "./aboutus/aboutus.component";
import { ContactusComponent } from "./contactus/contactus.component";
import { CategorywisepaireportComponent } from "./categorywisepaireport/categorywisepaireport.component";
import { RefundPolicyComponent } from "./refund-policy/refund-policy.component";
import { AppLinkComponent } from "./app-link/app-link.component";
import { AddStopComponent } from "./NewTransportO/add-stop/add-stop.component";
import { AddRouteComponent } from './NewTransportO/add-route/add-route.component';
import { AddDriverComponent } from './NewTransportO/add-driver/add-driver.component';
import { AddConductorComponent } from './NewTransportO/add-conductor/add-conductor.component';
import { AddVehicleComponent } from './NewTransportO/add-vehicle/add-vehicle.component';
import { MapRouteComponent } from './NewTransportO/map-route/map-route.component';
import { MapRouteVehicleComponent } from './NewTransportO/map-route-vehicle/map-route-vehicle.component';
import { DriverConductorVehicleAllocationComponent } from './NewTransportO/driver-conductor-vehicle-allocation/driver-conductor-vehicle-allocation.component';
import { ListRouteComponent } from './NewTransportO/list-route/list-route.component';
import { ListConductorAndDriverComponent } from './NewTransportO/list-conductor-and-driver/list-conductor-and-driver.component';
import { ListVehicleComponent } from './NewTransportO/list-vehicle/list-vehicle.component';
import { BlankContentComponent } from "./blank-content/blank-content.component";
import { StudentReportingForTransportComponent } from './NewTransportO/student-reporting-for-transport/student-reporting-for-transport.component';
import { SeatAllocationReportComponent } from './NewTransportO/seat-allocation-report/seat-allocation-report.component';
import { TrackSchoolBusComponent } from './NewTransportO/track-school-bus/track-school-bus.component';
import { OverSpeedDetailsComponent } from './NewTransportO/over-speed-details/over-speed-details.component';
import { DistanceReportComponent } from './NewTransportO/distance-report/distance-report.component';
import { TransportExpensePrivateComponent } from './NewTransportO/transport-expense-private/transport-expense-private.component';
import { TransportExpenseCommercialComponent } from './NewTransportO/transport-expense-commercial/transport-expense-commercial.component';
import { MailConfigComponent } from './NewTransportO/mail-config/mail-config.component';
import { AddHelperComponent } from './NewTransportO/add-helper/add-helper.component';
import { ExpenseListComponent } from './NewTransportO/expense-list/expense-list.component';
import { StudentAllocationReportComponent } from './NewTransportO/student-allocation-report/student-allocation-report.component';
import { AddVehicleIncomeComponent } from './NewTransportO/add-vehicle-income/add-vehicle-income.component';
import { EditVehicleIncomeComponent } from './NewTransportO/edit-vehicle-income/edit-vehicle-income.component';
import { EditExpenseComponent } from './NewTransportO/edit-expense/edit-expense.component';
import { AddExpenseComponent } from './NewTransportO/add-expense/add-expense.component';
import { StudentAttendanceReportComponent } from './NewTransportO/student-attendance-report/student-attendance-report.component';
import { AddShiftComponent } from './NewTransportO/add-shift/add-shift.component';
import { CapacityShiftWiseComponent } from './NewTransportO/capacity-shift-wise/capacity-shift-wise.component';
import { StoppageHistoryReportComponent } from './NewTransportO/stoppage-history-report/stoppage-history-report.component';
import { TransportExpenseCommercialInstallmentwiseComponent } from './NewTransportO/transport-expense-commercial-installmentwise/transport-expense-commercial-installmentwise.component';
// import { NasoHomeComponent } from './naso/naso-home/naso-home.component';
// import { NasoRegistrationComponent } from './naso/naso-registration/naso-registration.component';

@NgModule({
  imports: [
    CommonImportsModule,
    SigninSignoutOModule,
    DashboardModule,
    AppRoutingModule,
    BrowserModule,
    FileUploadModule,
    SharedModule,
    DragulaModule,
    PPAdminModule,
    OrderModule,
    NotifierModule.withConfig({
      position: {
        vertical: { position: "top", distance: 55 },
        horizontal: { position: "right", distance: 12 },
      },
      behaviour: { onMouseover: "pauseAutoHide", stacking: 1, autoHide: 2000 },
    }),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCMW4jVY0ZIfAD-hzXpZkFqWf_4gKy9hSc',
      libraries: ['places']
    })
  ],
  declarations: [
    AppComponent,
    SiteV1Component,
    PageNotFoundComponent,
    AddFineComponent,
    CreateRouteComponent,
    MapTransportComponent,
    FeeHistoryComponent,
    SiteV2Component,
    DashContentComponent,
    BlankContentComponent,
    TypeOfExamComponent,
    EmailVerificationComponent,
    PrivacyComponent,
    AppLinkComponent,
    StatusMonthWiseComponent,
    SchoolPaymentListComponent,
    ImportStudentSchedulingExcelComponent,
    CreatefeecategorygroupComponent,
    TermconditionComponent,
    AboutusComponent,
    ContactusComponent,
    CategorywisepaireportComponent,
    RefundPolicyComponent,
    AddStopComponent,
    AddRouteComponent,
    AddDriverComponent,
    AddConductorComponent,
    AddVehicleComponent,
    MapRouteComponent,
    MapRouteVehicleComponent,
    DriverConductorVehicleAllocationComponent,
    ListRouteComponent,
    ListConductorAndDriverComponent,
    ListVehicleComponent,
    StudentReportingForTransportComponent,
    SeatAllocationReportComponent,
    TrackSchoolBusComponent,
    OverSpeedDetailsComponent,
    DistanceReportComponent,
    TransportExpensePrivateComponent,
    TransportExpenseCommercialComponent,
    MailConfigComponent,
    AddHelperComponent,
    ExpenseListComponent,
    StudentAllocationReportComponent,
    AddVehicleIncomeComponent,
    EditVehicleIncomeComponent,
    EditExpenseComponent,
    AddExpenseComponent,
    StudentAttendanceReportComponent,
    AddShiftComponent,
    CapacityShiftWiseComponent,
    StoppageHistoryReportComponent,
    TransportExpenseCommercialInstallmentwiseComponent
    // NasoHomeComponent,
    // NasoRegistrationComponent,
  ],
  providers: [
    FeeCollectionService,
    AuthGuard,
    DashGuard,
    OneStorage,
    Globals,
    DragulaService,
  ],

  bootstrap: [AppComponent],
})
export class AppModule {}
