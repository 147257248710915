import { Component, OnInit } from "@angular/core";
import { NotifierService } from "angular-notifier";
import { vehicleDetails, VehicleModel, shiftAndCapacity } from "../Models/vehicle";
import { transportService } from "../transport.service";
import { Router } from "@angular/router";

declare var $: any;

@Component({
  selector: "app-add-vehicle",
  templateUrl: "./add-vehicle.component.html",
  styleUrls: ["./add-vehicle.component.scss"],
})
export class AddVehicleComponent implements OnInit {
  vehicle = new VehicleModel();
  certificate: any;
  date: any;
  fileToUpload: any;
  showErrorMessage: string = "";

  vehicleDetails = new vehicleDetails();
  button: string;

  isCorrect = false;
  customGpsType:any;
  tableRowArr = [new shiftAndCapacity()];
  AllShiftList = [];

  constructor(
    private serv: transportService,
    public notifier: NotifierService,
    private router: Router
  ) {}

  ngOnInit() {
    this.vehicle.purchaseType = "New";
    this.vehicle.status = "In Use";
    this.vehicle.vehicleCategory = "Commercial"
    this.vehicle.VehicleType = "Bus"

    this.button = "Add Vehicle";
    this.fetchShiftData();

    if (localStorage.getItem("vehicleDetail")) {
      try {
        this.vehicle = JSON.parse(localStorage.getItem("vehicleDetail"));
        if(this.vehicle.shiftAndCapacity.length > 0) {
          this.tableRowArr =   this.vehicle.shiftAndCapacity;

        } else {
          this.tableRowArr = [new shiftAndCapacity()];
        }

        this.button = "Update vehicle";

        localStorage.removeItem("vehicleDetail");
      } catch (e) {
        alert(e); // error in the above string (in this case, yes)!
      }
    }
  }

  onFileChange(event) {
    this.fileToUpload = event.target.files[0];
    console.log("filename", this.fileToUpload);
    if (
      this.fileToUpload &&
      (this.fileToUpload.name.toLowerCase().includes("jpg") ||
        this.fileToUpload.name.includes("jpeg") ||
        this.fileToUpload.name.includes("jfif") ||
        this.fileToUpload.name.includes("pjpeg") ||
        this.fileToUpload.name.includes("pjp") ||
        this.fileToUpload.name.includes("png") ||
        this.fileToUpload.name.includes("svg"))
    ) {
      this.serv.saveId(this.fileToUpload);
    } else {
      this.fileToUpload = null;
      this.notifier.notify("error", "Please select only image file");
    }
  }

  validateForm() {
    const currentDate = new Date();

    if (
      Object.keys(this.vehicle).length === 0 ||
      !this.vehicle.registrationNumber ||
      this.vehicle.registrationNumber.trim() == ""
    ) {
      this.notifier.notify("error", "Please Enter a Registration Number.");
      return false;
    }

    // if(!this.isCorrect){
    //   this.notifier.notify("error", "Please Enter Valid Registration Number.");
    //   return false;
    // }

    // if (!this.vehicle.modelNumber) {
    //   this.notifier.notify("error", "Please Enter Model Number.");
    //   return false;
    // }

    // Future Date Validation
    if (new Date(this.vehicle.registrationDate) > currentDate) {
      return this.notifier.notify("error", "Future Date For Registration Date is not allowed");
    }
    if (new Date(this.vehicle.manufactureDate) > currentDate) {
      return this.notifier.notify("error", "Future Date For Manufacture Date is not allowed");
    }

    if (new Date(this.vehicle.purchaseDate) > currentDate) {
      return this.notifier.notify("error", "Future Date For Purchase Date is not allowed");
    }

    if (!this.vehicle.insuranceValidity) {
      return this.notifier.notify("error", "Please Select Insurance Validity Date.");

    } else if(new Date(this.vehicle.insuranceValidity) < currentDate) {
      return this.notifier.notify("error", "Past Date For Insurance Validity is not allowed");
    }

    if (!this.vehicle.fitnessValidity) {
      return this.notifier.notify("error", "Please Select Fitness Validity Date.");

    } else if(new Date(this.vehicle.fitnessValidity) < currentDate) {
      return this.notifier.notify("error", "Past Date For Fitness Validity is not allowed");
    }

    if (!this.vehicle.polutionValidity) {
     return this.notifier.notify("error", "Please Select Pollution Validity Date.");

    } else if(new Date(this.vehicle.polutionValidity) < currentDate) {
      return this.notifier.notify("error", "Past Date For Pollution Validity is not allowed");
    }

    if (!this.vehicle.VehicleType) {
      this.notifier.notify("error", "Please Select Vehicle Type.");
      return false;
    }

    if (!this.vehicle.vehicleCategory) {
      this.notifier.notify("error", "Please Select Vehicle Category.");
      return false;
    }

    if (this.vehicle.gpsDeviceId) {
      if (!this.vehicle.gpsType) {
        this.notifier.notify("error", "Please Select a GPS Type.");
        return false;
      } 

      if (!this.vehicle.gpsStatus) {
        this.notifier.notify("error", "Please Select GPS Status.");
        return false;
      } 

      if (this.vehicle.gpsType == "Others") {
        if (!this.customGpsType) {
          this.notifier.notify("error", "Please Enter Other GPS Type.");
          return false;
        } 
        this.vehicle.gpsType = this.customGpsType;

        if (!this.vehicle.gpsStatus) {
          this.notifier.notify("error", "Please Select GPS Status.");
          return false;
        }
      } 
    }

    // if (!this.vehicle.VehicleType) {
    //   this.notifier.notify("error", "Please Select Vehicle Type.");
    //   return false;
    // }

    // if (!this.vehicle.seatingCapacity) {
    //   this.notifier.notify("error", "Please Select Seating Capacity.");
    //   return false;
    // }

    // if (!this.vehicle.mileage) {
    //   this.notifier.notify("error", "Please Enter Vehicle mileage.");
    //   return false;
    // }

    // if (!this.vehicle.fuelCapacity) {
    //   this.notifier.notify("error", "Please Enter Fuel Capacity.");
    //   return false;
    // }

    if (!this.vehicle.status) {
      this.notifier.notify("error", "Please Select Vehicle Status.");
      return false;
    }

    for (let i = 0; i < this.tableRowArr.length; i++) {
      let trow = this.tableRowArr[i];

      if(trow.shiftName)
      {
        if (!trow.shiftName) {
          return this.notifier.notify("error", `Please Select Shift`);
        }

        if (trow.shiftCapacity === undefined || trow.shiftCapacity === null) {
          return this.notifier.notify("error", `Please Enter Seat Capacity For Blank Field`);
        }
      }
      
      const duplicateIndex = this.tableRowArr.findIndex((row, index) => index !== i && row.shiftName === trow.shiftName);
      if (duplicateIndex !== -1) {
        return this.notifier.notify("error", `Shift '${trow.shiftName}' is already selected.`);
      }

    }

    return true;
  }

  resetPageDetails() {
    this.vehicle = new VehicleModel();
    this.vehicle.status = "In Use";
    this.isCorrect = false;
    this.tableRowArr = [new shiftAndCapacity()];
    this.vehicleDetails = new vehicleDetails();
  }

  // validateGpsId(){
  //   this.serv.validateGps(this.vehicle.gpsDeviceId).subscribe( (res) => {
  //     console.log("response form gps validation", res)

  //     if(!res.status){
  //       this.notifier.notify("error", "GPS Id entered is not correct");
  //       return false;
  //     }else{
  //       return true;
  //     }
  //   })
  // }

  checkVehicleStatus(){

    this.serv.validateVehicleToBeAdded(this.vehicle.registrationNumber).subscribe((res)=> {
      if(res.status){
        this.isCorrect = true;
        this.vehicle.gpsDeviceId = res.gpsId
      }else{
        this.isCorrect = false;
      }
    })
  }

  addVehicle() {
    if (!this.validateForm()) {
      return;
    }

    if (this.button == "Update vehicle") {
      this.vehicle.shiftAndCapacity = this.tableRowArr
      this.serv.updateVehicle(this.vehicle).subscribe(
        (res) => {
          this.notifier.notify("success", "Vehicle Updated successfully");
          console.log("response", res);
          this.router.navigate(["/dashboard/listVehicle"]);
          this.resetPageDetails();
        },
        (err) => {
          this.notifier.notify("error", "Please Try Again");
        }
      );
      localStorage.setItem("refreshTable", JSON.stringify(true));
    } else {
      // console.log("vehicle", this.vehicle);
      this.vehicle.shiftAndCapacity = this.tableRowArr

      this.serv.saveVehicle(this.vehicle).subscribe(
        (res) => {
          console.log("res", "vehicle added", res);
          if (res.status) {
            this.notifier.notify("success", res.msg);
            this.resetPageDetails();
          } else {
            this.notifier.notify("error", res.msg);
          }
        },
        (err) => {
          this.notifier.notify("error", "Please Try Again");
        }
      );
    }
  }

  onAddDocument() {
    const currentDate = new Date();

    if (new Date(this.vehicleDetails.issuedAt) > currentDate) {
      return this.notifier.notify("error", "Future Date For Issue Date is not allowed");
    }

    if (new Date(this.vehicleDetails.expiredAt) < currentDate) {
      return this.notifier.notify("error", "Past Date For Expiry Date is not allowed");
    }

    this.serv.saveImage(this.fileToUpload).subscribe((res) => {
      this.vehicleDetails.certificate = res.data.url;
      this.vehicle.vehicleDetails.push(this.vehicleDetails);
    });
  }
  
  parseDate(dateString: string): Date {
    let dateVar: any = null;
    if (dateString) {
      dateVar = new Date(dateString);
    }
    console.log(dateVar);
    return dateVar;
  }

  deleteRow(index) {
    // if (index != 0) {
    //   let newArr = this.tableRowArr;
    //   newArr.splice(index, 1);
    //   this.tableRowArr = newArr;
    // }

    let newArr = this.tableRowArr;
    newArr.splice(index, 1);
    this.tableRowArr = newArr;
  }

  addRow() {    
    let lastObj = this.tableRowArr[this.tableRowArr.length - 1]
    if (lastObj.shiftName && lastObj.shiftCapacity) {
      this.tableRowArr.push(new shiftAndCapacity());

    } else {
      this.notifier.notify("error", "Empty fields not allowed")
    }

  }

  fetchShiftData() {
    // this.showLoader = true;
    this.serv.fetchAllShift().subscribe(
      (res) => {
        this.AllShiftList = res.msg;
      },
      (err) => {
        this.notifier.notify("error", err.error);
      }
    );
  }


}
