import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class SharedService {
  session : string;
  constructor() {}

  private subject = new Subject<any>();
  sendClickEvent(session) {
    this.session = session
    this.subject.next();
  }

  getSession(){
    return this.session
  }

  getClickEvent(): Observable<any> {
    return this.subject.asObservable();
  }
}
