import { Component, OnInit, Input } from '@angular/core';


@Component({
  selector: 'app-page-loader2',
  templateUrl: './page-loader2.component.html',
  styleUrls: ['./page-loader2.component.scss']
})
export class PageLoader2Component implements OnInit {
  @Input() public loderContent:any
  constructor() { }

  ngOnInit() {
  }
}
