import { Component, OnInit } from '@angular/core';
import { NotifierService } from "angular-notifier";
import { transportService } from "../transport.service";
import { Router } from "@angular/router";
import { ExpenseModel  } from "../Models/Expense";

@Component({
  selector: 'app-edit-expense',
  templateUrl: './edit-expense.component.html',
  styleUrls: ['./edit-expense.component.scss']
})
export class EditExpenseComponent implements OnInit {
  imageFlag = false;
  showErrorMessage: string = "";
  billImage: any;
  fileToUpload : any;

  session:any;
  vehicleId:any;
  expense = new ExpenseModel();
  constructor(
    public notifier: NotifierService,
    private serv: transportService,
    public _route: Router,
  ) { }

  ngOnInit() {
    try {
      this.expense = JSON.parse(localStorage.getItem("ExpenseLocal"));
      if(this.expense.billImage.length > 0) {
        this.expense.billImage= this.expense.billImage;
        // let result = url.match(/^https:\/\/d3nwkpv8dwssuq\.cloudfront\.net\/(.*)$/);
        // this.expense.billImage = result[1];
        this.imageFlag = true;
      }
      // localStorage.removeItem("ExpenseLocal");
    } catch (e) {
      // alert(e);
    }
  }

    editExpense() {
      console.log("here is updated payload.......",this.expense )
    this.serv.updateExenseByIncharge(this.expense).subscribe(
      (res) => {
        this.notifier.notify("success", "Updated successfully");
        console.log("response", res);
        this._route.navigate(["/dashboard/expenseList"]);
      },
      (err) => {
        this.notifier.notify("error", "Please Try Again");
      }
    );
  }

  fuelTypeChange() {
    this.expense.billType == "Fuel";
  }

  removeFilePic() {
    this.imageFlag = false;
    this.billImage = null;
    this.expense["billImage"] = null
  }

  onAddDocument(fileName) {
    this.serv.saveImage(this.fileToUpload).subscribe((res) => {
      this.expense[`${fileName}`] = res.data.url;
      if(fileName == "billImage"){
        this.imageFlag = true;
      }
      console.log("res", this.expense)
    });
  }

  onFileChange(event) {
    this.fileToUpload = event.target.files[0];
    if (
      this.fileToUpload &&
      (this.fileToUpload.name.toLowerCase().includes("jpg") ||
        this.fileToUpload.name.includes("jpeg") ||
        this.fileToUpload.name.includes("jfif") ||
        this.fileToUpload.name.includes("pjpeg") ||
        this.fileToUpload.name.includes("pjp") ||
        this.fileToUpload.name.includes("png") ||
        this.fileToUpload.name.includes("svg"))
    ) {
      this.serv.saveId(this.fileToUpload);
      this.onAddDocument(event.target.name)
    } else {
      this.fileToUpload = null;
      this.notifier.notify("error", "Please select only image file");
    }
  }

}
