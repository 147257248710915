import { Component, OnInit } from '@angular/core';
import { PaymentListService } from './paymentList.service';
import { Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';

declare var $: any;


@Component({
  selector: 'app-school-payment-list',
  templateUrl: './school-payment-list.component.html',
  styleUrls: ['./school-payment-list.component.scss']
})
export class SchoolPaymentListComponent implements OnInit {

  constructor(public  paymentListService: PaymentListService, private router: Router, private notify: NotifierService,) { }

  transactionList=[];
  coloumns=[];
  startDate = new Date().toISOString().substr(0, 10);
  endDate = new Date().toISOString().substr(0, 10);

  ngOnInit() {

    let action_btn = 'No Update';

    this.coloumns=[   
      { data: 'studentName', defaultContent: '<i>-</i>',title:'Student' },
      { data: 'classSection', defaultContent: '<i>-</i>',title:'Class' },
      { data: 'father', defaultContent: '<i>-</i>',title:'Father' },
      { data: 'contactNo', defaultContent: '<i>-</i>',title:'Contact' },
      { data: 'payObjName', defaultContent: '<i>-</i>',title:'Particular' },
      { data: 'amount', defaultContent: '<i>-</i>',title:'Amount' },
      { data: 'paymentId', defaultContent: '<i>-</i>',title:'Payment Id' }, 
      { data: 'payStatus', defaultContent: '<i>-</i>',title:'Status' },
      { data: 'createdAt', defaultContent: '<i>-</i>',title:'Date' },
      { data: 'rzpId', defaultContent: '<i>-</i>',title:'RazorPay Id' },

      { data: 'paycontact', defaultContent: '<i>-</i>',title:'Payer Contact' },

      { data: 'payemail', defaultContent: '<i>-</i>',title:'Payer Email' },
      {
        data: 'paymentUpdate',
        className: 'center',
        defaultContent: action_btn
      },  
    ]
    this.getSchoolList();
  }

  getSchoolList(){
    this.paymentListService.getUserTransactionsList(this.startDate, this.endDate)
    .subscribe(res=>{

      console.log("transactionList", res)
      this.transactionList=res.transactionList;
      // console.log("transactionList",this.transactionList)
      this.populateTable(true);
      });
  }

  populateTable(active:boolean) {
    let self=this;
    if ($.fn.DataTable.isDataTable(".example")) {
      $('.example').DataTable().clear().destroy();
    };
    $('.showhideTable').css('display', 'block');
    const example = $('.example')
      .DataTable({
        data: this.transactionList,
        columns: this.coloumns ,
        scrollX: true,
        dom: 'Bfrtip',
        buttons: ['copy', 'csv', 'pdf', 'print', {
          extend: 'excelHtml5',
          title: 'Transaction',
          customizeData: function(data) {
            for(var i = 0; i < data.body.length; i++) {
              for(var j = 0; j < data.body[i].length; j++) {
                data.body[i][j] = '\u200C' + data.body[i][j];
              }
            }
          },      
          orientation: 'landscape'
        }],
        lengthMenu: [[10, 25, 50, -1], [10, 25, 50, 'All']]
      });
      $('.example').on('click', 'a.Update', function () {
        console.log("clicked............");
       var p = this.closest('tr');
       var data = $('.example').DataTable().row(p).index();
       self.updateOnlinePayment(self.transactionList[data].paymentId);
     });
  }

  updateOnlinePayment(id:any){

    console.log("paymentId", id);

    var obj={}

    obj["paymentId"] = id;

    this.paymentListService.updateOnlinePaymentStatus(obj).subscribe(
      res =>{
        console.log("res",res);
        this.notify.notify("info", 'Successful Updated');
      }
    )
  }

  

}
