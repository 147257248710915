import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { transportService } from '../transport.service';
import { OneStorage } from "../../GlobalDataStorage/oneStorage.service";
import { SharedService } from "src/app/dashboardO/dashboard/shared.service";


declare var $: any;

@Component({
  selector: 'app-list-route',
  templateUrl: './list-route.component.html',
  styleUrls: ['./list-route.component.scss']
})
export class ListRouteComponent implements OnInit {

  coloumns;
  routeDetails;
  refreshTable: any;
  showLoader:boolean = false;
  loadContent = "";
  clickEventsubscription: any;

  constructor(
    private serv: transportService,
    public notifier: NotifierService,
    public globals: OneStorage,
    public sharedService : SharedService,

    private router: Router) {
      this.clickEventsubscription = this.sharedService
      .getClickEvent()
      .subscribe(() => {
        this.globals.activeSession = this.sharedService.getSession();
        this.ngOnInit();
      });
     }

  ngOnInit() {
    this.showLoader = true;



    let action_btn_edit =
      '<span class="editBtn"><i title="Edit" style="padding-left:10px;position:pointer; cursor:pointer;"><img src="../../../assets/dash/pencil.svg"> </i></span>'
      //  <span class="deleteBtn"><i style="padding-left:10px;position:pointer;" title="delete"><img src="../../../assets/dash/Group45993.svg"></i></span> ';

    this.coloumns = [

      {
        defaultContent: "<i>Not Set</i>",
        title: "S. No.",
        // render: function (data, type, full, meta) {
        //   return i++;
        // },
      },
      { data: "session", defaultContent: "<i>Not Set</i>", title: "Session" },
    
      { data: "name", defaultContent: "<i>Not Set</i>", title: "Route Name" },
      {
        data: "stoppage.length",
        defaultContent: "<i>Not Set</i>",
        title: "Total Stops",
      },
      {
        data: "stopDetails",
        defaultContent: "<i>Not Set</i>",
        title: "Stops Details",
      },
      {
        data: "shiftName",
        defaultContent: "<i>Not Set</i>",
        title: "Shift",
      },
      {
        data: "routeShift",
        defaultContent: "<i>Not Set</i>",
        title: "Route Shift",
      },
      {
        data: null,
        className: "",
        render: function (data) {
          return action_btn_edit;
        },
        defaultContent: action_btn_edit,
      },
    ];

    this.serv.getRoute(this.globals.activeSession).subscribe((res) => {
      this.routeDetails = res.data;
      this.populateTable(true)
    });
    

    if (localStorage.getItem("refreshTable")) {
      try {
        this.refreshTable = JSON.parse(localStorage.getItem("refreshTable"));


        this.serv.getRoute(this.globals.activeSession).subscribe((res) => {

      
          this.routeDetails = res.data;
          this.populateTable(true)
    
          console.log("data", this.routeDetails)
        });
        localStorage.removeItem("refreshTable");
      } catch (e) {
        alert(e); // error in the above string (in this case, yes)!
      }
    }else{
      this.serv.getRoute(this.globals.activeSession).subscribe((res) => {

      
        this.routeDetails = res.data;
        this.populateTable(true)
  
        console.log("data", this.routeDetails)
      });
    }

  }

  editRoute(data){
    localStorage.setItem("routeDetails",JSON.stringify(data));
    this.router.navigate(['/dashboard/addRoute']);
  }

  populateTable(active: boolean) {
    this.showLoader = false;
    let self = this;
    if ($.fn.DataTable.isDataTable(".table-display")) {
      $(".table-display").DataTable().clear().destroy();
    }
    $(".showhideTable").css("display", "block");
    const example = $(".table-display").DataTable({
      data: this.routeDetails,
      columns: this.coloumns,
      // dom: 'Bfrtip',
      //  buttons: []

      ordering: true,

      "fnRowCallback": function(nRow, aData, iDisplayIndex, iDisplayIndexFull) {
        //debugger;
        var index = iDisplayIndexFull + 1;
        $("td:first", nRow).html(index);
        return nRow;
      },
      
      language: {
        searchPlaceholder: "Search Here",
      },
      lengthMenu: [
        [10, 25, 50, -1],
        [10, 25, 50, "All"],
      ],
    });
    $(".table-display tbody").on("click", "tr .editBtn", function () {
      var p = this.closest("tr");
      var data = $(".table-display").DataTable().row(p).data();
      console.log("clicked............", data);
      // self.button = "Update";
      self.editRoute(data);
    });
    $(".table-display tbody").on("click", "tr .deleteBtn", function () {
      var p = this.closest("tr");
      var data = $(".table-display").DataTable().row(p).data();
      console.log("clicked............", data.name);
    //   self.globals.confirmDialogService.confirmThis(
    //     `Delete Stop : ${data.name}`,
    //     "Do You Want To Delete Stop ?",
    //     function () {
    //       self.deleteStop(data._id);
    //     },
    //     function () {}
    //   );
    });
  }

}
