import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  NgZone,
} from "@angular/core";
import { transportService } from "../transport.service";
import { NotifierService } from "angular-notifier";
import { MapsAPILoader } from "@agm/core";
import { ConfirmDialogService } from "src/app/shared/confirm-dialog/confirm-dialog.service";
import { OneStorage } from "src/app/GlobalDataStorage/oneStorage.service";
import { SharedService } from "src/app/dashboardO/dashboard/shared.service";

declare var $: any;

@Component({
  selector: "app-add-stop",
  templateUrl: "./add-stop.component.html",
  styleUrls: ["./add-stop.component.scss"],
})
export class AddStopComponent implements OnInit {
  disableScroll(event: Event): void {
    event.preventDefault();
  }
  button = "Save";
  latitude = 28.539339;
  longitude = 77.295372;
  initialZoom = 5;
  clickEventsubscription: any;

  address: string;

  stopId: string;

  fullAddress: string;

  showLocation;

  stop = new StoppageModel();
  stopList: [];
  coloumns = [];
  private geoCoder;
  
  showLoader:boolean = false;
  loadContent = "";
  @ViewChild("search", { static: true }) mapElement: ElementRef;

  constructor(
    private notifier: NotifierService,
    private serv: transportService,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    public confirmDialogService: ConfirmDialogService,
    public globals: OneStorage,
    public sharedService : SharedService,
  ) {
    this.clickEventsubscription = this.sharedService
    .getClickEvent()
    .subscribe(() => {
      this.globals.activeSession = this.sharedService.getSession();
      this.ngOnInit();
    });
  }

  ngOnInit() {
    this.showLocation = false;
    this.stop.session = this.globals.activeSession;


    let action_btn_edit =
      '<span class="editBtn"><i style="padding-left:10px;position:pointer; cursor: pointer;" title="Edit"><img src="../../../assets/dash/pencil.svg"> </i></span>';

      // let action_btn_edit =
      // '<span class="editBtn"><i style="padding-left:10px;position:pointer; cursor: pointer;" title="Edit"><img src="../../../assets/dash/pencil.svg"> </i></span> <span class="deleteBtn"><i style="padding-left:10px;position:pointer; cursor:pointer;" title="Delete"><img src="../../../assets/dash/Group45993.svg"></i></span> ';
  
    var i = 1;

    this.coloumns = [
      {
        defaultContent: "<i>Not Set</i>",
        title: "S. No.",
        // render: function (data, type, full, meta) {
        //   return i++;
        // },
      },
      { data: "session", defaultContent: "<i>Not Set</i>", title: "Session" },

      { data: "name", defaultContent: "<i>Not Set</i>", title: "Stop Name" },
      {
        data: "fullAddress",
        defaultContent: "<i>Not Set</i>",
        title: "Stop Address",
      },
      { data: "amount", defaultContent: "<i>Not Set</i>", title: "Stop Fees" },
      {
        data: null,
        className: "",
        render: function (data) {
          return action_btn_edit;
        },
        defaultContent: action_btn_edit,
      },
    ];
    this.getStop();
  }

  ngAfterViewInit() {
    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder();

      let autocomplete = new google.maps.places.Autocomplete(
        this.mapElement.nativeElement
      );
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.initialZoom = 12;

          this.stop.location.lat = this.latitude;
          this.stop.location.lng = this.longitude;

          console.log("location", this.latitude, this.longitude);
        });
      });
    });
  }

  private setCurrentLocation() {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.initialZoom = 8;
        this.getAddress(this.latitude, this.longitude);
      });
    }
  }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode(
      { location: { lat: latitude, lng: longitude } },
      (results, status) => {
        if (status === "OK") {
          if (results[0]) {
            this.initialZoom = 12;
            this.address = results[0].formatted_address;
          } else {
            window.alert("No results found");
          }
        } else {
          window.alert("Geocoder failed due to: " + status);
        }
      }
    );
  }

  onMapReady(map?: google.maps.Map) {
    console.log("dogs");
    if (map) {
      console.log("dogs");
      map.setOptions({
        streetViewControl: true,
        fullscreenControl: true,
      });
    }
  }

  markerDragEnd($event: any) {
    console.log($event);

    this.showLocation = true;
    this.fullAddress = "";
    this.latitude = $event.coords.lat;
    this.longitude = $event.coords.lng;

    this.stop.location.lat = this.latitude;
    this.stop.location.lng = this.longitude;
    this.getAddress(this.latitude, this.longitude);
  }

  validateForm() {
    if (!this.stop.session) {
      this.notifier.notify("error", "Please Select a Session");
      return false;
    }
    if (
      Object.keys(this.stop).length === 0 ||
      !this.stop.name ||
      this.stop.name.trim() == ""
    ) {
      this.notifier.notify("error", "Please Enter a Name.");
      return false;
    }
    if (!this.stop.amount) {
      this.notifier.notify("error", "Please Enter stop amount.");
      return false;
    }
    if (Object.keys(this.stop.location).length === 0 || !this.stop.location) {
      this.notifier.notify("error", "Please Select Location on the Map.");
      return false;
    }
    // if (Object.keys(this.stop.address).length === 0 || !this.stop.address) {
    //   this.notifier.notify("error", "Please Enter Address.");
    //   return false;
    // }

    return true;
  }

  addNewStop() {
    if (!this.validateForm()) {
      return;
    }

    if (this.button === "Save") {
      console.log("this.stop", this.stop);
      this.serv.addStop(this.stop).subscribe(
        (res) => {
          this.notifier.notify("success", "Stop added successfully");
          this.getStop();
          this.resetPageDetails();
          console.log("response", res);
        },
        (err) => {
          this.notifier.notify("error", "Please Try Again");
        }
      );
    } else {
      this.showLoader = true;
      console.log("rrew", this.button, this.stop, this.stopId);

      this.serv.updateStop(this.stop, this.stopId).subscribe(
        (res) => {
          this.notifier.notify("success", "Stop Updated successfully");
          this.getStop();
          this.button = "Save";
          this.resetPageDetails();
          console.log("response", res);
        },
        (err) => {
          this.notifier.notify("error", "Please Try Again");
        }
      );
    }
  }

  resetPageDetails() {
    console.log("came here");
    var session = this.stop.session
    this.stop = new StoppageModel();
    this.stop.session = session;
    this.address = "";
    this.latitude = null;
    this.longitude = null;
    console.log("this.stop", this.stop);
  }

  editStop(data) {
    console.log("data", data);
    this.stopId = data._id;
    this.stop.name = data.name;
    this.stop.amount = data.amount;
    this.stop.address = data.address;
    this.stop.session = data.session;
    this.stop.location = data.location;

    this.fullAddress = data.fullAddress;

    this.latitude = data.location.lat;
    this.longitude = data.location.lng;
  }

  getStop() {
    this.serv.getStopNew(this.globals.activeSession).subscribe((res) => {
      console.log("response", res);
      this.stopList = res.data;
      this.populateTable(true);
    });
  }

  deleteStop(id) {
    this.serv.deleteStop(id).subscribe(
      (res) => {
        this.notifier.notify("success", "Stop Deleted successfully");
        this.getStop();
        console.log("response", res);
      },
      (err) => {
        this.notifier.notify("error", "Please Try Again");
      }
    );
  }

  populateTable(active: boolean) {
    let self = this;
    if ($.fn.DataTable.isDataTable(".table-displayStoppage")) {
      $(".table-displayStoppage").DataTable().clear().destroy();
    }
    $(".showhideTable").css("display", "block");
    const example = $(".table-displayStoppage").DataTable({
      data: this.stopList,
      columns: this.coloumns,
      // dom: 'Bfrtip',
      //  buttons: []

      ordering: true,

      fnRowCallback: function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
        //debugger;
        var index = iDisplayIndexFull + 1;
        $("td:first", nRow).html(index);
        return nRow;
      },

      language: {
        searchPlaceholder: "Search Here",
      },
      lengthMenu: [
        [5, 10, 25, 50, -1],
        [5, 10, 25, 50, "All"],
      ],
    });
    $(".table-displayStoppage tbody").on("click", "tr .editBtn", function () {
      var p = this.closest("tr");
      var data = $(".table-displayStoppage").DataTable().row(p).data();
      console.log("clicked............", data);
      self.button = "Update";
      self.showLocation = true;

      self.editStop(data);
      window.scrollTo(0, 0);
      var name = $("#stopname");
      name.focus();
    });
    // $(".table-displayStoppage tbody").on("click", "tr .deleteBtn", function () {
    //   var p = this.closest("tr");
    //   var data = $(".table-displayStoppage").DataTable().row(p).data();
    //   console.log("clicked............", data.name);
    //   self.globals.confirmDialogService.confirmThis(
    //     `Delete Stop : ${data.name}`,
    //     "Do You Want To Delete Stop ?",
    //     function () {
    //       self.deleteStop(data._id);
    //     },
    //     function () {}
    //   );
    // });
    self.showLoader = false;
  }
}

export class StoppageModel {
  name: string;
  location = new LatLngLiteral();
  address = new Address();
  amount: string;
  session: string;
}

export class Address {
  street: string;
  area: string;
  city: string;
  pinCode: string;
}

export class LatLngLiteral {
  lat: number;
  lng: number;
}
