export class ExpenseModel {
    billType : string;
    fuelType : string;
    fuelQuantity : number;
    amount : number;
    title : string;
    description : string;
    _id: string;
    billImage: any;
    vehicle : string;
    createdAt:Date;
    driverSourceId: string;
    session: string;
}
