import { Component, OnInit } from '@angular/core';
declare var $: any;
import { NotifierService } from "angular-notifier";
import { OneStorage } from "src/app/GlobalDataStorage/oneStorage.service";
import { transportService } from '../transport.service';
@Component({
  selector: 'app-transport-expense-commercial',
  templateUrl: './transport-expense-commercial.component.html',
  styleUrls: ['./transport-expense-commercial.component.scss']
})
export class TransportExpenseCommercialComponent implements OnInit {

  tableData = [];
  columns = [];
  voucherAvailable: boolean = false;
  pdfDataObj: any;
  session:any;
  dateNow = new Date().toISOString().substr(0, 10);
  showLoader:boolean = false;
  loadContent = "";
  allMonths: any[];
  startDate = new Date().toISOString().substr(0, 10);;
  endDate = new Date().toISOString().substr(0, 10);;

  constructor(
    private globals: OneStorage,
    private serv: transportService,
  ) { }

  
  ngOnInit() {
    this.session = this.globals.activeSession;
    if(typeof  this.session === 'undefined' )
    this.session ="2024-25";
    this.populateTable();
  }

  //Get Expense Report
  commercialVehicleExpense () {
    this.showLoader = true;
    this.serv.fetchCommercialVehicleExpeseReport(this.session, this.startDate, this.endDate  ).subscribe(
      (res) => {
        this.tableData = [];
         console.log(res.msg)
         var updatedArray1 = res.msg;
         updatedArray1.forEach(item => {
          console.log("actualIncomeAsPaidAmount Income:", item.actualIncomeAsPaidAmount);
          console.log("Salary:", item.salary);
          console.log("Total Amount:", item.totalAmount);

          let totalAmount = item.salary + (item.totalAmount || 0) + item.conductorSalary + item.bhabhi;
          let profitLoss = item.income - totalAmount;
          let actualProfitLoss = item.actualIncomeAsPaidAmount - totalAmount;

          this.tableData.push({
            ...item,
            totalAmount,
            profitLoss,
            actualProfitLoss
          });
        });

         const sumData = this.tableData.reduce((accumulator, expense) => {
          accumulator.fuelQuantity += expense.fuelQuantity || 0;
          accumulator.RepairAmount += expense.RepairAmount || 0;
          accumulator.MainAmount += expense.MainAmount || 0;
          accumulator.salary += expense.salary || 0;
          accumulator.FuelAmount += expense.FuelAmount || 0;
          accumulator.FoodAmount += expense.FoodAmount || 0;
          accumulator.InsuranceAmount += expense.InsuranceAmount || 0;
          accumulator.TyreAmount += expense.TyreAmount || 0;
          accumulator.TollAmount += expense.TollAmount || 0;
          accumulator.income += expense.income || 0;
          // accumulator.profitLoss += expense.profitLoss || 0;
          accumulator.OthersAmount += expense.OthersAmount || 0;
          accumulator.totalAmount += expense.totalAmount || 0;
          accumulator.seatingCapacity += expense.seatingCapacity || 0;
          accumulator.bhabhi += expense.bhabhi || 0;
          // accumulator.profitLoss += (expense.income || 0 ) - (expense.totalAmount || 0);
          accumulator.conductorSalary += expense.conductorSalary || 0;
          accumulator.actualIncomeAsPaidAmount += expense.actualIncomeAsPaidAmount || 0;
          return accumulator;
        }, {
          fuelQuantity: 0,
          salary:0,
          RepairAmount: 0,
          MainAmount: 0,
          FuelAmount: 0,
          FoodAmount: 0,
          InsuranceAmount: 0,
          TyreAmount: 0,
          totalAmount: 0,
          TollAmount: 0,
          // profitLoss:0,
          income:0,
          OthersAmount:0,
          seatingCapacity: 0,
          bhabhi: 0,
          conductorSalary: 0,
          actualIncomeAsPaidAmount:0
        });

        var newRowData = {
          vehicle: "",
          driverName: "",
          routeName : "",
          shiftName : "<b style='color: black;'>Grand Total</b>",
          seatingCapacity: sumData.seatingCapacity,
          salary: sumData.salary,
          conductorSalary: sumData.conductorSalary,
          bhabhi: sumData.bhabhi,
          fuelQuantity: sumData.fuelQuantity,
          FuelAmount: sumData.FuelAmount,
          RepairAmount: sumData.RepairAmount,
          // dieselCost: sumData.MainAmount,
          TollAmount: sumData.TollAmount,
          FoodAmount: sumData.FoodAmount,
          InsuranceAmount: sumData.InsuranceAmount,
          TyreAmount: sumData.TyreAmount,
          OthersAmount: sumData.OthersAmount,
          totalAmount: sumData.totalAmount,
          actualIncomeAsPaidAmount: sumData.actualIncomeAsPaidAmount,
          income:sumData.income,
          actualProfitLoss: sumData.actualIncomeAsPaidAmount - sumData.totalAmount,
          profitLoss: sumData.income - sumData.totalAmount,
      };
      this.tableData.push(newRowData)    
      this.populateTable();
      },
      (error) => {
        console.log("Error", error);
      }
      );
    }



  populateTable() {
    this.showLoader = false;
    let that = this;

    this.columns = [
      { data: "vehicle", title: "Vehicle", defaultContent: "<i>Not set</i>" },

      {
        data:"driverName",
        title: "Driver",
        defaultContent: "<i>Not set</i>",
      },
      {
        data: "routeName",
        title: "Route",
        defaultContent: "<i>Not set</i>",
      },
      {
        data: "shiftName",
        title: "Shift With Capacity",
        defaultContent: "<i>Not set</i>",
      },
      {
        data: "seatingCapacity",
        title: "RTO Capacity",
        defaultContent: "<i>Not set</i>",
      },
      {
        data: "salary",
        title: "Driver Salary",
        defaultContent: "<i>Not set</i>",
      },
      {
        data: "conductorSalary",
        title: "Conductor Salary",
        defaultContent: "<i>Not set</i>",
      },
      {
        data: "bhabhi",
        title: "Helper Salary",
        defaultContent: "<i>Not set</i>",
      },
      {
        data: "fuelQuantity",
        title: "Fuel(lt./Kg)",
        defaultContent: "<i>Not set</i>",
      },
      {
        data: "FuelAmount",
        title: "Fuel Cost(Rs.)",
        defaultContent: "<i>Not set</i>",
      },
      {
        data: "RepairAmount",
        title: "Repair",
        defaultContent: "<i>Not set</i>",
      },      
      // {
      //   data: "dieselCost",
      //   title: "TYRE/RTO ASSUR",
      //   defaultContent: "<i>Not set</i>",
      // },      
      {
        data: "TollAmount",
        title: "Toll",
        defaultContent: "<i>Not set</i>",
      },
      {
        data: "FoodAmount",
        title: "Food",
        defaultContent: "<i>Not set</i>",
      },
      {
        data: "InsuranceAmount",
        title: "Insurance",
        defaultContent: "<i>Not set</i>",
      },
      {
        data: "TyreAmount",
        title: "Tyre",
        defaultContent: "<i>Not set</i>",
      },
      {
        data: "OthersAmount",
        title: "Others",
        defaultContent: "<i>Not set</i>",
      },
      {
        data: "totalAmount",
        title: "Total",
        defaultContent: "<i>Not set</i>",
      },
      {
        data: "actualIncomeAsPaidAmount",
        title: "Actual Income",
        defaultContent: "0",
      },
      {
        data: "income",
        title: "Expected Income",
        defaultContent: "0",
      },

      {
        data: "actualProfitLoss",
        title: "Actual P&L",
        defaultContent: "0",
      },
      {
        data: "profitLoss",
        title: "Expected P&L",
        defaultContent: "0",
      },

    ];
    if ($.fn.DataTable.isDataTable(".example")) {
      $(".example").DataTable().clear().destroy();
    }
    $(".showhideTable").css("display", "block");
    const example = $(".example").DataTable({
      data: this.tableData,
      columns: this.columns,
      columnDefs: [
        {
          visible: false,
          searchable: false,
        },
      ],
      order: [],
      dom: "Bfrtip",
      buttons: [
        "pageLength",
        "excel",
        {
          text: "Pdf",
          action: function () {
            generatePdf();
          },
        },
      ],
      lengthMenu: [
        [5, 10, 25, 50, -1],
        [5, 10, 25, 50, "All"],
      ],
      language: {
        searchPlaceholder: "Search Here",
      },
    });
    async function generatePdf() {
      that.voucherAvailable = true;
      try {
        // if (that.columns.length > 6) {
        //   that.columns.pop()
        // }
        that.tableData.forEach((ele) => {
          that.columns.forEach((zz) => {
            if (!ele.hasOwnProperty(zz.data)) {
              ele[zz.data] = "Not set";
            }
          });
        });
        const inputDateString = new Date();
        const inputDate = new Date(inputDateString);

        const day = inputDate.getDate().toString().padStart(2, '0');
        const month = (inputDate.getMonth() + 1).toString().padStart(2, '0');
        const year = inputDate.getFullYear();

        const hours = inputDate.getHours().toString().padStart(2, '0');
        const minutes = inputDate.getMinutes().toString().padStart(2, '0');

        const formattedDate = `${day}/${month}/${year} ${hours}:${minutes}`;
        var title = "Report generated on  :" + "-  " + formattedDate;
        var reportName = "Commercial Vehicle Expenditure";

        await that.globals
          .getPdfDataObj(that.columns, that.tableData, title, reportName)
          .then((res) => {
            that.pdfDataObj = res;
          });
      } catch (e) {
        console.log(e);
      }
    }
  }
}
