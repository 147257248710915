import { Component, OnInit } from '@angular/core';
import { ConductorModel, salaryDetail } from "../Models/Conductor";
import { NotifierService } from "angular-notifier";
import { transportService } from "../transport.service";
import { Router } from "@angular/router";
import { OneStorage } from "../../GlobalDataStorage/oneStorage.service";
import { SharedService } from "src/app/dashboardO/dashboard/shared.service";

@Component({
  selector: 'app-add-conductor',
  templateUrl: './add-conductor.component.html',
  styleUrls: ['./add-conductor.component.scss']
})
export class AddConductorComponent implements OnInit {
  disableScroll(event: Event): void {
    event.preventDefault();
  }

  conductor = new ConductorModel()
  fileToUpload : any;
  govtIdFlag = false;
  profilePicFlag = false;
  showErrorMessage: string = "";
  date: any;
  profilePicture: any;
  govtId : any;
  button: string;
  salaryButton = "Add Salary";
  clickEventsubscription: any;

  canConductorBeDeactivated = true;

  conductorId : string;
  tableRowArr = [new salaryDetail()];

  constructor(
    public notifier: NotifierService,
    private serv: transportService,
    private router: Router,
    private globals: OneStorage,
    public sharedService : SharedService
  ) { 
    this.clickEventsubscription = this.sharedService
    .getClickEvent()
    .subscribe(() => {
      this.globals.activeSession = this.sharedService.getSession();
    });
  }

  ngOnInit() {

    this.conductor.currentStatus = "Active";

    this.button = "Add Conductor";
    if (localStorage.getItem("ConductorDetail")) {
      try {
        this.conductor = JSON.parse(localStorage.getItem("ConductorDetail"));

        if(this.conductor.salaryDetail.length > 0) {
          this.salaryButton = "Update/View"
          this.tableRowArr =   this.conductor.salaryDetail;

        } else {
          this.tableRowArr = [new salaryDetail()];
        }

        this.conductorId = JSON.parse(localStorage.getItem("ConductorDetail"))._id
        if(this.conductor.profilePicture){
          this.profilePicFlag = true;
        }

        if(this.conductor.govtId){
          this.govtIdFlag = true;
        }

        this.button = "Update Conductor";

        localStorage.removeItem("ConductorDetail");
      } catch (e) {
        alert(e); // error in the above string (in this case, yes)!
      }
    }
  }

  onFileChange(event) {
    this.fileToUpload = event.target.files[0];
    console.log("filename", this.fileToUpload);
    if (
      this.fileToUpload &&
      (this.fileToUpload.name.toLowerCase().includes("jpg") ||
        this.fileToUpload.name.includes("jpeg") ||
        this.fileToUpload.name.includes("jfif") ||
        this.fileToUpload.name.includes("pjpeg") ||
        this.fileToUpload.name.includes("pjp") ||
        this.fileToUpload.name.includes("png") ||
        this.fileToUpload.name.includes("svg"))
    ) {
      this.serv.saveId(this.fileToUpload);
      this.onAddDocument(event.target.name)
    } else {
      this.fileToUpload = null;
      this.notifier.notify("error", "Please select only image file");
    }
  }

  validateForm() {
    if (
      Object.keys(this.conductor).length === 0 ||
      !this.conductor.name ||
      this.conductor.name.trim() == ""
    ) {
      this.notifier.notify("error", "Please Enter a Name.");
      return false;
    }
    // if (Object.keys(this.conductor).length === 0 || !this.conductor.dateOfBirth) {
    //   this.notifier.notify("error", "Please Enter Date Of Birth.");
    //   return false;
    // }
    // if (
    //   Object.keys(this.conductor).length === 0 ||
    //   !this.conductor.localAddress.line1 ||
    //   this.conductor.localAddress.line1.trim() == ""
    // ) {
    //   this.notifier.notify("error", "Please Enter local address.");
    //   return false;
    // }
    // if (
    //   Object.keys(this.conductor).length === 0 ||
    //   !this.conductor.localAddress.state ||
    //   this.conductor.localAddress.state.trim() == ""
    // ) {
    //   this.notifier.notify("error", "Please Enter local State.");
    //   return false;
    // }
    // if (
    //   Object.keys(this.conductor).length === 0 ||
    //   !this.conductor.localAddress.pincode ||
    //   this.conductor.localAddress.pincode == null
    // ) {
    //   this.notifier.notify("error", "Please Enter valid pincode.");
    //   return false;
    // }

    // if (this.conductor.localAddress.line2) {
    //   this.conductor.localAddress.line2 = this.conductor.localAddress.line2.trim();
    // }

    var userDOB = new Date(this.conductor.dateOfBirth); 
    const isValidAge = this.isUserAbove18(userDOB);
    if (!isValidAge) {
      return this.notifier.notify("error", "Less than 18 Years Old DOB is not allowed");
    } 

    if (
      Object.keys(this.conductor).length === 0 ||
      !this.conductor.contactNo ||
      this.conductor.contactNo == null
    ) {
      this.notifier.notify("error", "Please Enter contact number.");
      return false;
    }

    // if (
    //   Object.keys(this.conductor).length === 0 ||
    //   !this.conductor.permanentAddress.line1 ||
    //   this.conductor.permanentAddress.line1.trim() == ""
    // ) {
    //   this.notifier.notify("error", "Please Enter Permanent address.");
    //   return false;
    // }
    // if (
    //   Object.keys(this.conductor).length === 0 ||
    //   !this.conductor.permanentAddress.state ||
    //   this.conductor.permanentAddress.state.trim() == ""
    // ) {
    //   this.notifier.notify("error", "Please Enter Permanent State.");
    //   return false;
    // }

    // if (this.conductor.permanentAddress.line2) {
    //   this.conductor.permanentAddress.line2 =
    //     this.conductor.permanentAddress.line2.trim();
    // }

    // if (this.conductor.otherContactNo) {
    //   this.conductor.otherContactNo = this.conductor.otherContactNo.trim();
    // }

    // if (
    //   Object.keys(this.conductor).length === 0 ||
    //   !this.conductor.username ||
    //   this.conductor.username.trim() == ""
    // ) {
    //   this.notifier.notify("error", "Please Set conductor Username.");
    //   return false;
    // }
    // if (
    //   Object.keys(this.conductor).length === 0 ||
    //   !this.conductor.password ||
    //   this.conductor.password.trim() == ""
    // ) {
    //   this.notifier.notify("error", "Please Set conductor password.");
    //   return false;
    // }

    if (!this.conductor.currentStatus) {
      this.notifier.notify("error", "Please Select conductor Status.");
      return false;
    }

    // if (!this.conductor.profilePicture) {
    //   this.notifier.notify("error", "Please Select Valid conductor Picture.");
    //   return false;
    // }

    // if (!this.conductor.govtId) {
    //   this.notifier.notify(
    //     "error",
    //     "Please Select Valid conductor Identity Document."
    //   );
    //   return false;
    // }

    if(!this.canConductorBeDeactivated){
      this.notifier.notify(
        "error",
        "Conductor Can't be Deactivated"
      );
      return false;
    }
    for (let i = 0; i < this.tableRowArr.length; i++) {
      let trow = this.tableRowArr[i];
      if(trow.month)
      {
        if (!trow.month) {
          return this.notifier.notify("error", `Please Select Month`);
        }
        if (trow.salary === undefined || trow.salary === null) {
          return this.notifier.notify("error", `Please Enter Salary For Blank Field`);
        }
        if (!trow.session) {
          trow.session = this.globals.globalSession;
        }
      }

    }

    return true;
  }

  sameAddress(event){
    console.log("localAdd",event)
    let localAdd = this.conductor.localAddress
    if(event.target.checked){
    if(!localAdd.line1 && !localAdd.line2 && !localAdd.state && !localAdd.pincode){
      this.notifier.notify("error", "Please Enter Local Address");
    }
    if(localAdd.line1){
      this.conductor.permanentAddress.line1 = localAdd.line1
    }
    if(localAdd.line2){
      this.conductor.permanentAddress.line2 = localAdd.line2
    }
    if(localAdd.state){
      this.conductor.permanentAddress.state = localAdd.state
    }
    if(localAdd.pincode){
      this.conductor.permanentAddress.pincode = localAdd.pincode
    }
    }else{
        this.conductor.permanentAddress.line1 = ""
      
        this.conductor.permanentAddress.line2 = ""
      
        this.conductor.permanentAddress.state = ""
      
        this.conductor.permanentAddress.pincode = ""
      
    }
  }

  onChange(newValue) {
    this.serv.checkAllocation({user : "Conductor", id : this.conductorId}).subscribe( (res) => {
      console.log("res from allocation check", res)

      if(res.status == false){
        this.notifier.notify("error", res.msg);
        this.canConductorBeDeactivated = false
      }else{
        this.canConductorBeDeactivated = true
      }
    })
}

  removeFilePic() {
    this.profilePicFlag = false;
    this.profilePicture = null;
    this.conductor["profilePicture"] = null
  }


  removeFileId() {
    this.govtIdFlag = false;
    this.govtId = null;
    this.conductor["govtId"] = null
  }

  onAddDocument(fileName) {
    this.serv.saveImage(this.fileToUpload).subscribe((res) => {
      this.conductor[`${fileName}`] = res.data.url;
      if(fileName == "profilePicture"){
        this.profilePicFlag = true
      }if(fileName == "govtId"){
        this.govtIdFlag = true
      }
      console.log("res", this.conductor)

    });
  }

  resetPageDetails() {
    this.conductor = new ConductorModel();
    this.tableRowArr = [new salaryDetail()];
    this.profilePicFlag = false;
    this.govtIdFlag = false;
    this.govtId = null;
    this.profilePicture = null;
    this.conductor.currentStatus = "Active"

  }

  addConductor() {
    if (!this.validateForm()) {
      return;
    }

    if(this.button == "Update Conductor"){
      this.conductor.session = this.globals.activeSession
      this.conductor.salaryDetail = this.tableRowArr;
      this.serv.updateConductor(this.conductor).subscribe(
        (res) => {
          this.notifier.notify("success", "Conductor Updated successfully");
          console.log("response", res);
          this.router.navigate(["/dashboard/listDriverConductor"]);
          this.resetPageDetails();
        },
        (err) => {
          this.notifier.notify("error", "Please Try Again");
        }
      );
      localStorage.setItem("refreshTable", JSON.stringify(true));
      
    }else{
      this.conductor.session = this.globals.activeSession
      this.conductor.salaryDetail = this.tableRowArr;
      this.serv.addConductor(this.conductor).subscribe(
        (res) => {
          console.log(res);
          if(res.status){
            this.notifier.notify("success", res.msg);
            this.resetPageDetails()
          }else{
            this.notifier.notify("error",res.msg);
          }
        },
        (err) => {
          console.log(err);
          this.notifier.notify("error", "Error:" + err);
        }
      );
    }
  }

  parseDate(dateString: string): Date {
    let dateVar: any = null;
    if (dateString) {
      dateVar = new Date(dateString);
    }
    console.log(dateVar);
    return dateVar;
  }

  deleteRow(index) {
    if (index != 0) {
      let newArr = this.tableRowArr;
      newArr.splice(index, 1);
      this.tableRowArr = newArr;
    }
  }

  addRow() {    
    let lastObj = this.tableRowArr[this.tableRowArr.length - 1]
    if (lastObj.month && lastObj.salary) {
      this.tableRowArr.push(new salaryDetail());

    } else {
      this.notifier.notify("error", "Empty fields not allowed")
    }

  }

  isUserAbove18(userDOB) {
    const currentDate = new Date();
    const ageInMilliseconds = currentDate.getTime() - userDOB.getTime();
    const ageInYears = ageInMilliseconds / (1000 * 60 * 60 * 24 * 365.25);
    return ageInYears >= 18;
  }

  getFunc() {
    if(this.tableRowArr.length === 0) {
      this.tableRowArr = [new salaryDetail()];
    }
  }

  onAddDocument2() {
    let concessionData = this.tableRowArr;
    for (let i = 0; i < this.tableRowArr.length; i++) {
      let trow = this.tableRowArr[i];

      if(trow.month)
      {
        if (!trow.month) {
          return this.notifier.notify("error", `Please Select a Month`);
        }
        if (trow.salary === undefined || trow.salary === null) {
          return this.notifier.notify("error", `Please Enter Salary For Blank Field`);
        }
      }
      
      const duplicateIndex = this.tableRowArr.findIndex((row, index) => index !== i && row.month === trow.month);
      if (duplicateIndex !== -1) {
        return this.notifier.notify("error", `'${trow.month}' is already selected.`);
      }

    }

    this.tableRowArr = [];
    for (const doc of concessionData) {
      const { ...newDocument } = doc;
      newDocument.session = this.globals.activeSession;
      this.tableRowArr.push(newDocument)
    }
  }

}