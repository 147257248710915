import { Component, OnInit } from '@angular/core';

declare var $: any;
import { OneStorage } from "src/app/GlobalDataStorage/oneStorage.service";
import { transportService } from '../transport.service';
import { Income, incomeDetails } from "../Models/Income";
import { NotifierService } from "angular-notifier";
import { Router, ActivatedRoute } from "@angular/router";




@Component({
  selector: 'app-add-vehicle-income',
  templateUrl: './add-vehicle-income.component.html',
  styleUrls: ['./add-vehicle-income.component.scss']
})
export class AddVehicleIncomeComponent implements OnInit {

  isConditionTrue: boolean = true; // Set this to true or false based on your condition

  tableData = [];
  columns = [];
  voucherAvailable: boolean = false;
  pdfDataObj: any;
  startDate = new Date().toISOString().substr(0, 10);
  endDate = new Date().toISOString().substr(0, 10);
  allVehicle: any;
  vehicleId: any;
  session: any;
  showLoader: boolean = false;
  loadContent = "";
  button = "Add Income";
  income = new Income();
  tableRowArr = [new incomeDetails()];

  constructor(
    private globals: OneStorage,
    private serv: transportService,
    public notifier: NotifierService,
    public _route: Router,
  ) { }

  ngOnInit() {
    this.session = this.globals.activeSession;
    if (typeof this.session === 'undefined')
      this.session = "2023-24";

    this.serv.fetchVehicleAllData().subscribe(
      (res) => {
        this.allVehicle = res.msg;
      }
    );
    this.populateTable();
    this.fetchIncome();
  }

  onAddDocument() {
    this.income.incomeDetails = this.tableRowArr;
    this.income.vehicle = this.vehicleId;
    this.income.session = this.session;
    this.serv.addVehicleIncome(this.income).subscribe(
      (res) => {
        this.notifier.notify("success", "Income Updated successfully");
        this.income = null;
        this.tableRowArr = [new incomeDetails()];
      },
      (err) => {
        this.notifier.notify("error", "Expected Income Already Exists");
      }
    );
    this.fetchIncome();
  }

  addIncome() {
    this.button = "Add Income";
    if (!this.session) {
      return this.notifier.notify("error", "Please Select Session")

    } else if (!this.vehicleId) {
      return this.notifier.notify("error", "Please Select Vehicle")
    }

  }

  fetchIncome() {
    this.serv.fetchVehicleIncome2(this.vehicleId, this.session).subscribe((res) => {
      this.tableData = res.msg;
      this.populateTable();
    })
  }


  onChangeVehicle() {
    this.fetchIncome();
  }

  createTable() {
    this.showLoader = true;
    this.serv.fetchExpenseList(this.vehicleId, this.session, this.startDate, this.endDate,).subscribe(
      (res) => {
        if ((res.data).length > 0) {

          this.tableData = res.data.map(item => {
            const date = new Date(item.createdAt);
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = date.getFullYear();
            return {
              ...item,
              createdAt: `${day}-${month}-${year}`,
            };
          });

          const sumData = this.tableData.reduce((accumulator, expense) => {
            accumulator.amount += expense.amount || 0;
            return accumulator;

          }, {
            amount: 0,
          });

          var newRowData = {
            vehicle: "",
            driverName: "",
            createdAt: "",
            title: "",
            billType: "",
            description: "",
            fuelType: "",
            fuelQuantity: "<b style='color: black;'>Grand Total</b>",
            amount: sumData.amount,
            billImage: "",
          };
          this.tableData.push(newRowData);
          this.populateTable();

        } else {
          this.tableData = null;
          this.populateTable();
        }
      },
      (error) => {
        console.log("Error", error);
      }
    );
  }

  populateTable() {
    this.showLoader = false;
    let that = this;
    let action_btn = '<i class="text-primary view" title="Edit Expected Income" style="cursor: pointer;"><img src="../../../assets/dash/pencil.svg"></i>';

    this.columns = [
      {
        data: "session",
        title: "Session",
        defaultContent: "<i>_</i>",
      },

      { data: "vehicle", title: "Vehicle", defaultContent: "<i>_</i>" },

      // {
      //   data: "createdAt",
      //   title: "Month",
      //   defaultContent: "<i>_</i>",
      // },
      // {
      //   data: "title",
      //   title: "Expected Income",
      //   defaultContent: "<i>_</i>",
      // },
      {
        data: null,
        title: "Action",
        className: "center",
        defaultContent: action_btn,
      },
    ];
    if ($.fn.DataTable.isDataTable(".example")) {
      $(".example").DataTable().clear().destroy();
    }

    $(".example tbody").on("click", "tr i.view", function () {
      var p = this.closest("tr");
      var data = $(".example").DataTable().row(p).index();      
      localStorage.setItem('vehicleIncomeLocal', JSON.stringify(that.tableData[data]));
      that._route.navigate(["/dashboard/editIncome"]);
    });

    $(".showhideTable").css("display", "block");
    const example = $(".example").DataTable({
      data: this.tableData,
      columns: this.columns,
      columnDefs: [
        {
          visible: false,
          searchable: false,
        },
      ],

      dom: "Bfrtip",
      order: [],
      buttons: [
        "pageLength",
        {
          extend: 'excelHtml5',
          exportOptions: {
            columns: ':not(:nth-child(4))'
          }
        },
        {
          text: "Pdf",
          action: function () {
            generatePdf();
          },
        },
      ],
      lengthMenu: [
        [5, 10, 25, 50, -1],
        [5, 10, 25, 50, "All"],
      ],
      language: {
        searchPlaceholder: "Search Here",
      },
    });
    async function generatePdf() {
      that.voucherAvailable = true;
      try {
        if (that.columns.length > 2) {
          that.columns.pop()
        }
        that.tableData.forEach((ele) => {
          that.columns.forEach((zz) => {
            if (!ele.hasOwnProperty(zz.data)) {
              ele[zz.data] = "Not set";
            }
          });
        });
        const inputDateString = new Date();
        const inputDate = new Date(inputDateString);

        const day = inputDate.getDate().toString().padStart(2, '0');
        const month = (inputDate.getMonth() + 1).toString().padStart(2, '0');
        const year = inputDate.getFullYear();

        const hours = inputDate.getHours().toString().padStart(2, '0');
        const minutes = inputDate.getMinutes().toString().padStart(2, '0');

        const formattedDate = `${day}/${month}/${year} ${hours}:${minutes}`;
        var title = "Report generated on  :" + "-  " + formattedDate;
        var reportName = " Expected Income Report";

        await that.globals
          .getPdfDataObj(that.columns, that.tableData, title, reportName)
          .then((res) => {
            that.pdfDataObj = res;
          });
      } catch (e) {
        console.log(e);
      }
    }
  }

  deleteRow(index) {
    if (index != 0) {
      let newArr = this.tableRowArr;
      newArr.splice(index, 1);
      this.tableRowArr = newArr;
    }
  }

  addRow() {
    let lastObj = this.tableRowArr[this.tableRowArr.length - 1]
    if (lastObj.month && lastObj.income) {
      this.tableRowArr.push(new incomeDetails());

    } else {
      this.notifier.notify("error", "Empty fields not allowed")
    }
  }



}
