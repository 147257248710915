import { Component, OnInit } from '@angular/core';
import { OneStorage } from "src/app/GlobalDataStorage/oneStorage.service";
import { transportService } from '../transport.service';
import { NotifierService } from "angular-notifier";
declare var $: any;

@Component({
  selector: 'app-capacity-shift-wise',
  templateUrl: './capacity-shift-wise.component.html',
  styleUrls: ['./capacity-shift-wise.component.scss']
})
export class CapacityShiftWiseComponent implements OnInit {
  tableData = [];
  columns = [];
  voucherAvailable: boolean = false;
  pdfDataObj: any;
  allVehicle: any;
  vehicleId: any = "All Vehicle";
  session:any;
  studentList: any;
  showLoader:boolean = false;
  loadContent = "";
  AllShiftList = [];
  shiftName: String =  "All";
  constructor(
    private globals: OneStorage,
    private serv: transportService,
    public notifier: NotifierService,

  ) { }

  ngOnInit() {
    this.createTable ()
        this.session = this.globals.activeSession;
        if(typeof  this.session === 'undefined' )
        this.session ="2023-24";
    
        this.serv.fetchVehicleAllData().subscribe(
          (res) => {
            this.allVehicle = res.msg;            
          }
        );       
        this.fetchShiftData();
  }


  createTable () {
    this.showLoader = true;
    this.serv.fetchShiftwiseCapacityByVehicle(this.session, this.vehicleId).subscribe(
      (res) => {
        this.studentList = res.msg;

        var sumData = this.studentList.reduce(function(acc, curr) {
          acc.occupied += curr.occupied;
          acc.capacity += curr.capacity;
          acc.vacant += curr.vacant;
          return acc;
        }, { occupied: 0, capacity: 0, vacant: 0 });

        if((this.studentList).length > 0) {
          var newRowData = {
            vehicle: "",
            routeName: "",
            routeShiftName : "<b style='color: black;'>Grand Total</b>",
            capacity: sumData.capacity,
            vacant: sumData.vacant,
            occupied: sumData.occupied, 
        };
        this.studentList.push(newRowData) 


        // var uniqueVehicles = {};
        // this.studentList.forEach(obj => {
        //   if (!uniqueVehicles[obj.vehicle]) {
        //     uniqueVehicles[obj.vehicle] = true;
        //   } else {
        //     obj.vehicle = '';
        //   }
        // });

        }


        this.populateTable();
      },
      (err) => {
        this.notifier.notify("error", "Please Try Again");
      }
    );
  }

  populateTable() {
    this.showLoader = false;
    let that = this;
    this.columns = [
      { data: "vehicle", 
        title: "Vehicle", 
        defaultContent: "<i>_</i>" 
      },
      { data: "routeName", 
      title: "Route Name", 
      defaultContent: "<i>_</i>" 
      },
      { data: "routeShiftName", 
      title: "Shift", 
      defaultContent: "<i>_</i>" 
      },
      {
        data: "capacity",
        title: "Capacity",
        defaultContent: "<i>not set</i>",
      },
      {
        data: "vacant",
        title: "Vacant",
        defaultContent: "<i> not set</i>",
      },
      {
        data: "occupied",
        title: "Occupied",
        defaultContent: "<i>not set</i>",
      },
    ];

    if ($.fn.DataTable.isDataTable(".example")) {
      $(".example").DataTable().clear().destroy();
    }
    $(".showhideTable").css("display", "block");
    const example = $(".example").DataTable({
      data: this.studentList,
      columns: this.columns,
      columnDefs: [
        {
          visible: false,
          searchable: false,
        },
      ],

      dom: "Bfrtip",
      order: [],
      // order: [[0, 'asc']],
      buttons: [
        "pageLength",
        {
          extend: 'excelHtml5',
          exportOptions: {
              columns: ':not(:nth-child(10))'
        }
        },
        {
          text: "Pdf",
          action: function () {
            generatePdf();
          },
        },
      ],
      lengthMenu: [
        [5, 10, 25, 50, -1],
        [5, 10, 25, 50, "All"],
      ],
      language: {
        searchPlaceholder: "Search Here",
      },
    });
    async function generatePdf() {
      that.voucherAvailable = true;
      try {
        that.columns.forEach((ele) => {
          that.columns.forEach((zz) => {
            if (!ele.hasOwnProperty(zz.data)) {
              ele[zz.data] = "Not set";
            }
          });
        });
        const inputDateString = new Date();
        const inputDate = new Date(inputDateString);

        const day = inputDate.getDate().toString().padStart(2, '0');
        const month = (inputDate.getMonth() + 1).toString().padStart(2, '0');
        const year = inputDate.getFullYear();

        const hours = inputDate.getHours().toString().padStart(2, '0');
        const minutes = inputDate.getMinutes().toString().padStart(2, '0');

        const formattedDate = `${day}/${month}/${year} ${hours}:${minutes}`;
        var title = "Report generated on  :" + "-  " + formattedDate;
        var reportName = "ShiftWise Capacity Report";

        await that.globals
          .getPdfDataObj(that.columns, that.studentList, title, reportName)
          .then((res) => {
            that.pdfDataObj = res;
          });
      } catch (e) {
        console.log(e);
      }
    }
  }
  fetchShiftData() {
    // this.showLoader = true;
    this.serv.fetchAllShift().subscribe(
      (res) => {
        this.AllShiftList = res.msg;
      },
      (err) => {
        this.notifier.notify("error", err.error);
      }
    );
  }
}
