import { Component, OnInit } from "@angular/core";
import { StudentService } from '../../StudentO/Services/student.service';
import { NotifierService } from "angular-notifier";
import { routeSchedulingService } from "src/app/CreateRoute/create-route.service";
import { OneStorage } from "src/app/GlobalDataStorage/oneStorage.service";
import { transportService } from "../transport.service";
// import { SharedService } from "src/app/dashboardO/dashboard/shared.service";


declare var $: any;
@Component({
  selector: "app-student-reporting-for-transport",
  templateUrl: "./student-reporting-for-transport.component.html",
  styleUrls: ["./student-reporting-for-transport.component.scss"],
})
export class StudentReportingForTransportComponent implements OnInit {
  allStandard = []; //JSON.parse(localStorage.getItem('allClasses'));
  allSection = []; //JSON.parse(localStorage.getItem('allSection'));
  sectionObj = {};
  allSession = [];
  routeName = "";
  studentData: any;

  studentstatus = "SelectAll";
  sessionOption = "2023-24";

  dropdownSettings = {};
  standard;
  section;
  today = new Date().toISOString().substr(0, 10);
  startDate = new Date().toISOString().substr(0, 10);
  endDate = new Date().toISOString().substr(0, 10);
  stopage = [];
  stopageList = [];
  routeShift: any;

  pdfDataObj: any;
  voucherAvailable: boolean = false;

  transactionDetails = [];
  coloumns = [];
  coloumns1 = [];
  showLoader:boolean = false;
  loadContent = "";

  constructor(
    private _studentService: StudentService,
    private routeSchedulingServices: routeSchedulingService,
    // public allApies: AllApisService,
    public _notify: NotifierService,
    private globals: OneStorage,
    private _tpserv: transportService,

  ) {
  }

  private callback3(res: {}, that: any) {
    that.schoolName = res["schoolDetails"]["name"];
    that.logoUrl = res["schoolDetails"]["logoUrl"];
  }

  private callback1(res: {}, that: any) {
    console.log("result", res);
    that.allStandard = res["standard"];
    that.sectionObj = res["sectionObj"];
    if (that.allStandard[0]) {
      that.standard = that.allStandard[0];
      that.allSection = that.sectionObj[that.standard];
      that.section = that.allSection[0];
      // that.createTable();
    }
    console.log(that.allClasses);
  }

  onClassChange(std: string) {
    console.log("standard selcted", std);
    this.stopage = [];
    // if (std === 'SelectAll') {
    //   this.allSection = ['SelectAll'];
    // } else {

    //   this.allSection = this.sectionObj[std];
    // }
    this.allSection = this.sectionObj[std];

    if (this.standard !== undefined && this.section !== undefined) {


      // this.createTable();
    }
    console.log(this.allSection);
  }
  private callback2(res: {}, that: any) {
    that.allSessions = res["session"];
    console.log(that.allSessions);
  }

  transportRouteid = "";
  selectedRoute: any = "";
  routeList = [];
  routeDetails = [];

  ngOnInit(): void {
    this.studentstatus = "SelectAll";

    this.dropdownSettings = {
      singleSelection: false,
      idField: "_id",
      textField: "name",
      selectAllText: "All",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 3,
      allowSearchFilter: true,
    };

    this.globals.initiateUserDetails(this.callback3, this);
    if (this.globals.standard === null || this.globals.standard.length === 0) {
      this.globals.initiateStandard(this.callback1, this);
    } else {
      this.allStandard = this.globals.standard;
      this.sectionObj = this.globals.sectionObj;
      if (this.allStandard[0]) {
        this.standard = this.allStandard[0];
        this.allSection = this.sectionObj[this.standard];
        this.section = this.allSection[0];
        // this.createTable();
      }
    }
    if (this.globals.session === null || this.globals.session.length === 0) {
      this.globals.initiateSession(this.callback2, this);
    } else {
      this.allSession = this.globals.session;
    }

    this.sessionOption = this.globals.activeSession;


    this.getRoutes();
  }

  getRoutes() {
    this.routeSchedulingServices
      .getRouteDetails1(this.sessionOption)
      .subscribe(
        (res) => {
          console.log("response routes", res.data);
          if (res.data.length > 0) {
            this.routeDetails = res.data;
            this.routeList = this.routeDetails.map(function (el) {
              return el.name;
            });
          } else {
            this.routeList = ["No Route Added"];
            this.stopage = null;
            this.stopageList = null;
          }
          this.selectedRoute = this.routeList[0];
          this.transportRouteid = this.routeDetails[0]._id;
          this.routeShift = this.routeDetails[0].routeShift
          this.stopageList = this.routeDetails[0].stoppage;
        },
        (error) => {
          console.log("Error", error);
        }
      );
  }

  changeStopageList(eventTarget) {
    console.log("routeName", eventTarget.value);
    this.stopageList = [];

    if(eventTarget.value == "All") {
      this.getStop();
    } else  {
      // this.stopageList = [];
      // this.stopageIdList = [];
      this.routeDetails.forEach((element) => {
        if (element.name == eventTarget.value) {
          this.transportRouteid = element._id;
          this.routeShift = element.routeShift
          // this.stopageIdList.push(element._id);
          this.stopageList = element.stoppage;
        }
      });
      this.stopage = null;
      console.log("helllo", this.stopageList);
    }

    // var row = eventTarget.closest("tr");
    // this.transportRouteid = "All";
    // var selectTarget = $(row).find("select.stopage");
  }

  getStop() {
    this._tpserv.getStopNew(this.sessionOption).subscribe((res) => {
      this.stopageList = res.data;
    });
  }

  createTable() {

    if(!this.stopage) {
      return this._notify.notify("error", "Please select stoppage");

    }
    this.showLoader = true;
    console.log("----this.section -", this.section);

    console.log(
      "concessionListconcessionListconcessionListconcessionListconcessionListconcessionList",
      this.stopage
    );

    let stopIds = [];
    this.stopage.forEach(elem => {
      stopIds.push(elem._id)
    })

    this._studentService
      .getStudentListForReporting_new(
        this.sessionOption,
        this.standard,
        this.section,
        this.studentstatus,
        stopIds,
        this.transportRouteid,
        this.routeShift,
        this.selectedRoute
      )
      .subscribe(
        (res) => {
          console.log("----------------getting student list", res);
          if (res.data.length === 0) {
            this._notify.notify("info", "No data Found");
          }

          this.transactionDetails = res.data;
          this.coloumns = res.coloumn;
          this.coloumns1 = res.coloumn;
          this.populateTable();

          /* this.notifier.notify('success', 'Data Updated SuccessFully'); */
        },
        (error) => {
          console.log("Error", error);
        }
      );
  }

  sessionChange() {
    this.getRoutes();
    // this.stopageList = [];
    this.stopage = null;

  }


  viewConcessionDetails(data){
    this.studentData = data
    $("#studentDataModal").modal("show");
  }

  populateTable() {
    this.showLoader = false;
    let that = this;
    if ($.fn.DataTable.isDataTable(".example")) {
      $('.example').DataTable().clear().destroy();
    };
    $('.showhideTable').css('display', 'block');
    const example = $('.example')
      .DataTable({
        data: this.transactionDetails,
        columns: this.coloumns,
        "columnDefs": [
          {
            "targets": [0],
            "visible": false,
            "searchable": false
          }],

        dom: 'Bfrtip',
        buttons: ["pageLength",'copy', 'csv', 'excel', {
          text: "Pdf",
          action: function () {
            generatePdf();
          },
        },],
        lengthMenu: [[5, 10, 25, 50, -1], [5, 10, 25, 50, 'All']],
        language: {
          searchPlaceholder: "Search Here",
        },
      });
    async function generatePdf() {
      that.voucherAvailable = true;
      try {
        let colNameOnly = (that.coloumns).filter((currElement, index) => {
          if (index != 0) { return currElement; }
        });//to remove first element

        //filling empty fields
        (that.transactionDetails).forEach(ele => {
          colNameOnly.forEach(zz => {
            if (!(ele.hasOwnProperty(zz.data))) {
              ele[zz.data] = 'Not set'
            }
          })
          // console.log(ele)
        })
        //console.log(arrArrData);
        var title = null;
        var concessionList = ' ';

        var reportName = 'Student Transport Mapping Report';

        if (that.standard != "SelectAll") {
          reportName = reportName + '_' + that.standard;
        }
        if (that.section != "SelectAll") {
          reportName = reportName + '_' + that.section;
        }
    

        await that.globals.getPdfDataObj(colNameOnly, that.transactionDetails, title, reportName, that.startDate, that.endDate).then(res => {
          that.pdfDataObj = res;
        })
      } catch (e) { console.log(e); }
    }

    $('.example tbody tr').on('click', 'i', function () {
        var p = this.closest('tr');
        var data = example.row(p).data()
        console.log(data);
        that.viewConcessionDetails(data);

    });

  }
}
