import { Component, OnInit } from '@angular/core';

declare var $: any;
import { OneStorage } from "src/app/GlobalDataStorage/oneStorage.service";
import { transportService } from '../transport.service';
import { Router, ActivatedRoute } from "@angular/router";
import { NotifierService } from "angular-notifier";

@Component({
  selector: 'app-expense-list',
  templateUrl: './expense-list.component.html',
  styleUrls: ['./expense-list.component.scss']
})
export class ExpenseListComponent implements OnInit {

  tableData = [];
  columns = [];
  voucherAvailable: boolean = false;
  pdfDataObj: any;
  startDate = new Date().toISOString().substr(0, 10);
  endDate = new Date().toISOString().substr(0, 10);
  allVehicle: any;
  vehicleId: any;
  session:any;
  showLoader:boolean = false;
  loadContent = "";
  newData: any;

  constructor(
    private globals: OneStorage,
    private serv: transportService,
    public _route: Router,
    public notifier: NotifierService,

  ) { }

  ngOnInit() {
    // this.createTable();
    this.session = this.globals.activeSession;
    if(typeof  this.session === 'undefined' )
    this.session ="2023-24";

    this.serv.fetchVehicleAllData().subscribe(
      (res) => {
        this.allVehicle = res.msg;
        this.vehicleId = "All"
        
      }
    );
    this.populateTable();
  }


  createTable() {
    this.showLoader = true;
    this.serv.fetchExpenseList(this.vehicleId, this.session, this.startDate, this.endDate, ).subscribe(
      (res) => {
        if ((res.data).length > 0) {

          this.tableData = res.data.map(item => {
            const date = new Date(item.createdAt);
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = date.getFullYear();
            return {
              ...item,
              createdAt: `${day}-${month}-${year}`,
            };
          });

         const sumData = this.tableData.reduce((accumulator, expense) => {
          accumulator.amount += expense.amount || 0;
          return accumulator;

        }, {
          amount: 0,
        });

        var newRowData = {
          vehicle: "",
          driverName: "",
          createdAt: "",
          title: "",
          billType: "",
          description: "",
          fuelType: "",
          fuelQuantity:  "<b style='color: black;'>Grand Total</b>",
          amount: sumData.amount,
          billImage: "",
      };
          this.tableData.push(newRowData);
          this.populateTable();

        } else {
          this.tableData = null;
          this.populateTable();
        }
      },
      (error) => {
        console.log("Error", error);
      }
    );
  }

  populateTable() {
    this.showLoader = false;
    let that = this;
    let action_btn = '<i class="text-primary edit" title="Edit Expense" style="cursor: pointer;"><img src="../../../assets/dash/pencil.svg"></i> <i class="text-danger ml-3 delete" title="Delete Expense" style="cursor: pointer;"><img src="../../../assets/dash/Group45993.svg"></i>';

    this.columns = [
      { data: "vehicle", title: "Vehicle", defaultContent: "<i>_</i>" },
      {
        data: "driverName",
        title: "Name",
        defaultContent: "<i>_</i>",
      },
      {
        data: "createdAt",
        title: "Expense Date",
        defaultContent: "<i>_</i>",
      },
      {
        data: "title",
        title: "Title",
        defaultContent: "<i>_</i>",
      },
      {
        data: "billType",
        title: "Bill Type",
        defaultContent: "<i>_</i>",
      },
      {
        data: "description",
        title: "Description",
        defaultContent: "<i>_</i>",
      },
      {
        data: "fuelType",
        title: "Fuel Type",
        defaultContent: "<i>_</i>",
      },
      {
        data: "fuelQuantity",
        title: "Fuel Quantity",
        defaultContent: "0",
      },
      {
        data: "amount",
        title: "Amount",
        defaultContent: "0",
      },
      {
        data: "billImage",
        title: "Download",
        defaultContent: "<i></i>",
        render: function(data, type, row) {
          if (type === 'display' && data) {
            return `<a href="${data}" download><i class="fas fa-download"></i> Download</a>`;
          }
          return data;
        }
      },
      // {
      //   data: null,
      //   title: "Action",
      //   className: "center",
      //   defaultContent: action_btn,
      // },
      {
        data: null,
        title: "Action",
        className: "center",
        defaultContent: "",
        render: function (data, type, row, meta) {
          if (type === 'display') {
            if (meta.row === (meta.settings._iRecordsTotal - 1)) {
              // Hide buttons for the last row
              return "";
            }
            // Render buttons for other rows
            return action_btn;
          }
          return "";
        },
      },
      
      
    ];
    if ($.fn.DataTable.isDataTable(".example")) {
      $(".example").DataTable().clear().destroy();
    }

    $(".example tbody").on("click", "tr i.edit", function () {
      var p = this.closest("tr");
      var data = $(".example").DataTable().row(p).index();      
      localStorage.setItem('ExpenseLocal', JSON.stringify(that.tableData[data]));
      that._route.navigate(["/dashboard/editExpense"]);
    });

    $(".example tbody").on("click", "tr i.delete", function () {
      var p = this.closest("tr");
      var data = $(".example").DataTable().row(p).index();   
      // let newData = that.tableData[data]
      localStorage.setItem('ExpensUpdateeLocal', JSON.stringify(that.tableData[data]));
      // that.deleteExpense(newData)   
      that.deleteExpense();
    });

    $(".showhideTable").css("display", "block");
    const example = $(".example").DataTable({
      data: this.tableData,
      columns: this.columns,
      columnDefs: [
        {
          visible: false,
          searchable: false,
        },
      ],

      dom: "Bfrtip",
      order: [],
      buttons: [
        "pageLength",
        {
          extend: 'excelHtml5',
          exportOptions: {
              columns: ':not(:nth-child(10)):not(:nth-child(11))'
        }
        },
        {
          text: "Pdf",
          action: function () {
            generatePdf();
          },
        },
      ],
      lengthMenu: [
        [5, 10, 25, 50, -1],
        [5, 10, 25, 50, "All"],
      ],
      language: {
        searchPlaceholder: "Search Here",
      },
    });
    async function generatePdf() {
      that.voucherAvailable = true;
      try {
        if (that.columns.length > 9) {
          that.columns.pop()
        }
        that.tableData.forEach((ele) => {
          that.columns.forEach((zz) => {
            if (!ele.hasOwnProperty(zz.data)) {
              ele[zz.data] = "Not set";
            }
          });
        });
        const inputDateString = new Date();
        const inputDate = new Date(inputDateString);

        const day = inputDate.getDate().toString().padStart(2, '0');
        const month = (inputDate.getMonth() + 1).toString().padStart(2, '0');
        const year = inputDate.getFullYear();

        const hours = inputDate.getHours().toString().padStart(2, '0');
        const minutes = inputDate.getMinutes().toString().padStart(2, '0');

        const formattedDate = `${day}/${month}/${year} ${hours}:${minutes}`;
        var title = "Report generated on  :" + "-  " + formattedDate;
        var reportName = "Expense Report";

        await that.globals
          .getPdfDataObj(that.columns, that.tableData, title, reportName)
          .then((res) => {
            that.pdfDataObj = res;
          });
      } catch (e) {
        console.log(e);
      }
    }
  }

  deleteExpense() {
    this.newData = JSON.parse(localStorage.getItem("ExpensUpdateeLocal"));
    localStorage.removeItem("ExpensUpdateeLocal");
    console.log("loclal data.......", this.newData)
    this.serv.deleteExenseByIncharge(this.newData).subscribe(
      (res) => {
        this.notifier.notify("success", "Expense Deleted Successfully");
        this.createTable();
      },
      (err) => {
        this.notifier.notify("error", "Please Try Again");
      }
    );
  }

}
