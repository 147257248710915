import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from "@angular/common/http";

import { Observable, timer, throwError } from "rxjs";
import { retryWhen, scan, delayWhen, catchError } from "rxjs/operators";
import { AllApisService } from "../all-apis.service";

@Injectable({
  providedIn: "root",
})
export class transportService {
  getStopApi = this.allApis.getStopApi;
  getStopNewApi = this.allApis.getStopNewApi;

  addStopApi = this.allApis.addStopApi;
  deleteStopApi = this.allApis.deleteStopApi;
  updateStopApi = this.allApis.updateStopApi;

  addDriverApi = this.allApis.addDriverApi;
  saveRouteApi = this.allApis.saveRouteApi;
  getStopNameApi = this.allApis.getStopNameApi;
  uploadFile = this.allApis.uploadFile;
  saveVehicleApi = this.allApis.saveVehicleApi;
  getVehicleApi = this.allApis.getVehicleApi;

  gpsData = this.allApis.gpsData;

  updateVehicleApi = this.allApis.updateVehicleApi;

  updateDriverApi = this.allApis.updateDriverApi;

  updateConductorApi = this.allApis.updateConductorApi;

  getMappedData = this.allApis.getMappedData;

  getRouteApi = this.allApis.getRouteApi;

  getStopAmountApi = this.allApis.getStopAmountApi;

  mapApi = this.allApis.mapApi;

  addTransportApi = this.allApis.addTransportApi;

  addConductorApi = this.allApis.addConductorApi;

  addHelperApi = this.allApis.addHelperApi;

  getHelperApi = this.allApis.getHelperApi;

  getDriverApi = this.allApis.getDriverApi;
  getConductorApi = this.allApis.getConductorApi;
  getHelperWithSessionApi = this.allApis.getHelperWithSessionApi;
  getDriverWithSessionApi = this.allApis.getDriverWithSessionApi;
  getConductorWithSessionApi = this.allApis.getConductorWithSessionApi;
  getUserNumberApi = this.allApis.getUserNumberApi;

  allocateData = this.allApis.allocateData;

  validateGpsId = this.allApis.validateGpsId;

  validateRegNum = this.allApis.validateRegNum;

  checkAllocationApi = this.allApis.checkAllocationApi;

  trackSchoolBus = this.allApis.trackSchoolBusData

  trackSchoolBusSingle = this.allApis.trackSchoolSingleBusData;

  // allBusData = this.allApis.trackSchoolBusDropDown;

  overSpeed = this.allApis.overSpeedFetch;
  getAllVehicle = this.allApis.fetchVehicleAll;
  fetchOverSpeed = this.allApis.fetchOverSpeedingData;
  fetchDistanceReportData = this.allApis.fetchDistanceReportData
  
  //Expense Report Vehicle
  privateVehicleExpense = this.allApis.transportExpensePrivate;
  commercialVehicleExpense = this.allApis.transportExpenseCommercial;
  commercialVehicleExpenseDashboard = this.allApis.transportExpenseCommercialDashboard;
  transportInstallmentFee = this.allApis.transportInstallments;
  commercialVehicleExpenseInstallment = this.allApis.transportExpenseCommercialInstallment;


  fetchExpense = this.allApis.fetchExpenseData

  updateMailSetting = this.allApis.updateMailSetting
  getMailSetting = this.allApis.getMailSetting

  fetchStudentsVehicle = this.allApis.fetchStudentListByVehicle
  fetchShiftwiseCapacity = this.allApis.fetchshiftWiseByVehicle
  attendanceStudent = this.allApis.fetchAttendance
  getRoutesByVehicle = this.allApis.fetchAllRoutesByVehicleId

  incomeVehicleApi = this.allApis.incomeVehicle;

  updateExpenseApi = this.allApis.updateExpenseByIncharge;
  deleteExpenseApi = this.allApis.deleteExpenseByIncharge;
  addExpenseApi = this.allApis.addExpenseByIncharge;
  shiftApi = this.allApis.addShift;
  shiftWiseCountApi = this.allApis.getShiftWiseCount;

  copyStoppageApi = this.allApis.storeCopyStoppage;
  copyRouteApi = this.allApis.storeCopyRoute;
  getIdpLoginPopupAuth0 = this.allApis.idpAuth0Login;

  
  // category="";
  errorData: {};
  constructor(
    private _http: HttpClient,
    private router: Router,
    public allApis: AllApisService
  ) {}

  getStop(session: string) {
    let params = new HttpParams();
    params = params.append("session", session);
    this.allApis.httpOptions.params = params;
    return this._http
      .get<any>(this.getStopApi, this.allApis.httpOptions)
      .pipe(catchError(this.handleError));
  }

  getStopNew(session: string) {
    let params = new HttpParams();
    params = params.append("session", session);
    this.allApis.httpOptions.params = params;
    return this._http
      .get<any>(this.getStopNewApi, this.allApis.httpOptions)
      .pipe(catchError(this.handleError));
  }

  getStopName() {
    let params = new HttpParams();
    this.allApis.httpOptions.params = params;
    return this._http
      .get<any>(this.getStopNameApi, this.allApis.httpOptions)
      .pipe(catchError(this.handleError));
  }

  addStop(stop: any) {
    let params = new HttpParams();
    this.allApis.httpOptions.params = params;
    return this._http
      .post<any>(this.addStopApi, stop, this.allApis.httpOptions)
      .pipe(catchError(this.handleError));
  }

  updateStop(stop: any, stopId: any) {
    let data = { stopdetails: stop, stopId: stopId };

    let params = new HttpParams();
    this.allApis.httpOptions.params = params;

    return this._http
      .patch<any>(this.updateStopApi, data, this.allApis.httpOptions)
      .pipe(catchError(this.handleError));
  }

  deleteStop(id: any) {
    let data = { stopId: id };

    let params = new HttpParams();
    this.allApis.httpOptions.params = params;

    return this._http
      .patch<any>(this.deleteStopApi, data, this.allApis.httpOptions)
      .pipe(catchError(this.handleError));
  }

  addDriver(driver: any) {
    let params = new HttpParams();
    this.allApis.httpOptions.params = params;

    return this._http
      .post<any>(this.addDriverApi, driver, this.allApis.httpOptions)
      .pipe(catchError(this.handleError));
  }

  updateDriver(driver: any) {
    let params = new HttpParams();
    this.allApis.httpOptions.params = params;

    return this._http
      .patch<any>(this.updateDriverApi, driver, this.allApis.httpOptions)
      .pipe(catchError(this.handleError));
  }

  addConductor(conductor: any) {
    let params = new HttpParams();
    this.allApis.httpOptions.params = params;

    return this._http
      .post<any>(this.addConductorApi, conductor, this.allApis.httpOptions)
      .pipe(catchError(this.handleError));
  }

  updateConductor(conductor: any) {
    let params = new HttpParams();
    this.allApis.httpOptions.params = params;

    return this._http
      .patch<any>(this.updateConductorApi, conductor, this.allApis.httpOptions)
      .pipe(catchError(this.handleError));
  }

  addHelper(helper: any) {
    let params = new HttpParams();
    this.allApis.httpOptions.params = params;
    return this._http
      .post<any>(this.addHelperApi, helper, this.allApis.httpOptions)
      .pipe(catchError(this.handleError));
  }

  updateHelper(helper: any) {
    let params = new HttpParams();
    this.allApis.httpOptions.params = params;
    return this._http
      .patch<any>(this.addHelperApi, helper, this.allApis.httpOptions)
      .pipe(catchError(this.handleError));
  }

  getHelper() {
    let params = new HttpParams();
    this.allApis.httpOptions.params = params;
    return this._http
      .get<any>(this.getHelperApi, this.allApis.httpOptions)
      .pipe(catchError(this.handleError));
  }

  getDriver() {
    let params = new HttpParams();
    this.allApis.httpOptions.params = params;

    return this._http
      .get<any>(this.getDriverApi, this.allApis.httpOptions)
      .pipe(catchError(this.handleError));
  }

  getConductor() {
    let params = new HttpParams();
    this.allApis.httpOptions.params = params;

    return this._http
      .get<any>(this.getConductorApi, this.allApis.httpOptions)
      .pipe(catchError(this.handleError));
  }
  getHelperWithSession(session: string) {
    let params = new HttpParams();
    params = params.append("session", session);
    this.allApis.httpOptions.params = params;
    return this._http
      .get<any>(this.getHelperWithSessionApi, this.allApis.httpOptions)
      .pipe(catchError(this.handleError));
  }

  getDriverWithSession(session: string) {
    let params = new HttpParams();
    params = params.append("session", session);
    this.allApis.httpOptions.params = params;

    return this._http
      .get<any>(this.getDriverWithSessionApi, this.allApis.httpOptions)
      .pipe(catchError(this.handleError));
  }

  getConductorWithSession(session: string) {
    let params = new HttpParams();
    params = params.append("session", session);
    this.allApis.httpOptions.params = params;

    return this._http
      .get<any>(this.getConductorWithSessionApi, this.allApis.httpOptions)
      .pipe(catchError(this.handleError));
  }

  getUser() {
    let params = new HttpParams();
    this.allApis.httpOptions.params = params;

    return this._http
      .get<any>(this.getUserNumberApi, this.allApis.httpOptions)
      .pipe(catchError(this.handleError));
  }

  allocateVehicleToDriverConductor(data: any) {
    let params = new HttpParams();
    this.allApis.httpOptions.params = params;

    return this._http
      .patch<any>(this.allocateData, data, this.allApis.httpOptions)
      .pipe(catchError(this.handleError));
  }

  getStopAmount(stop: any, session: string) {
    let params = new HttpParams();

    params = params.append("stop", stop);
    params = params.append("session", session);
    this.allApis.httpOptions.params = params;
    return this._http
      .get<any>(this.getStopAmountApi, this.allApis.httpOptions)
      .pipe(catchError(this.handleError));
  }

  addRoute(route: any) {
    let params = new HttpParams();
    this.allApis.httpOptions.params = params;

    return this._http
      .post<any>(this.saveRouteApi, route, this.allApis.httpOptions)
      .pipe(catchError(this.handleError));
  }

  updateRoute(route: any) {
    let params = new HttpParams();
    this.allApis.httpOptions.params = params;

    return this._http
      .patch<any>(this.saveRouteApi, route, this.allApis.httpOptions)
      .pipe(catchError(this.handleError));
  }

  getRoute(session : string) {
    let params = new HttpParams();
    params = params.append("session", session);
    this.allApis.httpOptions.params = params;

    return this._http
      .get<any>(this.getRouteApi, this.allApis.httpOptions)
      .pipe(catchError(this.handleError));
  }

  saveVehicle(vehicle: any) {
    let params = new HttpParams();
    this.allApis.httpOptions.params = params;

    return this._http
      .post<any>(this.saveVehicleApi, vehicle, this.allApis.httpOptions)
      .pipe(catchError(this.handleError));
  }

  updateVehicle(vehicle: any) {
    let params = new HttpParams();
    this.allApis.httpOptions.params = params;

    return this._http
      .patch<any>(this.updateVehicleApi, vehicle, this.allApis.httpOptions)
      .pipe(catchError(this.handleError));
  }

  getVehicles(flag: any) {
    let params = new HttpParams();

    params = params.append("allVehicleFlag", flag.allVehicleFlag);
    params = params.append("inUseVehicleFlag", flag.inUseVehicleFlag);
    this.allApis.httpOptions.params = params;
    return this._http
      .get<any>(this.getVehicleApi, this.allApis.httpOptions)
      .pipe(catchError(this.handleError));
  }

  getGpsData() {
    let params = new HttpParams();

    params = params.append("dashboard", "true");
    this.allApis.httpOptions.params = params;

    return this._http
      .get<any>(this.gpsData, this.allApis.httpOptions)
      .pipe(catchError(this.handleError));
  }

  mapVehicleToRoute(data: any) {
    let params = new HttpParams();
    this.allApis.httpOptions.params = params;

    return this._http
      .patch<any>(this.mapApi, data, this.allApis.httpOptions)
      .pipe(catchError(this.handleError));
  }

  getMappedRouteVehicleData(session:string) {
    let params = new HttpParams();
    params = params.append("session", session);
    this.allApis.httpOptions.params = params;

    return this._http
      .get<any>(this.getMappedData, this.allApis.httpOptions)
      .pipe(catchError(this.handleError));
  }

  addTransport(transport: any) {
    let params = new HttpParams();
    this.allApis.httpOptions.params = params;

    return this._http
      .patch<any>(this.addTransportApi, transport, this.allApis.httpOptions)
      .pipe(catchError(this.handleError));
  }
  trackSchookBus(flag){
    let params = new HttpParams();
      params = params.append('allVehicleFlag', flag.allVehicleFlag);
      params = params.append('inUseVehicleFlag', flag.inUseVehicleFlag);
      this.allApis.httpOptions.params = params;
    return this._http.get<any>(this.trackSchoolBus,this.allApis.httpOptions).pipe(catchError(this.handleError));
  }
  
  getOverSpeeding(startDate:any, endDate: any, speedLimit:any){
    let params = new HttpParams();
      params = params.append('startDate', startDate);
      params = params.append('endDate', endDate);
      params = params.append('speedLimit', speedLimit);
      this.allApis.httpOptions.params = params;
    return this._http.get<any>(this.overSpeed,this.allApis.httpOptions).pipe(catchError(this.handleError));
  }
  
  getOverSpeedin2(startDate:any, endDate: any, speedLimit:any, vehicleId: any){
    let params = new HttpParams();
      params = params.append('startDate', startDate);
      params = params.append('endDate', endDate);
      params = params.append('speedLimit', speedLimit);
      params = params.append('vehicleId', vehicleId);
      this.allApis.httpOptions.params = params;
    return this._http.get<any>(this.fetchOverSpeed,this.allApis.httpOptions).pipe(catchError(this.handleError));
  }
  
  fetchDistanceReport(startDate:any, endDate: any, vehicleId: any){
    let params = new HttpParams();
      params = params.append('startDate', startDate);
      params = params.append('endDate', endDate);
      params = params.append('vehicleId', vehicleId);
      this.allApis.httpOptions.params = params;
    return this._http.get<any>(this.fetchDistanceReportData,this.allApis.httpOptions).pipe(catchError(this.handleError));
  }

  fetchStudentsMappedByVehicles(session:any, vehicleId: any){
    let params = new HttpParams();
      params = params.append('session', session);
      params = params.append('vehicleId', vehicleId);
      this.allApis.httpOptions.params = params;
    return this._http.get<any>(this.fetchStudentsVehicle,this.allApis.httpOptions).pipe(catchError(this.handleError));
  }

  fetchShiftwiseCapacityByVehicle(session:any, vehicleId: any){
    let params = new HttpParams();
      params = params.append('session', session);
      params = params.append('vehicleId', vehicleId);
      this.allApis.httpOptions.params = params;
    return this._http.get<any>(this.fetchShiftwiseCapacity,this.allApis.httpOptions).pipe(catchError(this.handleError));
  }

  attendanceTransport(vehicle_id:any, route_id: any, date:any){
    let params = new HttpParams();
    params = params.append('vehicle_id', vehicle_id);
    params = params.append('route_id', route_id);
    params = params.append('date', date);
    this.allApis.httpOptions.params = params;
    return this._http.get<any>(this.attendanceStudent,this.allApis.httpOptions).pipe(catchError(this.handleError));
  }

  getRoutesByVehicleId(vehicleId:any){
    let params = new HttpParams();
    params = params.append('vehicleId', vehicleId);
    this.allApis.httpOptions.params = params;
    return this._http.get<any>(this.getRoutesByVehicle,this.allApis.httpOptions).pipe(catchError(this.handleError));
  }
  
  
  
  // trackSchookBusDataAll(){
  //   let params = new HttpParams();
  //   return this._http.get<any>(this.allBusData,this.allApis.httpOptions).pipe(catchError(this.handleError));
  // }
  
  trackSchookBusSingle(vehicleId:any){
    let params = new HttpParams();
      params = params.append('vehicleId', vehicleId);
      this.allApis.httpOptions.params = params;
    return this._http.get<any>(this.trackSchoolBusSingle,this.allApis.httpOptions).pipe(catchError(this.handleError));
  }
  
  
  fetchVehicleAllData(){
    return this._http.get<any>(this.getAllVehicle,this.allApis.httpOptions).pipe(catchError(this.handleError));
  }
  

  fetchPrivateVehicleExpeseReport(session:any, month:any, dateNow:any){
    let params = new HttpParams();
      params = params.append('session', session);
      params = params.append('month', month);
      params = params.append('dateNow', dateNow);
      this.allApis.httpOptions.params = params;
    return this._http.get<any>(this.privateVehicleExpense,this.allApis.httpOptions).pipe(catchError(this.handleError));
  }
  
  
  fetchCommercialVehicleExpeseReportDashboard(session:any, month:any){
    let params = new HttpParams();
    params = params.append('session', session);
    params = params.append('month', month);
    this.allApis.httpOptions.params = params;
    return this._http.get<any>(this.commercialVehicleExpenseDashboard,this.allApis.httpOptions).pipe(catchError(this.handleError));  
  }

  fetchCommercialVehicleExpeseReport(session:any, startDate:any, endDate:any){
    let params = new HttpParams();
    params = params.append('session', session);
    params = params.append('startDate', startDate);
    params = params.append('endDate', endDate);
    this.allApis.httpOptions.params = params;
    return this._http.get<any>(this.commercialVehicleExpense,this.allApis.httpOptions).pipe(catchError(this.handleError));  
  }
  
  fetchTransportFeesInstallMent(session:string){
    let params = new HttpParams();
    params = params.append('session', session);
    this.allApis.httpOptions.params = params;
    return this._http.get<any>(this.transportInstallmentFee,this.allApis.httpOptions).pipe(catchError(this.handleError));  
  }

  fetchCommercialVehicleExpeseReportInstallmentWise(session:any, selectedInstallment: any, monthListArray:any, installmentCount: any, separateCollectionTransport : any){
    let params = new HttpParams();
    params = params.append('session', session);
    params = params.append('selectedInstallment', selectedInstallment);
    params = params.append('monthListArray', monthListArray);
    params = params.append('installmentCount', installmentCount);
    params = params.append('separateCollectionTransport', separateCollectionTransport);
    this.allApis.httpOptions.params = params;
    return this._http.get<any>(this.commercialVehicleExpenseInstallment,this.allApis.httpOptions).pipe(catchError(this.handleError));  
  }
  

  fetchExpenseList(vehicleId: any, session:any, startDate:any, endDate: any){
      let params = new HttpParams();
      params = params.append('vehicleId', vehicleId);
      params = params.append('session', session);
        params = params.append('startDate', startDate);
        params = params.append('endDate', endDate);
        this.allApis.httpOptions.params = params;
      return this._http.get<any>(this.fetchExpense,this.allApis.httpOptions).pipe(catchError(this.handleError));
  }  

  addVehicleIncome(income: any) {
    let params = new HttpParams();
    this.allApis.httpOptions.params = params;
    return this._http
      .post<any>(this.incomeVehicleApi, income, this.allApis.httpOptions)
      .pipe(catchError(this.handleError));
  }

  fetchVehicleIncome2(vehicleId: any, session:any){
    let params = new HttpParams();
    params = params.append('vehicleId', vehicleId);
    params = params.append('session', session);
      this.allApis.httpOptions.params = params;
    return this._http.get<any>(this.incomeVehicleApi,this.allApis.httpOptions).pipe(catchError(this.handleError));
}

updateIncome(incomeDate: any) {
  let params = new HttpParams();
  this.allApis.httpOptions.params = params;
  return this._http
    .patch<any>(this.incomeVehicleApi, incomeDate, this.allApis.httpOptions)
    .pipe(catchError(this.handleError));
}

//Edit Expense
updateExenseByIncharge(expense: any) {
  let params = new HttpParams();
  this.allApis.httpOptions.params = params;
  return this._http
    .patch<any>(this.updateExpenseApi, expense, this.allApis.httpOptions)
    .pipe(catchError(this.handleError));
}


//Add Expense
addExenseByIncharge(expense: any) {
  let params = new HttpParams();
  this.allApis.httpOptions.params = params;
  return this._http
    .post<any>(this.addExpenseApi, expense, this.allApis.httpOptions)
    .pipe(catchError(this.handleError));
}

// Delete Expense
deleteExenseByIncharge(expense: any) {
  let params = new HttpParams();
  this.allApis.httpOptions.params = params;
  return this._http
    .patch<any>(this.deleteExpenseApi, expense, this.allApis.httpOptions)
    .pipe(catchError(this.handleError));
}

addShift(shift: any) {
  let params = new HttpParams();
  this.allApis.httpOptions.params = params;
  return this._http
    .post<any>(this.shiftApi, shift, this.allApis.httpOptions)
    .pipe(catchError(this.handleError));
}

fetchAllShift(){
  return this._http.get<any>(this.shiftApi,this.allApis.httpOptions).pipe(catchError(this.handleError));
}

updateShift(shift: any) {
let params = new HttpParams();
this.allApis.httpOptions.params = params;
return this._http
  .patch<any>(this.shiftApi, shift, this.allApis.httpOptions)
  .pipe(catchError(this.handleError));
}

  checkAllocation(user: any) {
    let params = new HttpParams();

    params = params.append("userType", user.user);
    params = params.append("userId", user.id);

    console.log("params conductor,", params);
    this.allApis.httpOptions.params = params;
    return this._http
      .get<any>(this.checkAllocationApi, this.allApis.httpOptions)
      .pipe(catchError(this.handleError));
  }

  validateGps(gpsId: any) {
    let params = new HttpParams();

    params = params.append("gpsId", gpsId);
    //    console.log(params);
    this.allApis.httpOptions.params = params;
    return this._http
      .get<any>(this.validateGpsId, this.allApis.httpOptions)
      .pipe(catchError(this.handleError));
  }

  validateVehicleToBeAdded(regNum: any) {
    let params = new HttpParams();

    params = params.append("regNum", regNum);
    this.allApis.httpOptions.params = params;
    return this._http
      .get<any>(this.validateRegNum, this.allApis.httpOptions)
      .pipe(catchError(this.handleError));
  }

  public profilePicture;
  public id;

  saveDp(reqFile: any) {
    this.profilePicture = reqFile;
  }

  saveId(reqFile: any) {
    this.id = reqFile;
  }

  saveImage(reqFile: any) {
    let formData = new FormData();
    if (reqFile) {
      formData.append("content_file", reqFile);
    }
    return this._http
      .post<any>(this.uploadFile, formData, this.allApis.httpOptions)
      .pipe(catchError(this.handleError));
  }

  updatetransportMailSetting(obj : any){

    return this._http
      .post<any>(this.updateMailSetting, obj, this.allApis.httpOptions)
      .pipe(catchError(this.handleError));

  }

  gettransportMailSetting(){
    return this._http
      .get<any>(this.getMailSetting, this.allApis.httpOptions)
      .pipe(catchError(this.handleError));
  }

  getShiftWiseCount(shift) {
    let params = new HttpParams();
    params = params.append("shift", shift);
    this.allApis.httpOptions.params = params;
    return this._http
      .get<any>(this.shiftWiseCountApi, this.allApis.httpOptions)
      .pipe(catchError(this.handleError));
  }

  copyStoppage(fromSessionStoppage: string, toSessionStoppage: string) {
    let params = new HttpParams();
    params = params.append("fromSessionStoppage", fromSessionStoppage);
    params = params.append("toSessionStoppage", toSessionStoppage);
    this.allApis.httpOptions.params = params;
    return this._http
      .get<any>(this.copyStoppageApi, this.allApis.httpOptions)
      .pipe(catchError(this.handleError));
  }

  copyRoute(fromSessionRoute: string, toSessionRoute: string) {
    let params = new HttpParams();
    params = params.append("fromSessionRoute", fromSessionRoute);
    params = params.append("toSessionRoute", toSessionRoute);
    this.allApis.httpOptions.params = params;
    return this._http
      .get<any>(this.copyRouteApi, this.allApis.httpOptions)
      .pipe(catchError(this.handleError));
  }

  getMapMyIndiaTrackingLogin() {
    // let params = new HttpParams();
    // params = params.append("allVehicleFlag", flag.allVehicleFlag);
    // params = params.append("inUseVehicleFlag", flag.inUseVehicleFlag);
    // this.allApis.httpOptions.params = params;

    return this._http
      .get<any>(this.getIdpLoginPopupAuth0, this.allApis.httpOptions)
      .pipe(catchError(this.handleError));
  }


  //  error handling
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.

      console.error("An error occurred:", error.error.message);
    } else {
      // The backend returned an unsuccessful response code.

      // The response body may contain clues as to what went wrong,

      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }

    // return an observable with a user-facing error message

    this.errorData = {
      errorTitle: "Oops! Request for document failed",
      errorDesc: "Something bad happened. Please try again later.",
    };
    return throwError(this.errorData);
  }
}
